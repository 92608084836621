import { collection, doc, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { isJurniFree, isJurniPaid } from './index'
import { useAlertStore } from '@/stores/alert'

export const fallbackToFirstStep = async (to, from, next) => {
  const { jurniId, stepId, chapterId } = to.params

  if (!stepId) {
    const jurniRef = doc(db, 'jurnis', jurniId)
    const stepsRef = collection(jurniRef, 'steps')
    const stepsQuery = query(stepsRef)
    const stepsQuerySnap = await getDocs(stepsQuery)
    const firstStep = stepsQuerySnap.docs[0]
    try {
      next({ name: 'jurni.step', params: { jurniId, stepId: firstStep?.id } })
    } catch (e: any) {
      throw new Error(e)
    }
  }
  next()
}

export const fallbackToFirstChapter = async (to, from, next) => {
  const { jurniId, stepId, chapterId } = to.params
  if (!await isJurniPaid(jurniId) && !await isJurniFree(jurniId)) {
    const { add } = useAlertStore()
    add({ description: 'This course has expired, please review your subscription details or contact customer support', color: 'danger' })
    next({ name: 'settings.payments' })
  } else if (!chapterId) {
    const stepRef = doc(db, 'jurnis', jurniId, 'steps', stepId)
    const chaptersQuerySnap = await getDocs(query(
      collection(stepRef, 'chapters'),
      orderBy('order')
    ))
    const firstChapter = chaptersQuerySnap.docs[0]

    try {
      next({ name: 'jurni.step.chapter',
        params: {
          jurniId,
          stepId,
          chapterId: firstChapter?.id
        }
      })
    } catch (e: any) {
      throw new Error(e)
    }
  } else {
    next()
  }
}

export default {
  fallbackToFirstStep
}
