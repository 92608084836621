import { useCoreStore } from '@/stores/core'
import { db } from '@/services/firebase'

import { ref, Ref } from 'vue'
import { collection, DocumentReference, getDocs, onSnapshot, query, where, Unsubscribe } from 'firebase/firestore'
import { defineStore, storeToRefs } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { Toast, User } from '@/types'

interface Alert {
  id: string,
  description: string
  color?: string,
  visible: boolean
}

interface Notification {
  id: string
  meta: {
    item?: {
      name: string
      ref: DocumentReference
    },
    user: DocumentReference<User>,
    content?: string
  }
  notificationType: string
  status?: string
}

type AlertStoreState = {
  alerts: Alert[]
  notifications: Notification[]
  toasts: Record<string, Toast>
  audio: Record<string, HTMLAudioElement>
  notifSnapUnsub: Unsubscribe | null
}

export const useAlertStore = defineStore('alert', {
  state: (): AlertStoreState => ({
    alerts: [],
    notifications: [],
    toasts: {},
    audio: {},
    notifSnapUnsub: null
  }),
  getters: {
    visibleAlerts(st) {
      return st.alerts.filter((alert) => alert.visible)
    },
    newNotifications(st) {
      return st.notifications.filter((n) => n.status === 'pending' || n.status === 'new')
    },
    toastList(st) {
      // return Object.values(st.toasts).filter((toast) => toast.visible)
      return Object.values(st.toasts)
    }
  },
  actions: {
    clearAllAlerts() {
      this.alerts = []
    },
    clearLastAlert() {
      this.alerts.pop()
    },
    clearNotifications() {
      this.notifications = []
    },
    removeNotificationById(notificationId) {
      this.notifications = this.notifications.filter((notification) => notification.id !== notificationId)
    },
    setAudio(id: string, url: string, opts: Record<string, boolean>) : HTMLAudioElement {
      if (!Object.keys(this.audio).includes(id)) {
        const audElm = new Audio(url)

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(opts))
          audElm[key] = value

        this.audio[id] = audElm
      }
      return this.audio[id]
    },

    playAudio(id: string, loop = false) : void {
      const audElm = this.audio[id]
      audElm.loop = loop
      audElm.muted = false

      const newMsgAlert = audElm.play()
      if (newMsgAlert !== undefined) {
        newMsgAlert
          .then(() => { console.log(`${id} ALERT SENT`) })
          .catch((err) => { console.log(`${id} ALERT FAILED`, err) })
      }
    },

    stopAudio(id: string) : void {
      this.audio[id].loop = false
    },

    getNotificationById(id : string) : Ref<Notification> {
      return ref(this.notifications.filter((notification) => notification.id === id)[0] ?? null)
    },
    async checkForNotifications() {
      const { currentUser } = storeToRefs(useCoreStore())
      if (!currentUser.value)
        return

      this.notifSnapUnsub = onSnapshot(
        query(
          collection(db, 'notifications'),
          where('meta.user', '==', currentUser.value.userRef)
        ),
        (notificationQuerySnap) => {
          notificationQuerySnap.docChanges().forEach((notificationChange) => {
            if (notificationChange.type === 'added') {
              const notificationData = notificationChange.doc.data() as Notification
              notificationData.id = notificationChange.doc.id
              this.notifications.push(notificationData)
            }
          })
        }
      )
    },
    add(alert, timeout: number | null = 10000) {
      const newAlert = {
        id: uuidv4(),
        visible: true,
        ...alert
      }

      this.alerts.push(newAlert)

      if (timeout) {
        setTimeout(() => {
          const alertIdx = this.alerts.findIndex((alrt) => alrt.id === newAlert.id)
          this.alerts.splice(alertIdx, 1)
        }, timeout)
      }
      return newAlert
    },
    addToast(toast, timeout: number | null = 10000) {
      const newToast = {
        id: uuidv4(),
        visible: false,
        ...toast
      }

      this.toasts[newToast.id] = newToast
      const storedToast = this.toasts[newToast.id]
      setTimeout(() => {
        storedToast.visible = true
      }, 100)

      if (timeout) {
        setTimeout(() => {
          storedToast.visible = false
          // const toastIdx = this.toasts.findIndex((tst) => tst.id === newToast.id)
          // this.toasts.splice(toastIdx, 1)
        }, timeout)
      }
    }
  }
})
