import type { User as FirebaseUser } from 'firebase/auth'
import type { CollectionReference, DocumentReference, query, where } from 'firebase/firestore'

import type { User } from '@/types'

import { defineStore } from 'pinia'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { collection, doc, getDoc, setDoc, collectionGroup } from 'firebase/firestore'
import { v4 } from 'uuid'
import { getDownloadURL, uploadBytes, deleteObject, ref as storageRef } from 'firebase/storage'
import { computed, ref, shallowRef } from 'vue'

import { db, storage } from '@/services/firebase'
// eslint-disable-next-line import/no-cycle
import { useAppOnboardingStore } from '@/stores/appOnboarding'
import { getFunctions, httpsCallable } from 'firebase/functions'

export const useAuthStore = defineStore('auth', () => {
  const firestoreUserCollection = collection(db, 'users') as CollectionReference<User>

  const authUser = shallowRef<FirebaseUser | null>(null)
  const userData = ref<User>()
  const moderator = ref<boolean>(false)
  const userRef = computed<DocumentReference<User> | null>(() => {
    if (authUser.value)
      return doc(firestoreUserCollection, authUser.value.uid)

    return null
  })

  const setModerator = () => {
    moderator.value = true
  }
  const initModerator = () => {
    moderator.value = false
  }

  const isAuthValid = ref(false)
  const hasPassword = computed(
    () => authUser.value?.providerData.some((x) => x.providerId === 'password')
  )

  const loadCurrentUser = async () : Promise<void> => {
    if (userRef.value) {
      const docSnap = await getDoc(userRef.value)
      const docData = docSnap.data()
      // console.log('loadCurrentUser', docData)
      userData.value = docData
    }
  }

  const init = () : void => {
    const unsubscribeAuth = onAuthStateChanged(getAuth(), async (x) => {
      // console.log('onAuthStateChanged', x)
      isAuthValid.value = !!x
      authUser.value = x

      if (x)
        await loadCurrentUser()
    })
    unsubscribeAuth()
  }

  const updateUser = async (data: Partial<User>) : Promise<void> => {
    if (userRef.value) {
      await setDoc(userRef.value, data, { merge: true })
      await loadCurrentUser()
    }
  }

  const setAvatar = async (file: File) : Promise<string> => {
    const name = v4()
    const sr = storageRef(storage, `users/avatars/${name}`)
    const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
    const downloadURL = await getDownloadURL(fileSnap.ref)
    await updateUser({ avatar: downloadURL })

    const appOnboardingStore = useAppOnboardingStore()
    appOnboardingStore.completeItem('profile.avatar.create')

    return downloadURL
  }

  const removeAvatar = async () : Promise<void> => {
    if (!userData.value || !userRef.value)
      return
    const httpsReference = storageRef(storage, userData.value?.avatar)

    await deleteObject(httpsReference)
    setDoc(userRef.value, { avatar: '' }, { merge: true })
  }

  const getToken = async (organizer: string | undefined) => {
    await loadCurrentUser()
    const userName = `${userData.value?.firstName} ${userData.value?.lastName}`
    const userEmail = userData.value?.email
    const userAvatar = userData.value?.avatar === '' ? `https://ui-avatars.com/api/?
    background=random&color=random&name=${userData.value?.firstName}+${userData.value?.lastName}` : userData.value?.avatar
    const _moderator = ref<boolean>(false)
    if (organizer !== undefined && organizer === userEmail)
      _moderator.value = true
    if (moderator.value === true)
      _moderator.value = true
    const functions = getFunctions()
    const sendInviteFunc = httpsCallable(functions, 'videoConference/getToken')
    console.log(_moderator.value, 'Are you moderator?')
    return sendInviteFunc({ name: userName, avatar: userAvatar, email: userEmail, moderator: _moderator.value })
  }

  return {
    init,
    authUser,
    hasPassword,
    isAuthValid,
    userRef,
    userData,
    updateUser,
    setAvatar,
    removeAvatar,
    getToken,
    loadCurrentUser,
    setModerator,
    initModerator,
    moderator
  }
})
