import { storeToRefs } from 'pinia'
import { Ref, ref } from 'vue'
import { useCoreStore } from '@/stores/core'
import { User } from '@/types'

type UseAuthenticatedUser = () => { user: Ref<User | null>, name: Ref<string> }

export const useAuthenticatedUser: UseAuthenticatedUser = () => {
  const { currentUser } = storeToRefs(useCoreStore())
  return { user: ref(currentUser.value?.profile || null), name: ref(currentUser.value?.name || '') }
}
