import { ref, Ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { defineStore, storeToRefs } from 'pinia'
import {
  Unsubscribe,
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  Query,
  DocumentData,
  onSnapshot,
  setDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  Timestamp,
  DocumentReference
} from 'firebase/firestore'
import {
  ref as rtdbRef,
  set,
  remove,
  onValue,
  onDisconnect,
  serverTimestamp,
  query as rtdbQuery,
  orderByKey,
  limitToLast,
  startAfter,
  onChildAdded,
  onChildChanged,
  push,
  endBefore,
  get
} from 'firebase/database'

import { db, rtdb } from '@/services/firebase'
import { query as agoraRecordingQuery } from '@/services/agora-recording'

import { useCoreStore } from '@/stores/core'
import { useAuthStore } from '@/stores/auth'
import { useAlertStore } from '@/stores/alert'
import { useAgoraCallStore } from './agoraCall'

import { usePermissions } from '@/composables/usePermissions'
import { useThread } from '@/composables/useThread'

import { Thread, User, ChatMessage, Community } from '@/types'

import router from '@/router'

export const useThreadStore = defineStore('thread', () => {
  const activeThreadId: Ref<string | null> = ref(null)
  const threads: Ref<{ [threadId: string]: Thread }> = ref({})
  const threadOrder: Ref<Array<string>> = ref([])
  const newThreadOrder: Ref<Array<string>> = ref([])
  let threadSnapshot: Unsubscribe | null = null
  const lazyLoadedThreadOrder: Ref<Array<string>> = ref([])
  const threadsLoadedAll: Ref<boolean> = ref(false)
  const threadsLazyLoadPointer: Ref<number> = ref(0)
  const status: Ref<{ [userId: string]: boolean }> = ref({})
  const typers: Ref<{ [threadId: string]: Record<string, string> }> = ref({})
  const typingTimeout: Ref<NodeJS.Timeout | null> = ref(null)
  const reply: Ref<ChatMessage | null> = ref(null)
  const recorders: Ref<{ [threadId: string]: Record<string, string> }> = ref({})
  const messageSnapshots: Ref<{
    [threadId: string]: { add: Unsubscribe | null, update: Unsubscribe | null }
  }> = ref({})
  const messages: Ref<{ [threadId: string]: ChatMessage[] }> = ref({})
  const chatSoundStatus: Ref<boolean> = ref(true)
  const threadsLoading: Ref<boolean> = ref(false)

  const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
  const { userRef } = storeToRefs(useAuthStore())
  const { can } = usePermissions()
  const { setAudio, playAudio, addToast, stopAudio, add: addAlert } = useAlertStore()
  const route = useRoute()

  // somehow im unable to export these from agoraCall.ts
  const RECORDING_SERVICE_NOT_STARTED = 0
  const RECORDING_SERVICE_INITIALIZED = 1
  const RECORDING_SERVICE_STARTING = 2
  const RECORDING_SERVICE_PARTIALLY_READY = 3
  const RECORDING_SERVICE_READY = 4
  const RECORDING_SERVICE_IN_PROGRESS = 5
  const RECODRING_SERVICE_REQUESTED_TO_STOP = 6
  const RECORDING_SERVICE_STOPS = 7
  const RECORDING_SERVICE_EXITS = 8
  const RECORDING_SERVICE_EXITS_ABNORMALLY = 20
  const RECORDING_STATUS_LIST_TO_STOP_START_QUERY = [
    RECORDING_SERVICE_IN_PROGRESS,
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]
  const RECORDING_STATUS_LIST_START_ERROR = [
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]

  const getThreadOrder = computed(() => lazyLoadedThreadOrder.value)
  const getNewThreadOrder = computed(() => newThreadOrder.value)

  const activeThread = computed(() => {
    if (!activeThreadId.value || !threads.value[activeThreadId.value])
      return null

    return threads.value[activeThreadId.value]
  })

  const getThreadById = (threadId: string) => threads.value[threadId] || null

  const getThreadList = computed(() => {
    const _threads: Array<Thread> = []

    lazyLoadedThreadOrder.value.map((threadId) => {
      if (threads.value[threadId])
        _threads.push(threads.value[threadId])
      return null
    })

    return _threads
  })

  const searchThreadList = computed(() => {
    const _lazyLoadedThreads: Array<Thread> = []
    const _threadIds: Array<string> = []
    const _threads: Array<Thread> = []

    lazyLoadedThreadOrder.value.map((threadId) => {
      if (threads.value[threadId]) {
        _threadIds.push(threadId)
        _lazyLoadedThreads.push(threads.value[threadId])
      }
      return null
    })

    threadOrder.value.map((threadId) => {
      if (threads.value[threadId] && !_threadIds.includes(threadId))
        _threads.push(threads.value[threadId])
      return null
    })

    return _lazyLoadedThreads.concat(_threads)
  })

  const getThreadMessageById = (threadId: string, messageId: string) => messages.value[threadId]
    ?.filter((msg) => msg.message_id === messageId)[0] ?? null

  const getThreadMessages = (threadId: string) => messages.value[threadId] ?? []

  const threadHasNewChats = (threadId: string) => newThreadOrder.value.includes(threadId)

  const isChatSoundOn = computed(() => chatSoundStatus.value)

  const hasNewChats = computed(() => newThreadOrder.value.filter(
    (newThreadId) => (threads.value[newThreadId] ? !threads.value[newThreadId].meta?.hideThread || false : false)
  ).length)

  const _loadNewThreadOrder = async () => {
    if (!currentUser.value || !currentCommunity.value)
      return

    const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
    const commSettings = await getDoc(doc(currentUser.value.userRef, 'communitySettings', communityId))
    const comSettingsData = commSettings.data()
    newThreadOrder.value = comSettingsData?.newChatThreads ? comSettingsData.newChatThreads : []
    threadOrder.value = [...newThreadOrder.value]
  }

  const _loadSupportThreadOrder = async () => {
    if (!currentUser.value || !currentCommunity.value)
      return

    const supportThreads = await getDocs(
      query(
        collection(db, 'threads'),
        where('type', '==', 'support'),
        where('members', 'array-contains', currentUser.value.id)
      )
    )

    if (supportThreads.empty)
      return

    supportThreads.docs.map((supportThread) => {
      if (!threadOrder.value.includes(supportThread.id))
        threadOrder.value.push(supportThread.id)
      return null
    })
  }

  const _loadThreadOrder = async () => {
    if (!currentUser.value || !currentCommunity.value)
      return

    const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
    const commSettings = await getDoc(doc(currentUser.value.userRef, 'communitySettings', communityId))
    const comSettingsData = commSettings.data()
    const commSettingsThreadOrder = comSettingsData?.threadOrder ? comSettingsData.threadOrder : []

    if (commSettingsThreadOrder.length) {
      commSettingsThreadOrder.map((threadId) => {
        if (!threadOrder.value.includes(threadId))
          threadOrder.value.push(threadId)
        return null
      })
    }
  }

  const _selectUserActiveThread = () => {
    if (!currentUser.value)
      return

    const userData = currentUser.value?.profile
    let _activeThreadId = lazyLoadedThreadOrder.value[0]
    if (userData?.meta?.activeThread)
      _activeThreadId = userData.meta.activeThread

    activeThreadId.value = _activeThreadId
  }

  const addToTopThread = (threadId: string, isNew = false) : void => {
    const _tempLLThreadOrder = lazyLoadedThreadOrder.value.filter((LLThreadOrderId) => LLThreadOrderId !== threadId)
    _tempLLThreadOrder.unshift(threadId)
    lazyLoadedThreadOrder.value = _tempLLThreadOrder

    const _tempThreadOrder = threadOrder.value.filter((threadOrderId) => threadOrderId !== threadId)
    _tempThreadOrder.unshift(threadId)
    threadOrder.value = _tempThreadOrder

    if (isNew) {
      const _tempNewThreadOrder = newThreadOrder.value.filter((newThreadOrderId) => newThreadOrderId !== threadId)
      _tempNewThreadOrder.unshift(threadId)
      newThreadOrder.value = _tempNewThreadOrder
    }
  }

  const _saveThreadOrder = async () => {
    if (!currentUser.value || !currentCommunity.value)
      return

    const uid = currentUser.value?.id as string
    const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
    const userSettingsRef = doc(db, 'users', uid, 'communitySettings', communityId)
    await setDoc(userSettingsRef, { threadOrder: threadOrder.value }, { merge: true })
  }

  const clearNewChat = async (threadId: string) => {
    if (!currentUser.value || !currentCommunity.value)
      return

    const communityId = currentUser.value.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID ? 'global' : currentCommunity.value.id
    newThreadOrder.value = newThreadOrder.value.filter((tId) => tId !== threadId)
    await setDoc(
      doc(currentUser.value.userRef, 'communitySettings', communityId),
      { newChatThreads: arrayRemove(threadId) },
      { merge: true }
    )
  }

  const _clearTyping = async () => {
    const { userRef: currentUser } = storeToRefs(useAuthStore())
    await setDoc(doc(db, `threads/${activeThreadId.value}`), { typing: arrayRemove(currentUser.value) }, { merge: true })
  }

  const _isLoadExempted = (thread: Thread) => {
    if (!currentUser.value || !thread.community || !thread.owner)
      return true

    if (thread.type === 'support' &&
      currentUser.value?.id !== process.env.VUE_APP_CHAT_SUPPORT_USER_ID &&
      !thread.members.find((m) => m.id === currentUser.value?.id))
      return true

    if (thread.type === 'bot' && !thread.members.find((m) => m.id === currentUser.value?.id))
      return true

    if (thread.community === 'global' && thread.type !== 'support')
      return true

    if (thread.community !== 'global' && thread.community.id !== currentCommunity.value?.id)
      return true

    if (thread.members && thread.members.length < 2 && !thread.name && thread.type !== 'support')
      return true

    // hide support chat from support account unless user sent a message
    // hide coach welcome message to coach unless otherwise set
    if ([process.env.VUE_APP_CHAT_SUPPORT_USER_ID, thread.owner.id].includes(currentUser.value?.id)) {
      if (thread.meta?.hideThread === true)
        return true
    }

    // roles are not loaded at this point
    const isStudent = currentCommunity.value?.coach.id !== currentUser.value.id &&
      !['admin', 'coach', 'coachadmin', 'coachmoderator', 'superadmin'].includes(currentCommunity.value?.roles[currentUser.value.id])
    // hides support chat for students
    if (thread.type === 'support' && isStudent && (currentUser.value?.id !== process.env.VUE_APP_CHAT_SUPPORT_USER_ID))
      return true

    return false
  }

  const _addMessageListener = async (threadId: string, listenerType: 'add'|'update') : Promise<Unsubscribe | null> => {
    const messagesRef = rtdbRef(rtdb, `chats/${threadId}/messages`)
    const initialMessageRef = rtdbQuery(messagesRef, orderByKey(), limitToLast(1))

    const initialData = await new Promise((resolve, reject) => {
      const onError = (error) => reject(error)
      const onData = (snap) => resolve(snap.val())

      onValue(initialMessageRef, onData, onError, { onlyOnce: true })
    }) as Record<string, ChatMessage>

    const lastIndex = initialData ? Object.keys(initialData)[Object.keys(initialData).length - 1] : null
    let newMessageRef
    if (lastIndex)
      newMessageRef = rtdbQuery(initialMessageRef, startAfter(lastIndex))
    else
      newMessageRef = rtdbQuery(initialMessageRef)

    if (listenerType === 'add') {
      return onChildAdded(newMessageRef, async (messageSnap) => {
        const threadSnap = await getDoc(doc(db, 'threads', threadId))
        const thread = threadSnap.data() as Thread
        const message = messageSnap.val() as ChatMessage
        let notificationData: Record<string, any> = {}

        // if chat from current community or support chat
        if ((currentCommunity.value && thread.community !== 'global' && currentCommunity.value.id === thread.community.id) || thread.type === 'support') {
          addToTopThread(threadId, true)
          _saveThreadOrder()

          notificationData = {
            message: 'New chat message',
            actionLabel: 'View',
            onClick: () => {
              activeThreadId.value = threadId
              router.push({ name: 'chat' })
            }
          }
        } else if (currentCommunity.value && thread.community !== 'global' && currentCommunity.value.id !== thread.community.id) {
          // allows user to receive new message notifs in another community if thread has already been loaded
          const commSnap = await getDoc(thread.community)
          const community = commSnap.data() as Community
          const threadComposable = useThread(threadId, thread)
          notificationData = {
            message: `New chat message<br>Community: ${community.name}<br>Conversation: ${threadComposable.name.value || 'unnamed thread'}`
          }
        }

        if (message.timestamp)
          message.timestamp = new Timestamp(message.timestamp?.seconds, message.timestamp?.nanoseconds)

        if (Object.keys(messages.value).includes(threadId) && messages.value[threadId]) {
          if (!messages.value[threadId].some((msg) => msg.message_id === message.message_id))
            messages.value[threadId].push(message)
        }

        // if on the receiving end
        if (currentUser.value?.userRef.id !== message?.from) {
          setAudio(
            'newChat',
            'https://firebasestorage.googleapis.com/v0/b/jurni-prod.appspot.com/o/sfxs%2Fmeeting-notification.mp3?alt=media&token=4eb80eca-5bd1-4826-af76-ce6460422ca2',
            { defaultMuted: true, muted: true }
          )

          if (chatSoundStatus.value)
            playAudio('newChat')

          // send notification toast
          addToast(notificationData, 2500)
        }

        await _clearTyping()
      })
    }

    if (listenerType === 'update') {
      return onChildChanged(messagesRef, async (messageSnap) => {
        const message = messageSnap.val() as ChatMessage

        if (message.timestamp)
          message.timestamp = new Timestamp(message.timestamp?.seconds, message.timestamp?.nanoseconds)

        messages.value[threadId] = messages.value[threadId].map(
          (msg) => (msg.message_id === message.message_id ? message : msg)
        )
      })
    }
    return null
  }

  const _loadTypers = async (thread: Thread, threadId: string) => {
    if (!thread.typing)
      return

    const typersData = {}

    await Promise.all(thread.typing?.map(async (typist) => {
      const userSnap = await getDoc(typist)
      const typistProfile = userSnap.data() as User

      if (thread.type && currentUser.value?.id !== typist.id) {
        if (['thread', 'support'].includes(thread.type))
          typersData[typist.id] = typistProfile.firstName
        else
          typersData[typist.id] = typistProfile.meta?.name
      }
    }))

    typers.value[threadId] = typersData
  }

  const _removeChatBadge = (threadId: string) => {
    // remove from sender for new chat badge
    // remove red dot if user is currently inside thread
    newThreadOrder.value = newThreadOrder.value.filter((newThreadId) => {
      if (!currentUser.value)
        return false

      if (newThreadId !== threadId)
        return true

      const thread = threads.value[newThreadId]
      if (thread?.lastActivity?.senderId === currentUser.value.id || (activeThreadId.value === newThreadId && route.name === 'chat'))
        return false

      return true
    })
  }

  const _loadMeetingStartedEvents = async (thread: Thread, threadRef: DocumentReference): Promise<void> => {
    if (!thread.startMeeting)
      return

    if (thread.startMeeting === 'stopped')
      return

    if (thread.startMeeting === 'started') {
      if (!thread.joinedMeeting!.length)
        await setDoc(threadRef, { startMeeting: 'stopped' }, { merge: true })
      return
    }

    if (currentUser.value?.id === thread.startMeeting?.id) {
      // need to change startMeeting so notification would not repeat
      // every time there is another change in the thread doc
      await setDoc(threadRef, { startMeeting: 'started' }, { merge: true })
    } else if (thread.startMeeting?.id !== currentUser.value?.id) {
      // other users' end of the meeting
      // Audio Notification
      setAudio(
        'meeting',
        'https://firebasestorage.googleapis.com/v0/b/jurni-prod.appspot.com/o/sfxs%2Fmeeting-notification.mp3?alt=media&token=4eb80eca-5bd1-4826-af76-ce6460422ca2',
        { defaultMuted: true, muted: true }
      )
      playAudio('meeting', true)

      const initialUserSnap = await getDoc(thread.startMeeting)
      const initialUser = initialUserSnap.data()

      // Visual Notification
      addToast({
        message: `Video chat from ${initialUser?.firstName || 'Unknown'}`,
        actionLabel: 'Join',
        onClick: () => {
          stopAudio('meeting')

          const callUrl = router.resolve({
            name: 'meet.room',
            params: { room: thread.thread_id }
          })

          window.open(callUrl.href, '_blank')
        },
        closeOnClick: () => {
          stopAudio('meeting')
        }
      }, null)
    }
  }

  const _loadMeetingJoiners = async (thread: Thread) => {
    if (!thread.joinedMeeting || !currentUser.value)
      return false

    const mmIds = thread.joinedMeeting.map(
      (meetingMember) => meetingMember.id
    ).filter((id) => id).sort()

    return mmIds.includes(currentUser.value?.id)
  }

  const updateThread = async (data: Partial<Thread>, threadId? : string) : Promise<void> => {
    if (!activeThreadId.value && !threadId)
      return

    const threadToUpdate = threadId ?? activeThreadId.value
    if (!threadToUpdate)
      return

    await setDoc(doc(collection(db, 'threads'), threadToUpdate), data, { merge: true })
  }

  const _loadMeetingRecordingEvents = async (thread: Thread, threadId: string) => {
    if (!thread.meta || (!thread.meta.startedRecordingBy && !thread.meta.recordingProgress))
      return

    if (thread.meta.recordingProgress === 'stopped') {
      recorders.value[threadId] = {}
      addAlert({ description: 'Recording has been stopped', color: 'info' })

      // so it does not show every time a change in thread is made
      await updateThread({
        meta: {
          recordingResourceId: null,
          recordingSid: null,
          startedRecordingBy: null,
          recordingProgress: null
        }
      }, threadId)
      return
    }

    const userSnap = await getDoc(thread.meta.startedRecordingBy as DocumentReference<User>)
    const recorderProfile = userSnap.data() as User
    const recorderData = {}

    // this way even late joiners to the meeting will be notified with the recording
    // other users' end of the recording
    if (thread.meta.startedRecordingBy?.id && thread.meta.recordingProgress === 'recording' &&
      currentUser.value?.id !== thread.meta.startedRecordingBy?.id &&
      thread.meta.recordingResourceId && thread.meta.recordingSid) {
      recorderData[thread.meta.startedRecordingBy?.id] = recorderProfile.firstName ?? 'unknown'
      recorders.value[threadId] = recorderData

      try {
        let _recordingStatus = 0
        /* eslint-disable no-await-in-loop */
        while (!RECORDING_STATUS_LIST_TO_STOP_START_QUERY.includes(_recordingStatus))
          _recordingStatus = await agoraRecordingQuery(thread.meta.recordingResourceId, thread.meta.recordingSid)

        if (RECORDING_STATUS_LIST_START_ERROR.includes(_recordingStatus))
          throw new Error('recording not in progress')

        addAlert({
          description: `This meeting is being recorded by ${recorderData[thread.meta.startedRecordingBy?.id]}`,
          color: 'info'
        })
      } catch (err: any) {
        // recording not in progress anymore
        await updateThread({
          meta: {
            recordingResourceId: null,
            recordingSid: null,
            startedRecordingBy: null,
            recordingProgress: null
          }
        }, threadId)
      }
      return
    }

    // clear uncleared recording trackers
    // if something is missing, means it was broken
    const _trackers = [
      thread.meta.startedRecordingBy?.id,
      thread.meta.recordingProgress,
      thread.meta.recordingResourceId,
      thread.meta.recordingSid
    ]
    if (!_trackers.every((item) => !!item)) {
      await updateThread({
        meta: {
          recordingResourceId: null,
          recordingSid: null,
          startedRecordingBy: null,
          recordingProgress: null
        }
      }, threadId)
    }
  }

  const _loadThreads = async () => {
    if (!currentUser.value || !currentCommunity.value)
      return

    if (threadSnapshot)
      threadSnapshot()

    // const communityId = currentCommunity.value.id
    // let threadQuery: Query<DocumentData> | undefined
    // if (can('manage', 'chat')) {
    //   // load all chats in community
    //   threadQuery = query(
    //     collection(db, 'threads'),
    //     where('community', 'in', [doc(db, 'communities', communityId), 'global'])
    //   )
    // } else {
    // load chats user is a member of
    const threadQuery : Query<DocumentData> = query(
      collection(db, 'threads'),
      where('members', 'array-contains', currentUser.value.userRef)
    )
    // }

    threadSnapshot = onSnapshot(threadQuery, async (threadSnap) => {
      await Promise.all(threadSnap.docChanges().map(async (change) => {
        const threadId = change.doc.id
        const threadRef = change.doc.ref

        if (change.type === 'removed') {
          delete threads.value[threadId]
          lazyLoadedThreadOrder.value = lazyLoadedThreadOrder.value.filter((tId) => tId !== threadId)
          threadOrder.value = threadOrder.value.filter((tId) => tId !== threadId)
          newThreadOrder.value = newThreadOrder.value.filter((tId) => tId !== threadId)
          delete messages.value[threadId]
          delete typers.value[threadId]
          delete recorders.value[threadId]
          return
        }

        // added or modified
        const thread = change.doc.data() as Thread

        // update legacy support chats to global
        if (!thread.community && thread.type === 'support') {
          await setDoc(change.doc.ref, { community: 'global' }, { merge: true })
          thread.community = 'global'
        }

        // hide legacy support chats by default
        if (!thread.meta && thread.type === 'support') {
          await setDoc(change.doc.ref, { meta: { hideThread: true } }, { merge: true })
          thread.meta = { hideThread: true }
        }

        if (_isLoadExempted(thread))
          return

        // load thread owner data
        const ownerSnap = await getDoc(thread.owner)
        const profile = ownerSnap.data() as User
        thread.ownerData = { ...profile, id: ownerSnap.id } as User

        // compute thread name
        const name = async () : Promise<string> => {
          if (thread.name)
            return Promise.resolve(thread.name)

          const threadMembers = await Promise.all(thread.members
            .map(async (member) => {
              const memberSnap = await getDoc(member)
              const memberData = memberSnap.data() as User
              return { user: memberData }
            }))
          return Promise.resolve(threadMembers.map(({ user }) => {
            if (!user)
              return null

            if (user.uid !== currentUser.value?.id)
              return `${user.firstName} ${user.lastName}` || user.email

            return null
          }).filter((value) => value).join(', '))
        }

        // replace with content library threadAvatar
        if (thread.meta?.bannerItem) {
          const contentSnap = await getDoc(thread.meta.bannerItem)
          const content = contentSnap.data()
          thread.threadAvatar = content?.uploadUrl
        }

        // load thread
        threads.value[threadId] = { ...thread, name: await name(), thread_id: threadId }

        // LISTENERS
        if (change.type === 'added') {
          // initialize
          typers.value[threadId] = {}
          recorders.value[threadId] = {}

          // add message listeners
          if (!Object.keys(messageSnapshots.value).includes(threadId)) {
            const addListener = await _addMessageListener(threadId, 'add')
            const updateListener = await _addMessageListener(threadId, 'update')
            messageSnapshots.value[threadId] = { add: addListener, update: updateListener }
          } else if (!Object.keys(messageSnapshots.value[threadId]).includes('add') || messageSnapshots.value[threadId].add === null) {
            messageSnapshots.value[threadId].add = await _addMessageListener(threadId, 'add')
          } else if (!Object.keys(messageSnapshots.value[threadId]).includes('update') || messageSnapshots.value[threadId].update === null) {
            messageSnapshots.value[threadId].update = await _addMessageListener(threadId, 'update')
          }

          // add new thread to top
          if (!threadOrder.value.length) {
            await _loadNewThreadOrder()
            await _loadThreadOrder()
            await _loadSupportThreadOrder()
          }

          if (!threadOrder.value.includes(threadId)) {
            addToTopThread(threadId, true)
            _saveThreadOrder()
          }
        } else if (change.type === 'modified') {
          _removeChatBadge(threadId)

          // typing listener
          if (thread.typing)
            await _loadTypers(thread, threadId)

          // meeting listeners
          if (thread.startMeeting)
            await _loadMeetingStartedEvents(thread, threadRef)

          let userJoinedMeeting = false
          if (thread.joinedMeeting)
            userJoinedMeeting = await _loadMeetingJoiners(thread)

          // meeting recording listeners
          if (userJoinedMeeting)
            await _loadMeetingRecordingEvents(thread, threadId)
        }
      }))
    })
  }

  const loadThreadMessages = async (threadId: string, loadMore = false, limit = 15) : Promise<boolean|null> => {
    if (!messages.value[threadId])
      messages.value[threadId] = []

    let firstLoadedMessage: string | null = null

    if (messages.value[threadId].length > 0) {
      firstLoadedMessage = messages.value[threadId][0].message_id
      if (!loadMore)
        return true
    }

    const messagesRef = rtdbRef(rtdb, `chats/${threadId}/messages`)
    let initialMessageRef = rtdbQuery(messagesRef, orderByKey(), limitToLast(limit))
    if (firstLoadedMessage)
      initialMessageRef = rtdbQuery(initialMessageRef, endBefore(firstLoadedMessage))

    const initialData = await new Promise((resolve, reject) => {
      const onError = (error) => reject(error)
      const onData = (snap) => resolve(snap.val())

      onValue(initialMessageRef, onData, onError, { onlyOnce: true })
    }) as Record<string, ChatMessage>

    if (!initialData)
      return false

    const tempMessages: Array<ChatMessage> = []
    Object.values(initialData).map((value) => {
      const msg = value as ChatMessage
      if (msg.timestamp)
        msg.timestamp = new Timestamp(msg.timestamp?.seconds, msg.timestamp?.nanoseconds)

      tempMessages.push(msg)
      return null
    })

    messages.value[threadId] = tempMessages.concat(messages.value[threadId])
    return Object.values(initialData).length === limit
  }

  const lazyLoadThreadOrder = async (limit = 30) : Promise<boolean> => {
    if (!currentUser.value || !currentCommunity.value)
      return false

    threadsLoadedAll.value = false

    // load 30 by 10s
    const _limit = 10
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i <= limit; i += _limit) {
      let endPoint = threadsLazyLoadPointer.value + _limit
      if (endPoint > threadOrder.value.length)
        endPoint = threadOrder.value.length

      const threadBatch = threadOrder.value.slice(threadsLazyLoadPointer.value, endPoint)

      if (!threadBatch.length)
        return true

      lazyLoadedThreadOrder.value = lazyLoadedThreadOrder.value.concat(threadBatch)

      threadsLazyLoadPointer.value = endPoint
    }

    return Object.values(threadOrder.value).length === lazyLoadedThreadOrder.value.length
  }

  const _setUserStatus = (state: 'online' | 'offline') => ({
    state,
    last_changed: serverTimestamp()
  })

  const init = async () => {
    if (currentUser.value?.id === process.env.VUE_APP_CHAT_SUPPORT_USER_ID) {
      const userStatusDatabaseRef = rtdbRef(rtdb, `/status/${currentUser.value?.id}`)

      onValue(rtdbRef(rtdb, '.info/connected'), (snapshot) => {
        if (snapshot.val() === false)
          return

        onDisconnect(userStatusDatabaseRef)
          .set(_setUserStatus('offline'))
          .then(async () => {
            await set(userStatusDatabaseRef, _setUserStatus('online'))
          })
      })
    }

    onValue(rtdbRef(rtdb, `status/${process.env.VUE_APP_CHAT_SUPPORT_USER_ID}`), (snapshot) => {
      const supportStatus = snapshot.val()
      if (!supportStatus)
        return

      if (process.env.VUE_APP_CHAT_SUPPORT_USER_ID)
        status.value[process.env.VUE_APP_CHAT_SUPPORT_USER_ID] = supportStatus.state === 'online'
    })
  }

  const getThreadByMembers = async (memberList: Partial<User>[]) => {
    if (!currentUser.value?.userRef)
      throw new Error('Current user not loaded')

    if (!threads.value.length)
      await _loadThreads()

    let memberListIds = memberList.map((member) => member.id)
    if (!memberListIds.includes(currentUser.value.userRef.id))
      memberListIds.push(currentUser.value.userRef.id)
    memberListIds = memberListIds.sort()
    let existing = false
    let existingThreadId: string | null = null

    Object.entries(threads.value).every(([key, thread]) => {
      if (thread.type === 'bot')
        return true

      const tmIds = thread.members.map((tm) => tm.id)
        .filter((id) => id).sort()

      existing = (memberListIds.length === tmIds.length) &&
        memberListIds.every((element, index) => element === tmIds[index])

      if (existing)
        existingThreadId = thread.thread_id

      return !existing
    })

    if (!existingThreadId)
      return null

    return threads.value[existingThreadId]
  }

  const addThreadMember = async (threadId: string, userId: string) => {
    const threadRef = doc(collection(db, 'threads'), threadId)
    await setDoc(threadRef, { members: arrayUnion(doc(db, `users/${userId}`)) }, { merge: true })
    const rtdbThreadMemberRef = rtdbRef(rtdb, `chats/${threadId}/members/${userId}`)
    await set(rtdbThreadMemberRef, { isMember: true })
  }

  const removeThreadMember = async (threadId: string, userId: string) => {
    const threadRef = doc(collection(db, 'threads'), threadId)
    await setDoc(threadRef, { members: arrayRemove(doc(db, `users/${userId}`)) }, { merge: true })
    const rtdbThreadMemberRef = rtdbRef(rtdb, `chats/${threadId}/members/${userId}`)
    await remove(rtdbThreadMemberRef)
  }

  const unHideThread = (threadId: string) => {
    if (!threads.value[threadId] || !threads.value[threadId].meta || !threads.value[threadId].meta?.hideThread)
      return

    threads.value[threadId].meta = {
      ...threads.value[threadId].meta,
      hideThread: false
    }

    setDoc(
      doc(db, 'threads', threadId),
      { meta: { hideThread: false } },
      { merge: true }
    )
  }

  const setActiveThread = (threadId: string) => {
    activeThreadId.value = threadId
  }

  const createThread = async (thread: Thread) => new Promise<Thread>(async (resolve) => {
    const newThreadRef = doc(collection(db, 'threads'))
    const newThreadId = newThreadRef.id
    const newThread: Thread = { ...thread, thread_id: newThreadId }

    await setDoc(newThreadRef, newThread)
    threads.value = { ...threads.value, [newThreadId]: newThread }
    messages.value[newThreadId] = []

    setActiveThread(newThreadId)
    addToTopThread(newThreadId, true)

    resolve(newThread)
  })

  const renameThread = async (threadId: string, threadName: string) => {
    const threadRef = doc(collection(db, 'threads'), threadId)
    await setDoc(threadRef, { name: threadName }, { merge: true })
  }

  const removeThread = async (threadId: string) => {
    await remove(rtdbRef(rtdb, `chats/${threadId}`))
    await deleteDoc(doc(collection(db, 'threads'), threadId))
  }

  const removeFromThread = async (threadId: string, memberId: string) => {
    const threadRef = doc(collection(db, 'threads'), threadId)
    await setDoc(threadRef, { members: arrayRemove(doc(db, `users/${memberId}`)) }, { merge: true })
    const rtdbThreadMemberRef = rtdbRef(rtdb, `chats/${threadId}/members/${memberId}`)
    await remove(rtdbThreadMemberRef)
  }

  const clearTyping = async () => {
    const { userRef: currentUser } = storeToRefs(useAuthStore())
    await setDoc(doc(db, `threads/${activeThreadId.value}`), { typing: arrayRemove(currentUser.value) }, { merge: true })
  }

  const userIsTyping = async (ev) => {
    if (ev.key === 'Enter')
      return

    const { userRef: currentUser } = storeToRefs(useAuthStore())
    await setDoc(doc(db, `threads/${activeThreadId.value}`), { typing: arrayUnion(currentUser.value) }, { merge: true })

    if (typingTimeout.value)
      clearTimeout(typingTimeout.value)

    await new Promise((resolve) => {
      typingTimeout.value = setTimeout(resolve, 2500)
    })

    await clearTyping()
    typingTimeout.value = null
  }

  const replyTo = async (message: ChatMessage | null) => {
    reply.value = message
  }

  const sendMessage = async (threadId: string, message: ChatMessage) => {
    if (!messages.value[threadId])
      messages.value[threadId] = []

    if (reply.value)
      message.reply_to_id = reply.value.message_id

    const messagesRef = push(rtdbRef(rtdb, `chats/${threadId}/messages`))
    if (messagesRef.key) {
      message.message_id = messagesRef.key
      await set(messagesRef, message)
    }

    if (reply.value)
      reply.value = null
  }

  const _saveMessage = async (message: ChatMessage) => {
    const formattedTimestamp = message.formattedTimestamp
    delete message.formattedTimestamp

    const rtdbThreadRef = rtdbRef(rtdb, `chats/${message.thread_id}`)
    const rtdbThreadSnap = await get(rtdbThreadRef)
    const rtdbThreadData = rtdbThreadSnap.exists() ? rtdbThreadSnap.val() : {}

    if (!rtdbThreadData.members && activeThread.value) {
      // solution for existing rtdb threads without members are not set
      // which we will use for the rtdb rule to allow reactions for non-author but member of the thread
      activeThread.value.members.forEach(async (member) => {
        await set(
          rtdbRef(rtdb, `chats/${message.thread_id}/members/${member.id}`),
          {
            isMember: true
          }
        )
      })
    }

    await set(
      rtdbRef(rtdb, `chats/${message.thread_id}/messages/${message.message_id}`),
      message
    )

    message.formattedTimestamp = formattedTimestamp
    return message
  }

  const editMessage = async (updatedMessage: ChatMessage) => {
    await _saveMessage(updatedMessage)
  }

  const deleteMessage = async (deletedMessage: ChatMessage) => {
    await remove(
      rtdbRef(
        rtdb,
        `chats/${deletedMessage.thread_id}/messages/${deletedMessage.message_id}`
      )
    )
    messages.value[deletedMessage.thread_id] = messages.value[deletedMessage.thread_id].filter(
      (message) => deletedMessage.message_id !== message.message_id
    )
  }

  const toggleReaction = async (message: ChatMessage, user_id: string, reaction: string) => {
    let tempReaction = (message.reactions || {})[reaction]

    if (!tempReaction)
      tempReaction = [user_id]
    else if (typeof tempReaction === 'boolean')
      tempReaction = []
    else if (!tempReaction.includes(user_id))
      tempReaction = [...tempReaction, user_id]
    else
      tempReaction = tempReaction.filter((react_user) => react_user !== user_id)

    message.reactions = { ...message.reactions, [reaction]: tempReaction }
    await _saveMessage(message)
  }

  const switchChatSound = (val: boolean) => {
    if (!userRef.value)
      return

    setDoc(
      userRef.value,
      { meta: { chatSoundStatus: val } },
      { merge: true }
    )

    chatSoundStatus.value = val
  }

  const _loadUserChatSoundStatus = async () => {
    if (!currentUser.value)
      return

    const userData = currentUser.value?.profile
    let status = true

    // default true
    if (userData && userData.meta && userData.meta.chatSoundStatus != null)
      status = userData.meta.chatSoundStatus

    chatSoundStatus.value = status
  }

  const resetThreadStore = () => {
    activeThreadId.value = null
    threads.value = {}
    threadOrder.value = []
    newThreadOrder.value = []
    if (threadSnapshot)
      threadSnapshot()
    threadSnapshot = null
    lazyLoadedThreadOrder.value = []
    threadsLoadedAll.value = false
    threadsLazyLoadPointer.value = 0
    if (messageSnapshots.value) {
      Object.values(messageSnapshots.value).forEach((snap) => {
        if (snap.add)
          snap.add()

        if (snap.update)
          snap.update()
        return snap
      })
    }
    messageSnapshots.value = {}
    messages.value = {}
  }

  watch(() => currentCommunity.value, async (val) => {
    resetThreadStore()

    if (val) {
      threadsLoading.value = true
      await init()
      await _loadUserChatSoundStatus()
      await _loadNewThreadOrder()
      await _loadThreadOrder()
      await _loadSupportThreadOrder()

      await _loadThreads()
      await lazyLoadThreadOrder()

      if (!activeThreadId.value)
        _selectUserActiveThread()

      threadsLoading.value = false
    }
  }, { immediate: true })

  return {
    init,
    activeThreadId,
    activeThread,
    getThreadById,
    lazyLoadThreadOrder,
    getThreadList,
    getThreadOrder,
    getNewThreadOrder,
    threadHasNewChats,
    status,
    getThreadByMembers,
    addThreadMember,
    removeThreadMember,
    unHideThread,
    setActiveThread,
    createThread,
    renameThread,
    removeThread,
    removeFromThread,
    updateThread,
    typers,
    userIsTyping,
    clearTyping,
    clearNewChat,
    reply,
    replyTo,
    sendMessage,
    loadThreadMessages,
    getThreadMessageById,
    getThreadMessages,
    editMessage,
    deleteMessage,
    toggleReaction,
    isChatSoundOn,
    switchChatSound,
    recorders,
    hasNewChats,
    messageSnapshots,
    threadSnapshot,
    threadsLoading,
    searchThreadList,
    resetThreadStore
  }
})
