import { storeToRefs } from 'pinia'
import {
  createRouter,
  createWebHistory
} from 'vue-router'

import { routes } from '@/router/routes'

import { useCoreStore } from '@/stores/core'
import { useSpinnerStore } from '@/stores/spinner'
import { usePermissions } from '@/composables/usePermissions'
import { useFirebaseAuth } from '@/composables/useFirebaseAuth'

import { routeInactiveCommunity } from './guards/account'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const { logout } = useFirebaseAuth()
  const { currentUser, isCommunityActive } = storeToRefs(useCoreStore())
  const { getCurrentUserAuth, setMember } = useCoreStore()
  const { can } = usePermissions()

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some((record) => record.meta.requiresUnauth)
  const requiresPermission = to.matched.some((record) => record.meta.requiresPermission)

  useSpinnerStore().start()
  const currentUserAuth = await getCurrentUserAuth()
  if (!currentUser.value)
    await setMember(currentUserAuth)

  if (requiresAuth && !currentUserAuth) {
    next({ name: 'logout' })
  } else if (requiresUnauth && currentUserAuth) {
    next({ name: 'home' })
  } else if (to.name === 'logout') {
    await logout()
    next({ name: 'login' })
  } else if (requiresPermission) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const action = to.meta.requiresPermission.action as string
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const subject = to.meta.requiresPermission.subject as string

    if (can(action, subject))
      next()
    else
      next({ name: 'home' })
  } else if (!isCommunityActive.value && currentUserAuth) {
    await routeInactiveCommunity(to, from, next)
  } else { next() }
})

router.afterEach(() => {
  useSpinnerStore().done()
})

export default router
