import { RouterView } from 'vue-router'

import { checkJurniPaid, resumeOnboarding, routeOnboarding } from '@/router/guards'
import { fallbackToFirstChapter, fallbackToFirstStep } from '@/router/guards/jurni'
import JurniGameboard from '@/views/jurnis/_id/gameboard.vue'
import JurniMembers from '@/views/jurnis/_id/members.vue'
import JurniOnboardingSettings from '@/views/jurnis/_id/onboarding_settings.vue'
import JurniOnboardingResponses from '@/views/jurnis/_id/onboarding_responses.vue'
import JurniPaymentSettings from '@/views/jurnis/_id/paypal_plan_settings.vue'
import Onboarding from '@/views/onboarding/index.vue'
import OnboardingStudentPayment from '@/views/onboarding/contractor_payment.vue'
import OnboardingSteps from '@/views/onboarding/steps.vue'
import JurniStep from '@/views/jurnis/_id/steps/_id/index.vue'
import JurniChapter from '@/views/jurnis/_id/steps/_id/chapters/_id/index.vue'
import JurniChapterSidebar from '@/views/jurnis/_id/steps/_id/chapters/_id/sidebar.vue'
import JurniHomework from '@/views/jurnis/_id/homeworks/_id/index.vue'
import JurniHomeworkResults from '@/views/jurnis/_id/homeworks/_id/results.vue'
import Jurnis from '@/views/jurnis/index.vue'
import ArchivedJurnis from '@/views/jurnis/archived.vue'

export default [
  {
    path: '/courses',
    name: 'jurnis',
    component: Jurnis,
    meta: { requiresAuth: true }
  },
  {
    path: '/archived-courses',
    name: 'archived-jurnis',
    component: ArchivedJurnis,
    meta: {
      requiresAuth: true,
      requiresPermission: {
        action: 'manage',
        subject: 'community'
      }
    }
  },
  {
    path: '/course/:jurniId',
    name: 'jurni',
    component: RouterView,
    props: true,
    meta: { requiresAuth: true },
    redirect: (to) : any => ({
      name: 'jurni.gameboard',
      params: { jurniId: to.params.jurniId }
    }),
    children: [
      {
        path: 'gameboard',
        name: 'jurni.gameboard',
        props: true,
        component: JurniGameboard,
        beforeEnter: resumeOnboarding,
        meta: { requiresAuth: true }
      },
      {
        path: 'members',
        name: 'jurni.members',
        props: true,
        component: JurniMembers
      },
      {
        path: 'onboarding-settings',
        name: 'jurni.onboarding-settings',
        props: true,
        component: JurniOnboardingSettings
      },
      {
        path: 'onboarding-responses',
        name: 'jurni.onboarding-responses',
        props: true,
        component: JurniOnboardingResponses
      },
      {
        path: 'payment-settings',
        name: 'jurni.payment-settings',
        props: true,
        component: JurniPaymentSettings,
        meta: {
          requiresPermission: {
            action: 'setupSubscriptions',
            subject: 'jurni'
          }
        }
      },
      {
        name: 'jurni.onboarding',
        path: 'onboarding',
        component: Onboarding,
        props: true,
        beforeEnter: routeOnboarding,
        meta: {
          layout: 'Default'
        },
        children: [
          {
            path: 'contractor-payment',
            alias: '',
            name: 'jurni.onboarding.contractor-payment',
            props: true,
            beforeEnter: routeOnboarding,
            component: OnboardingStudentPayment
          },
          {
            path: 'steps',
            alias: '',
            name: 'jurni.onboarding.steps',
            props: true,
            component: OnboardingSteps
          }
        ]
      },
      {
        path: 'steps',
        name: 'jurni.steps',
        props: (route) : Record<string, string | string[]> => ({
          jurniId: route.params.jurniId,
          stepId: route.params.stepId
        }),
        beforeEnter: fallbackToFirstStep,
        meta: { requiresAuth: true },
        component: RouterView,
        children: [
          {
            path: ':stepId',
            name: 'jurni.step',
            props: true,
            beforeEnter: fallbackToFirstChapter,
            components: {
              default: JurniStep
            },
            children: [
              {
                path: 'chapters',
                redirect: (to) : any => ({
                  name: 'jurni.step',
                  params: { jurniId: to.params.jurniId, stepId: to.params.stepId }
                })
              },
              {
                name: 'jurni.step.chapter',
                path: 'chapters/:chapterId',
                props: true,
                beforeEnter: checkJurniPaid,
                components: {
                  content: JurniChapter,
                  sidebar: JurniChapterSidebar
                }
              }
            ]
          }
        ]
      },
      {
        path: 'homeworks',
        name: 'jurni.homeworks',
        props: true,
        meta: { requiresAuth: true },
        component: RouterView,
        children: [
          {
            path: ':homeworkId',
            name: 'jurni.homework',
            props: true,
            meta: { requiresAuth: true },
            component: JurniHomework
          },
          {
            path: ':homeworkId/results',
            name: 'jurni.homework.results',
            props: true,
            meta: { requiresAuth: true },
            component: JurniHomeworkResults
          }
        ]
      }
    ]
  }
]
