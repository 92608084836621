import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'

import AgoraRTC from 'agora-rtc-sdk-ng'
import {
  IAgoraRTCClient,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ILocalTrack,
  IAgoraRTCRemoteUser,
  ScreenVideoTrackInitConfig,
  UID
} from 'agora-rtc-sdk-ng'
import {
  setDoc,
  doc,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore'

import { fetchToken } from '@/services/agora-call'
import { db } from '@/services/firebase'
import { acquire, start, query, stop } from '@/services/agora-recording'

import { LocalStream } from '@/components/meeting/types'

import { useCoreStore } from '@/stores/core'
import { useThreadStore } from '@/stores/thread'
import { useCalendarStore } from '@/stores/calendar'

export const useAgoraCallStore = defineStore('agoraCall', () => {
  let client: IAgoraRTCClient | null = null
  let screenSharingClient: IAgoraRTCClient | null = null
  const rtcToken = ref<string>('')
  const isMicOn = ref(false)
  const isVideoOn = ref(false)
  const isMicAvailable = ref(false)
  const isVideoAvailable = ref(false)
  const localCameraStream = ref<LocalStream | null>(null)
  const screenRtcToken = ref<string>('')
  const localScreenStream = ref<LocalStream | null>(null)
  const isScreenSharing = ref(false)
  const focusedId = ref<string | UID>('')
  const displayMode = ref('pip')
  const showSidebar = ref(true)
  const remoteUsers = ref<Record<string, IAgoraRTCRemoteUser>>({})
  const remoteUserProfiles = ref<Record<string, string>>({})
  const triggerReload = ref({})
  const initiatedRecording = ref(false)
  const recordingResourceId = ref<string | null>(null)
  const recordingSid = ref<string | null>(null)
  const isRecorderAndRecording = ref(false)
  const stoppedRecording = ref(false)
  const meetingType = ref('threads')

  const RECORDING_SERVICE_NOT_STARTED = 0
  const RECORDING_SERVICE_INITIALIZED = 1
  const RECORDING_SERVICE_STARTING = 2
  const RECORDING_SERVICE_PARTIALLY_READY = 3
  const RECORDING_SERVICE_READY = 4
  const RECORDING_SERVICE_IN_PROGRESS = 5
  const RECODRING_SERVICE_REQUESTED_TO_STOP = 6
  const RECORDING_SERVICE_STOPS = 7
  const RECORDING_SERVICE_EXITS = 8
  const RECORDING_SERVICE_EXITS_ABNORMALLY = 20
  const RECORDING_STATUS_LIST_TO_STOP_START_QUERY = [
    RECORDING_SERVICE_IN_PROGRESS,
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]
  const RECORDING_STATUS_LIST_START_ERROR = [
    RECODRING_SERVICE_REQUESTED_TO_STOP,
    RECORDING_SERVICE_STOPS,
    RECORDING_SERVICE_EXITS,
    RECORDING_SERVICE_EXITS_ABNORMALLY
  ]

  const { getMemberIdByAgoraId } = useCoreStore()
  const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
  const { updateThread } = useThreadStore()
  const { getMeetingEventByMeetingLink, updateMeetingEvent } = useCalendarStore()

  const remoteUserList = computed(() => Object.values(remoteUsers.value))

  async function setupLocalCameraStream(uid: string, attendeeMode: string) {
    const defaultConfig = {
      streamID: uid,
      audio: true,
      video: true,
      screen: false
    }

    switch (attendeeMode) {
      case 'audio-only':
        defaultConfig.video = false
        break
      case 'audience':
        defaultConfig.video = false
        defaultConfig.audio = false
        break
      default:
      case 'video':
        break
    }

    // to check if mic or video is available
    let videoTrack: ILocalVideoTrack | null = null
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
      isVideoAvailable.value = true
      videoTrack = await AgoraRTC.createCameraVideoTrack()
    } catch (err) {
      defaultConfig.video = false
    }

    let audioTrack: ILocalAudioTrack | null = null
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true })
      isMicAvailable.value = true
      audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
    } catch (err) {
      defaultConfig.audio = false
    }

    isVideoOn.value = defaultConfig.video
    isMicOn.value = defaultConfig.audio

    const stream = {
      videoTrack,
      audioTrack,
      uid: defaultConfig.streamID,
      type: 'camera'
    } as LocalStream

    localCameraStream.value = stream
  }

  const publishLocalStream = async (uid) => {
    if (!localCameraStream.value || !client)
      throw new Error('unable to start video')

    if (localCameraStream.value.audioTrack && localCameraStream.value.videoTrack) {
      client.publish([
        localCameraStream.value.audioTrack as unknown as ILocalTrack,
        localCameraStream.value.videoTrack as unknown as ILocalTrack
      ])
    } else if (localCameraStream.value.videoTrack) {
      client.publish([localCameraStream.value.videoTrack as unknown as ILocalTrack])
    } else if (localCameraStream.value.audioTrack) {
      client.publish([localCameraStream.value.audioTrack as unknown as ILocalTrack])
    }

    focusedId.value = `${uid}`
  }

  function removeRemoteUser(uid) {
    delete remoteUsers.value[uid]
    delete remoteUserProfiles.value[uid]
  }

  async function addRemoteUser(remoteUser) {
    remoteUsers.value[remoteUser.uid] = remoteUser
    remoteUserProfiles.value[remoteUser.uid] = await getMemberIdByAgoraId(remoteUser.uid)
    if (triggerReload.value[remoteUser.uid])
      triggerReload.value[remoteUser.uid] += 1

    else
      triggerReload.value[remoteUser.uid] = 1
  }

  const subscribeStream = async (remoteUser, mediaType) => {
    if (!client)
      throw new Error('unable to start video')

    try {
      await client.subscribe(remoteUser, mediaType)
      await addRemoteUser(remoteUser)
    } catch (error) {
      throw new Error(`Error in subscribing the remote user:: ${remoteUser.uid} >> ${mediaType} >> ${error}`)
    }
  }

  const subscribeStreamEvents = (streamId) => {
    if (!client)
      throw new Error('unable to start video')

    client.on('connection-state-change', (curState, prevState) => {
      console.log(`current_state:: ${curState} >> prev_state:: ${prevState}`)
    })

    client.on('media-reconnect-start', (uid) => {
      console.log(`Media reconnection start for uid:: ${uid}`)
      if (uid === streamId)
        publishLocalStream(streamId)
    })

    client.on('user-joined', (remoteUser) => {
      console.log('user-joined', remoteUser)
    })

    client.on('user-left', (remoteUser) => {
      console.log('user-left', remoteUser)
      removeRemoteUser(remoteUser.uid)

      if (remoteUser.uid === focusedId.value)
        focusedId.value = `${streamId}`
    })

    client.on('user-published', async (remoteStream, mediaType) => {
      console.log('user-published', remoteStream, mediaType)

      try {
        await subscribeStream(remoteStream, mediaType)
      } catch (error) {
        console.log(`Error in subscribing remote user:: ${error}`)
      }
    })

    client.on('user-unpublished', (remoteStream, mediaType) => {
      console.log('user-unpublished', remoteStream, mediaType)
      addRemoteUser(remoteStream)
    })
  }

  const joinStream = async (appId, room, uid) => {
    if (!appId || !room || !uid || !rtcToken.value || !client)
      throw new Error('unable to start video')

    try {
      client.join(appId, room, rtcToken.value, uid)
    } catch (err) {
      throw new Error(`failed to join stream: ${err}`)
    }
  }

  const createLocalScreenStream = async (screenUid: string, config?: ScreenVideoTrackInitConfig) => {
    const defaultConfig = {
      ...config
    }

    const videoTrack = await AgoraRTC.createScreenVideoTrack(defaultConfig, 'disable')

    const stream = {
      videoTrack,
      audioTrack: null,
      uid: `${screenUid}`,
      type: 'screen'
    } as LocalStream

    return stream
  }

  const stopSharingScreen = async (uid: string) => {
    if (!screenSharingClient)
      return

    try {
      if (localScreenStream.value?.videoTrack) {
        await screenSharingClient.unpublish([localScreenStream.value.videoTrack as unknown as ILocalVideoTrack])

        localScreenStream.value?.videoTrack?.setEnabled(false)
        localScreenStream.value?.videoTrack?.close()
      }

      await screenSharingClient.leave()

      isScreenSharing.value = false
      localScreenStream.value = null
      focusedId.value = `${uid}`
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const subscribeScreenStreamEvents = (uid: string, videoTrack?: ILocalVideoTrack | null) => {
    if (!videoTrack)
      throw new Error('failed to subscribe to stream events')

    videoTrack.on('track-ended', async () => {
      stopSharingScreen(uid)
    })
  }

  const setupLocalScreenStream = async (uid: string, screenUid: string) => {
    try {
      localScreenStream.value = await createLocalScreenStream(screenUid)

      subscribeScreenStreamEvents(uid, localScreenStream.value?.videoTrack as unknown as ILocalVideoTrack)
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const joinScreenStream = async (appId: string, room: string, screenUid: string) => {
    if (!appId || !room || !screenUid || !screenRtcToken.value || !screenSharingClient)
      throw new Error('unable to start screen sharing')

    try {
      focusedId.value = `${await screenSharingClient.join(appId, room, screenRtcToken.value, screenUid)}`
    } catch (err: any) {
      throw new Error(`failed to join screen share stream: ${err.message}`)
    }
  }

  const publishLocalScreenStream = async (screenUid: string) => {
    if (!localScreenStream.value?.videoTrack || !screenSharingClient)
      throw new Error('unable to start screen sharing')

    try {
      await screenSharingClient.publish(localScreenStream.value.videoTrack as unknown as ILocalVideoTrack)

      isScreenSharing.value = true
      focusedId.value = screenUid
    } catch (err: any) {
      throw new Error(`failed to start screen sharing: ${err.message}`)
    }
  }

  const getRemoteUserId = (streamUid) => {
    if (!streamUid)
      return null

    const agoraId = streamUid.includes('screen') ? streamUid.slice(7) : streamUid

    return remoteUserProfiles.value[agoraId]
  }

  const focusUser = (steamUid: string) => {
    displayMode.value = 'pip'
    focusedId.value = steamUid
  }

  const switchDisplay = (e) => {
    if (displayMode.value === 'pip')
      displayMode.value = 'tile'
    else if (displayMode.value === 'tile')
      displayMode.value = 'pip'
    else if (displayMode.value === 'share')
      console.log('do nothing or alert, tbd')
    else
      throw new Error('Display Mode can only be tile/pip/share')
  }

  const initAgoraCall = async (room, uid, transcode) => {
    try {
      const responseData = await fetchToken(room, uid, true)
      rtcToken.value = responseData.rtcToken

      client = AgoraRTC.createClient({ mode: transcode, codec: 'vp8' })
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const initAgoraScreenShare = async (room, screenUid, transcode) => {
    try {
      const responseData = await fetchToken(room, screenUid, true)
      screenRtcToken.value = responseData.rtcToken

      screenSharingClient = AgoraRTC.createClient({ mode: transcode, codec: 'vp8' })
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const trackMeetingJoiners = async (room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    let docId = room
    if (meetingType.value === 'meetingEvents')
      docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `${meetingType.value}/${docId}`),
      {
        joinedMeeting: arrayUnion(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const trackMeetingLeavers = async (room: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    let docId = room
    if (meetingType.value === 'meetingEvents')
      docId = await getMeetingEventByMeetingLink(room)

    await setDoc(
      doc(db, `${meetingType.value}/${docId}`),
      {
        joinedMeeting: arrayRemove(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const startRecordVideo = async (room: string, uid: string) => {
    if (!currentUser.value?.userRef)
      throw new Error('no user found')

    initiatedRecording.value = true

    // get cloud recording resource
    try {
      const resourceId = await acquire(room, `${uid}`)

      if (!resourceId)
        throw new Error('failed to acquire resource')

      recordingResourceId.value = resourceId

      // start a cloud recording
      const sid = await start(room, recordingResourceId.value, rtcToken.value, currentCommunity.value?.id, `${uid}`)

      if (!sid)
        throw new Error('failed to start cloud recording')

      recordingSid.value = sid

      let _recordingStatus = 0
      /* eslint-disable no-await-in-loop */
      while (!RECORDING_STATUS_LIST_TO_STOP_START_QUERY.includes(_recordingStatus))
        _recordingStatus = await query(recordingResourceId.value, recordingSid.value)

      if (RECORDING_STATUS_LIST_START_ERROR.includes(_recordingStatus))
        throw new Error('recording failed to start')

      isRecorderAndRecording.value = true
      initiatedRecording.value = false

      // track recording progress
      if (meetingType.value === 'threads') {
        await updateThread({
          meta: {
            recordingResourceId: recordingResourceId.value,
            recordingSid: recordingSid.value,
            startedRecordingBy: currentUser?.value?.userRef,
            recordingProgress: 'recording'
          }
        }, room)
      } else if (meetingType.value === 'meetingEvents') {
        await updateMeetingEvent({
          meta: {
            recordingResourceId: recordingResourceId.value,
            recordingSid: recordingSid.value,
            startedRecordingBy: currentUser?.value?.userRef,
            recordingProgress: 'recording'
          }
        }, await getMeetingEventByMeetingLink(room))
      }
    } catch (err: any) {
      initiatedRecording.value = false
      isRecorderAndRecording.value = false
      throw new Error(`${err.message}`)
    }
  }

  const stopRecordingVideo = async (room: string, uid: string) => {
    try {
      if (!recordingResourceId.value || !recordingSid.value)
        throw new Error('unable to find recording')

      if (!currentCommunity.value)
        throw new Error('no community found')

      // stop recording
      await stop(room, recordingResourceId.value, recordingSid.value, currentCommunity.value?.id, `${uid}`)

      isRecorderAndRecording.value = false
      stoppedRecording.value = false

      if (meetingType.value === 'threads') {
        await updateThread({
          meta: {
            recordingResourceId: null,
            recordingSid: null,
            startedRecordingBy: null,
            recordingProgress: 'stopped'
          }
        }, room)
      } else if (meetingType.value === 'meetingEvents') {
        await updateMeetingEvent({
          meta: {
            recordingResourceId: null,
            recordingSid: null,
            startedRecordingBy: null,
            recordingProgress: 'stopped'
          }
        }, await getMeetingEventByMeetingLink(room))
      }
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
    // recording is then saved in cloud storage
    // and created a content library recording item
    // handled in the cloud function
    // post link to chat?
    // send link to email of participants?
  }

  const endAgoraCall = async (room: string, uid: string, isRecording: boolean) => {
    if (!client)
      return

    try {
      if (isScreenSharing.value)
        await stopSharingScreen(uid)

      if (isRecording || isRecorderAndRecording.value)
        await stopRecordingVideo(room, uid)

      if (localCameraStream.value?.videoTrack) {
        await client.unpublish([localCameraStream.value.videoTrack as unknown as ILocalTrack])
        localCameraStream.value.videoTrack?.setEnabled(false)
        localCameraStream.value.videoTrack?.close()
      }

      if (localCameraStream.value?.audioTrack) {
        await client.unpublish([localCameraStream.value?.audioTrack as unknown as ILocalTrack])
        localCameraStream.value.audioTrack?.setEnabled(false)
        localCameraStream.value.audioTrack?.close()
      }

      await client.leave()
      localCameraStream.value = null
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  return {
    meetingType,
    isMicOn,
    isVideoOn,
    isMicAvailable,
    isVideoAvailable,
    initAgoraCall,
    client,
    screenSharingClient,
    setupLocalCameraStream,
    publishLocalStream,
    subscribeStreamEvents,
    triggerReload,
    joinStream,
    localCameraStream,
    localScreenStream,
    focusedId,
    displayMode,
    showSidebar,
    remoteUsers,
    remoteUserList,
    getRemoteUserId,
    initAgoraScreenShare,
    screenRtcToken,
    setupLocalScreenStream,
    subscribeScreenStreamEvents,
    isScreenSharing,
    joinScreenStream,
    publishLocalScreenStream,
    stopSharingScreen,
    focusUser,
    switchDisplay,
    endAgoraCall,
    trackMeetingJoiners,
    trackMeetingLeavers,
    startRecordVideo,
    initiatedRecording,
    isRecorderAndRecording,
    stopRecordingVideo,
    stoppedRecording,
    RECORDING_STATUS_LIST_TO_STOP_START_QUERY,
    RECORDING_STATUS_LIST_START_ERROR
  }
})
