import { storeToRefs } from 'pinia'
import { useOnboardingStore } from '@/stores/onboarding'
import { useAlertStore } from '@/stores/alert'
import { useCoreStore } from '@/stores/core'
import { usePermissions } from '@/composables/usePermissions'
import { useJurniStore } from '@/stores/jurni'

export async function isJurniPaid(jurniId: string) {
  const { currentUser } = storeToRefs(useCoreStore())
  const { isUserAnActiveJurniSubscriber, loadJurni } = useJurniStore()

  const { can } = usePermissions()

  if (can('manage', 'community'))
    return true

  if (!currentUser.value)
    return false

  await loadJurni(jurniId)
  return isUserAnActiveJurniSubscriber(jurniId)
}

export async function isJurniFree(jurniId: string) : Promise<boolean> {
  if (!jurniId)
    return false

  const { loadJurni } = useJurniStore()
  const jurni = await loadJurni(jurniId, true)

  if (!jurni || !jurni?.paypal || !jurni?.paypal?.planId)
    return true

  return jurni?.paypal?.isFree ?? false
}

export async function doesUserNeedToSetupPayment(jurniId: string) : Promise<boolean> {
  if (!jurniId || jurniId === 'coach')
    return false

  const jurniStore = useJurniStore()
  const jurni = await jurniStore.loadJurni(jurniId)

  if (!jurni || !jurni?.paypal || !jurni?.paypal?.planId)
    return false

  return !jurni?.paypal?.isFree ?? true
}

export async function routeOnboarding(to, from, next) {
  const onboardingStore = useOnboardingStore()

  const { getCurrentUserAuth } = useCoreStore()
  const isAuthValid = async () : Promise<boolean> => {
    const auth = await getCurrentUserAuth()
    return !!auth
  }

  const invitationHash = to.params.invitationHash
  const preview = Boolean(to.query.preview)
  let jurniId = to.params.jurniId
  let invitation
  const isCoach = jurniId === 'coach'

  if (invitationHash) {
    invitation = await onboardingStore.loadInvitation(invitationHash)
    jurniId = invitation.value.jurniId
  }

  if (to.name === 'onboarding.claim-account') {
    next()
  } else if (await isAuthValid() && jurniId && !isCoach) {
    // student/contractor routes
    const onboarding = await onboardingStore.loadUserOnboarding(jurniId)
    const userNeedsToSetupPayment = await doesUserNeedToSetupPayment(jurniId)

    if (to.name === 'jurni.onboarding.contractor-payment' && userNeedsToSetupPayment) {
      next()
    } else if (to.name === 'jurni.onboarding.contractor-payment' && !userNeedsToSetupPayment) {
      next({
        name: 'jurni.onboarding.steps',
        params: { jurniId }
      })
    } else if (to.name === 'jurni.onboarding.steps' && !userNeedsToSetupPayment) {
      next()
    } else if (to.name === 'jurni.onboarding.steps' && userNeedsToSetupPayment) {
      next({
        name: 'jurni.onboarding.contractor-payment',
        params: { jurniId }
      })
    } else if (onboarding?.completed && !preview) {
      next({
        name: 'jurni',
        params: { jurniId }
      })
    } else {
      next({
        name: 'jurni.onboarding.steps',
        params: { jurniId }
      })
    }
  } else if (await isAuthValid() && jurniId && isCoach) {
    // coach onboarding routes
    if (invitation) {
      if (invitation.value.docType === 'community-coach') {
        const onboarding = await onboardingStore.loadUserOnboarding('coach')
        if (onboarding?.completed)
          next({ name: 'home' })
        else if (to.name === 'jurni.onboarding.steps')
          next()
        else
          next({ name: 'jurni.onboarding.steps', params: { jurniId: 'coach' } })
      } else {
        next()
      }
    }
  } else {
    next({ name: 'login' })
  }
}

export async function resumeOnboarding(to, from, next) {
  const { add } = useAlertStore()
  const { getJurniById } = useJurniStore()

  const { currentUser } = storeToRefs(useCoreStore())
  const { loadUserOnboarding } = useOnboardingStore()
  const jurniId = to.params.jurniId
  const jurni = getJurniById(jurniId)

  if (jurni?.coach?.id === currentUser.value?.id)
    next()

  if (to.name === 'jurni.onboarding.contractor-payment')
    next()

  // routing from payment to gameboard should mean payment was approved
  if (to.name === 'jurni.gameboard' && from.name === 'jurni.onboarding.contractor-payment')
    next()

  if (await isJurniPaid(jurniId) === false && await isJurniFree(jurniId) === false) {
    add({ description: 'Entering a paid course. Please review your subscription details or contact customer support', color: 'danger' })
    next({
      name: 'jurni.onboarding.contractor-payment',
      params: { jurniId }
    })
  } else if (!jurni || !jurni.onboardingflow) {
    next()
  } else {
    const onboarding = await loadUserOnboarding(jurniId)

    if (onboarding?.completed || onboarding?.onboardingflow.steps.length === 0) {
      next()
    } else {
      next({
        name: 'jurni.onboarding',
        params: { jurniId }
      })
    }
  }
}

export async function checkJurniPaid(to, from, next) {
  const { jurniId } = to.params

  if (await isJurniPaid(jurniId) === false && await isJurniFree(jurniId) === false) {
    const { add } = useAlertStore()
    add({ description: 'Entering a paid course. please review your subscription details or contact customer support', color: 'danger' })
    next({
      name: 'jurni.onboarding.contractor-payment',
      params: { jurniId }
    })
  } else { next() }
}

export async function muteCallNotification(to) {
  if (to.name === 'meet' || to.name === 'meet.room') {
    const alertStore = useAlertStore()
    if (Object.keys(alertStore.audio).includes('meeting'))
      alertStore.stopAudio('meeting')
  }
}

export default {
  routeOnboarding,
  resumeOnboarding,
  muteCallNotification,
  isJurniPaid,
  isJurniFree,
  checkJurniPaid
}
