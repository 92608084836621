import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { useAlertStore } from '@/stores/alert'

type UseInvites = (itemType: string, itemId: string, itemName: string) => {
  send: (users: any[]) => void
}

export const useInvites: UseInvites = (itemType, itemId, itemName) => {
  const send = (users: any[]) : void => {
    const alertStore = useAlertStore()
    const counts = { success: 0, failure: 0 }

    users.map((user) => {
      const notificationData = {
        meta: {
          user: doc(db, `users/${user.id}`),
          accessLevel: user.role,
          item: {
            ref: doc(db, `${itemType}s/${itemId}`),
            name: itemName || ''
          }
        },
        status: 'pending',
        notificationType: `invite.${itemType}`
      }

      try {
        setDoc(doc(collection(db, 'notifications')), notificationData)
        counts.success += 1
      } catch (error) {
        counts.failure += 1
      }
      return null
    })

    if (counts.success > 0)
      alertStore.add({ description: `Successfully sent ${itemType} invitations to ${counts.success} member(s)`, color: 'success' })

    if (counts.failure > 0)
      alertStore.add({ description: `Failed to send ${itemType} invitations to ${counts.failure} member(s)`, color: 'danger' })
  }

  return {
    send
  }
}
