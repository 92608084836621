import { User as UserAuth } from 'firebase/auth'
import {
  collection,
  collectionGroup,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where
} from 'firebase/firestore'
import { computed, ref, Ref, watch } from 'vue'
import { db } from '@/services/firebase'
import { User, Jurni, Group, Invitation, UserBillables, UserBillingDetails, ContentLibraryItem } from '@/types'

import { useJurniStore } from '@/stores/jurni'
import { useTimestamp } from '@/composables/utils/useTimestamp'
import { storeToRefs } from 'pinia'
import { useCoreStore } from '@/stores/core'

export interface UseUser {
  id: Ref<string>
  userRef: DocumentReference<User>
  profile: Ref<User | null>
  name: Ref<string>
  getClaims: () => Promise<void>
  claims: Ref<Record<string, any> | null>
  role: Ref<string | null>
  activeCommunityId: Ref<string>
  jurnis: Ref<Jurni[]>
  groups: Ref<Group[]>
  joined: Ref<Timestamp | string>
  lastActivityDate: Ref<Timestamp | string>
  isActiveLast30Days: Ref<boolean>
  status: Ref<string>
  billingStatus: Ref<string>
  studentBillPayment: Ref<UserBillables[]>
  coachBillPayment: Ref<UserBillables[]>
  userBillingDetails: Ref<UserBillingDetails[]>
  giroCustomerId: Ref<string>
  activePaymentMethodId: Ref<string>
  paymentSettingChangedExcludeBillingFrom: Ref<Record<string, boolean>>
  sharedContent: Ref<DocumentReference<ContentLibraryItem>[]>
}

export const useUser = (userId: string, userAuth: UserAuth | null) : UseUser => {
  const id = ref(userId)
  const userRef: DocumentReference<User> = doc(db, 'users', userId) as DocumentReference<User>
  const profile: Ref<User | null> = ref(null)
  const name: Ref<string> = ref('')
  const claims = ref<Record<string, any> | null>(null)
  const activeCommunityId = ref('')
  const jurnis = ref<Jurni[]>([])
  const groups = ref<Group[]>([])
  const invitation = ref<Invitation | null>(null)
  const billingStatus = ref('Inactive')
  const studentBillPayment = ref<UserBillables[]>([])
  const coachBillPayment = ref<UserBillables[]>([])
  const userBillingDetails = ref<UserBillingDetails[]>([])
  const giroCustomerId = ref('')
  const activePaymentMethodId = ref('')
  const isActiveLast30Days = ref(false)
  const paymentSettingChangedExcludeBillingFrom = ref({})
  const sharedContent = ref<DocumentReference<ContentLibraryItem>[]>([])

  const getName = () => {
    if (!profile.value)
      return ''

    if (profile.value.meta?.isBot && profile.value.meta?.name)
      return profile.value.meta.name

    if (profile.value?.preferredName)
      return profile.value.preferredName

    if (profile.value?.firstName || profile.value?.middleInitial || profile.value?.lastName) {
      return [
        profile.value?.firstName,
        ...(profile.value?.middleInitial ? [profile.value?.middleInitial] : []),
        profile.value?.lastName
      ].join(' ')
    }
    return profile.value?.email ?? ''
  }

  const lastActivityDate = computed<string | Timestamp>(() => {
    if (!profile.value || !profile.value.meta || !profile.value.meta?.lastActivityDate)
      return ''

    return profile.value.meta.lastActivityDate as Timestamp
  }) as Ref

  const getActivityStatus = () => {
    if (!profile.value || !profile.value.meta || !profile.value.meta?.lastActivityDate)
      return false

    const dateNow = Timestamp.now()
    const timeDiff = dateNow.toDate().getTime() - profile.value.meta?.lastActivityDate.toDate().getTime()
    const daysDiff = Math.floor(timeDiff / 1000 / 60 / 60 / 24)

    if (daysDiff <= 30)
      return true

    return false
  }

  const getClaims = async () => {
    if (!userAuth)
      return

    const tokenResult = await userAuth.getIdTokenResult()

    if (tokenResult)
      claims.value = tokenResult.claims
  }

  const getInvitation = async () => {
    if (!profile.value || !profile.value?.email)
      return

    if (!invitation.value) {
      try {
        const invitationQuerySnapshot = await getDocs(
          query(
            collectionGroup(db, 'invitations'),
            where('email', '==', profile.value.email)
          )
        )

        invitationQuerySnapshot.forEach((doc) => {
          invitation.value = doc.data() as Invitation
        })
      } catch (e) {
        console.error('useUser : getInvitation', e)
      }
    }
  }

  const getJurnis = async () => {
    const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
    if (!currentCommunity.value?.id || !currentUser.value)
      return

    const jurniStore = useJurniStore()
    // const { loadUserJurnies } = useJurniStore()
    // await loadUserJurnies()
    const communityRef = doc(db, 'communities', currentCommunity.value.id)
    try {
      const jurniesSnapshot = await getDocs(
        query(
          collection(db, 'jurnis'),
          where('community', '==', communityRef),
          where('members', 'array-contains', currentUser.value.userRef)
        )
      )

      await Promise.all(jurniesSnapshot.docs.map(async (jurniRef) => {
        let jurni = jurniStore.getJurniById(jurniRef.id)

        if (!jurni)
          jurni = await jurniStore.loadJurni(jurniRef.id)

        if (jurni)
          jurnis.value.push(jurni)
      }))
    } catch (e) {
      console.error('useUser : getJurnis', e)
    }
  }

  const getGroups = async () => {
    const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
    if (!currentCommunity.value?.id || !currentUser.value)
      return

    const communityRef = doc(db, 'communities', currentCommunity.value.id)
    try {
      const groupQuerySnapshot = await getDocs(
        query(
          collection(db, 'groups'),
          where('community', '==', communityRef),
          where('members', 'array-contains', currentUser.value.userRef)
        )
      )

      groupQuerySnapshot.forEach((doc) => {
        const group = doc.data() as Group
        group.id = doc.id
        groups.value.push(group)
      })
    } catch (e) {
      console.error('useUser : getGroups', e)
    }
  }

  const getBillingStatus = async () => {
    const { currentCommunity } = storeToRefs(useCoreStore())

    if (!currentCommunity.value?.id)
      return

    const commSettings = await getDoc(doc(userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()
    billingStatus.value = comSettingsData?.billingStatus ? comSettingsData.billingStatus : 'Inactive'
  }

  const role = computed<string>(() => {
    if (!claims.value)
      return ''
    return claims.value?.role || ''
  })

  const joined = computed<string | Timestamp>(() => {
    if (!invitation.value)
      return '-'
    return useTimestamp(invitation.value.created || invitation.value.timeAccepted || Timestamp.now() as Timestamp)
  }) as Ref

  const status = computed<string | Timestamp>(() => {
    if (!invitation.value)
      return '-'

    if (invitation.value.timeAccepted)
      return 'Active'

    if (invitation.value.created)
      return 'Pending'

    return '-'
  }) as Ref

  watch(() => userId, async () => {
    const userSnap = await getDoc(userRef)
    const profileData = userSnap.data() as User
    const newTZ = new Date().getTimezoneOffset()
    if (userAuth && (!profileData.tzoffset || profileData.tzoffset !== newTZ)) {
      profileData.tzoffset = newTZ
      await setDoc(userRef, { tzoffset: newTZ }, { merge: true })
    }
    profile.value = profileData
    name.value = getName()
    isActiveLast30Days.value = getActivityStatus()
    activeCommunityId.value = profileData?.activeCommunityId ?? ''

    await getClaims()
    await getJurnis()
    await getGroups()
    await getInvitation()
    await getBillingStatus()
  }, { immediate: true })

  return {
    id,
    userRef,
    profile,
    name,
    getClaims,
    claims,
    role,
    activeCommunityId,
    jurnis,
    groups,
    joined,
    lastActivityDate,
    isActiveLast30Days,
    status,
    billingStatus,
    studentBillPayment,
    coachBillPayment,
    userBillingDetails,
    giroCustomerId,
    activePaymentMethodId,
    paymentSettingChangedExcludeBillingFrom,
    sharedContent
  }
}
