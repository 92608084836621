import { computed, ref } from 'vue'
import _ from 'lodash'

import { useAlertStore } from '@/stores/alert'
import { doc, DocumentReference, setDoc } from 'firebase/firestore'
import { db } from '@/services/firebase'

export declare interface NotificationComposable {
  id: string
  title: string
  body: string
  actionable: boolean
  updateStatus: CallableFunction
  docRef: DocumentReference | null
}

type UseNotification = (id: string) => NotificationComposable

export const useNotification : UseNotification = (id) => {
  const alertStore = useAlertStore()
  const notificationId = ref(id)

  const notification = computed(() => alertStore.getNotificationById(notificationId.value).value)
  const type = computed(() => notification.value.notificationType.split('.'))
  const actionable = computed(() => notification.value.status === 'pending')

  const title = computed(() => {
    if (type.value[0] === 'invite')
      return `${_.capitalize(type.value[1])} Invitation`

    return notification.value.notificationType
  })

  const body = computed(() => {
    if (type.value[0] === 'invite')
      return `You have been invited to ${type.value[1]}: '${notification.value.meta.item?.name}'`

    return notification.value.meta.content ? String(notification.value.meta.content) : ''
  })

  const updateStatus = (newStatus : string) : void => {
    const notificationRef = doc(db, 'notifications', notificationId.value)
    setDoc(notificationRef, { status: newStatus }, { merge: true })
    notification.value.status = newStatus
  }

  const docRef = computed(() => notification.value.meta.item?.ref ?? null)

  return {
    id: notificationId.value,
    title: title.value,
    body: body.value,
    actionable: actionable.value,
    docRef: docRef.value,
    updateStatus
  }
}
