import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions()

interface GiroSetupReqInterface {
  firstName: string
  lastName: string
  phone: string
  emailAddress: string
  uid: string
  communityId: string
}

interface GiroSetupResInterface {
  stripeUrl: string
  account_id: string
}

interface StripeSetupCheckReqInterface {
  accountId: string
  communityId: string
}

interface StripeSetupCheckResInteface {
  status: boolean
  description?: string
}

interface RegisterGiroCustomerReqInterface {
  uid: string
  communityId: string
  address_line_one: string
  address_line_two?: string
  city: string
  stateCode: string
  zipCode: string
  card_no: string
  exp_month: string
  exp_year: string
  cvc: string
  phone?: string
  studentOnboarding?: boolean
}

interface AddPaymentMethodReqInterface {
  uid: string
  communityId: string
  card_no: string
  exp_month: string
  exp_year: string
  cvc: string
  phone?: string
  studentOnboarding?: boolean
}

interface RemovePaymentMethodReqInterface {
  uid: string
  communityId: string
  paymentMethodId: string
}

export const setup = async (data) => {
  const setupFunc = httpsCallable<GiroSetupReqInterface, GiroSetupResInterface>(functions, 'giroSetup')

  try {
    const resource = await setupFunc(data)

    return resource
  } catch (err: any) {
    throw new Error(`giro setup err: ${err.message}`)
  }
}

export const checkStripe = async (data) => {
  const checkStripeFunc = httpsCallable<StripeSetupCheckReqInterface, StripeSetupCheckResInteface>(functions, 'stripeSetupCheck')

  try {
    const resource = await checkStripeFunc(data)

    return resource
  } catch (err: any) {
    throw new Error(`check stripe setup err: ${err.message}`)
  }
}

export const registerGiroCustomer = async (data) => {
  const registerGiroCustomerFunc = httpsCallable<RegisterGiroCustomerReqInterface, void>(functions, 'registerGiroCustomer')

  try {
    const resource = await registerGiroCustomerFunc(data)

    return resource
  } catch (err: any) {
    console.log(err)
    debugger
    throw new Error(`add payment method err: ${err.message}`)
  }
}

export const addPaymentMethod = async (data) => {
  const addPaymentMethodFunc = httpsCallable<AddPaymentMethodReqInterface, void>(functions, 'addPaymentMethod')

  try {
    const resource = await addPaymentMethodFunc(data)

    return resource
  } catch (err: any) {
    console.log(err)
    debugger
    throw new Error(`add payment method err: ${err.message}`)
  }
}

export const removePaymentMethod = async (data) => {
  const removePaymentMethodFunc = httpsCallable<RemovePaymentMethodReqInterface, void>(functions, 'removePaymentMethod')

  try {
    const resource = await removePaymentMethodFunc(data)

    return resource
  } catch (err: any) {
    console.log(err)
    debugger
    throw new Error(`remove payment method err: ${err.message}`)
  }
}

export default {
  setup,
  checkStripe,
  registerGiroCustomer,
  addPaymentMethod
}
