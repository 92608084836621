import { v4 } from 'uuid'
import { Ref, ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { cloneDeep } from 'lodash'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  getDoc,
  getDocs,
  doc,
  addDoc,
  collection,
  collectionGroup,
  updateDoc,
  deleteDoc,
  setDoc,
  query,
  where,
  arrayUnion,
  arrayRemove,
  DocumentReference,
  DocumentSnapshot
} from 'firebase/firestore'

import {
  Invitation,
  OnboardingResponse,
  Onboarding,
  OnboardingFlow,
  OnboardingDoc,
  GameBoardCard,
  OnboardingFlowStep,
  Jurni
} from '@/types'
import { TableHeader } from '@/components/data-table/types'
import { useCoreStore } from '@/stores/core'
import { useAlertStore } from '@/stores/alert'
import { useJurniStore } from '@/stores/jurni'
import { useGroupStore } from '@/stores/group'
// eslint-disable-next-line import/no-cycle
import { useFirebaseAuth } from '@/composables/useFirebaseAuth'
import { db } from '@/services/firebase'

interface ResponseData {
  data: any
}

export const useOnboardingStore = defineStore('onboarding', () => {
  const currentStepIndex = ref(0)
  const invitation = ref<Invitation | undefined>(undefined)
  const currentResponse = ref<string | boolean | [] | null>(null)
  const isValid = ref(false)
  const onboardings = ref<Record<string, any>[]>([])
  const currentUserOnboardingId = ref<string>('')
  const userOnboardings = ref<Record<string, Onboarding>>({})
  const onboardingHeaders = ref<Array<TableHeader>>([])

  const iconTypeMap = {
    text: 'text-multiline',
    video: 'video',
    image: 'image',
    'user-input': 'question',
    document: 'sign'
  }

  const currentUserOnboarding = computed(() => {
    if (currentUserOnboardingId.value)
      return userOnboardings.value[currentUserOnboardingId.value]
    return null
  })

  const currentOnboardingFlow = computed(() => {
    console.log('currentOnboardingFlow', currentUserOnboardingId.value, userOnboardings.value, userOnboardings.value[currentUserOnboardingId.value])
    if (currentUserOnboardingId.value && userOnboardings.value[currentUserOnboardingId.value])
      return userOnboardings.value[currentUserOnboardingId.value].onboardingflow
    return null
  })

  const completed = computed(() => currentUserOnboarding.value?.completed)

  const getOnboardings = computed(() => Object.values(userOnboardings.value))

  const steps = computed(() => currentOnboardingFlow.value?.steps || [])

  const currentStep = computed(() => steps.value[currentStepIndex.value])

  const currentStepSavedResponse = computed(() => {
    // eslint-disable-next-line max-len
    const currentResponse = currentUserOnboarding.value?.responses?.find((response) => response.stepIndex === currentStepIndex.value)
    return currentResponse?.response
  })

  const isLastStep = computed(() => currentStepIndex.value === steps.value.length - 1)

  const isFirstStep = computed(() => currentStepIndex.value === 0)

  const progress = computed(() => {
    if (!currentOnboardingFlow.value)
      return 0
    // add one to include set password in progress calc
    return (((currentStepIndex.value + 1) / (currentOnboardingFlow.value.steps.length + 1)) * 100)
  })

  const invitationAccepted = computed(() => !!invitation.value?.timeAccepted)

  const invitationExpired = computed(() => {
    if (!invitation.value?.inviteExpiration?.seconds)
      return false
    const expiration = new Date(invitation.value.inviteExpiration.seconds * 1000)
    const nowDate = new Date()

    const comparison = nowDate.getTime() > expiration.getTime()

    return comparison
  })

  function selectUserOnboarding(jurniId) {
    currentUserOnboardingId.value = jurniId
  }

  async function createFlow(jurniId) {
    const jurniRef = doc(db, 'jurnis', jurniId)
    const newFlow = await addDoc(collection(db, 'onboardingflows'), {
      jurniRef,
      steps: []
    })

    return newFlow
  }

  async function assignFlowToJurni(jurniId, flowId) {
    const jurniDocRef = doc(db, 'jurnis', jurniId)
    await updateDoc(jurniDocRef, { onboardingflow: doc(db, 'onboardingflows', flowId) })
  }

  async function fetchFlow(flowId: string): Promise<OnboardingFlow | null> {
    const flowRef = doc(db, 'onboardingflows', flowId)

    const flowSnap = await getDoc(flowRef)

    if (flowSnap.exists()) {
      const flowData = flowSnap.data() as OnboardingFlow
      flowData.id = flowRef.id

      return flowData
    }
    return null
  }

  async function getOnboardingGameboard(flowId: string) {
    const onboarding = await fetchFlow(flowId)
    if (!onboarding || !onboarding.steps)
      return []

    return onboarding.steps.map((step, index) => {
      const data: GameBoardCard = {
        type: 'onboarding',
        id: index,
        steps: [],
        title: step.header,
        icon: iconTypeMap[step.type]
      }

      // if (step.type === 'image')
      //   data.extraHtml = step.settings.imageUrl ? `<img src='${step.settings.imageUrl}' />` : ''

      // if (step.type === 'video')
      //   data.extraHtml = `<video src='${step.settings.videoUrl}' />`

      return data
    })
  }

  async function loadUserOnboarding(jurniId): Promise<Onboarding | null> {
    const jurniStore = useJurniStore()
    let onboardingData : Record<string, any> = { id: '', responses: [], userEmail: '', onboardingflow: null, coach: null }
    let jurni : Jurni = jurniStore.getJurniById(jurniId)

    if (jurniId !== 'coach') {
      if (!jurni)
        jurni = await jurniStore.loadJurni(jurniId, true)

      if (!jurni)
        return null
    }

    if (!Object.keys(userOnboardings.value).includes(jurniId)) {
      const { currentUser } = storeToRefs(useCoreStore())

      if (currentUser.value) {
        const onboardingRef = doc(collection(db, currentUser.value.userRef.path, 'onboarding'), jurniId)
        let userOnboardingSnap : DocumentSnapshot | null = null

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userOnboardings.value[jurniId] = { id: '', responses: [], userEmail: '', onboardingflow: null, coach: null }
        try {
          userOnboardingSnap = await getDoc(onboardingRef)
        } catch (e) {
          console.log('ONBOARDINGSTORE : loadUserOnboarding', e)
        }

        if (!userOnboardingSnap || !userOnboardingSnap.exists()) {
          let coach : DocumentReference | null = null
          let onboardingflow : DocumentReference | null = null

          if (jurniId !== 'coach') {
            onboardingflow = jurni.onboardingflow
            coach = jurni?.coach
          } else {
            onboardingflow = doc(db, 'onboardingflows', 'coach')
            coach = currentUser.value.userRef
          }

          if (onboardingflow) {
            try {
              onboardingData = {
                onboardingflow,
                responses: [],
                userEmail: currentUser.value.profile?.email,
                coach
              }
              await setDoc(onboardingRef, onboardingData)
              userOnboardingSnap = await getDoc(onboardingRef)
            } catch (e) {
              console.error('Onboarding Responses', e)
            }
          }
        }

        const onboarding = userOnboardingSnap?.data() ?? onboardingData
        if (!onboarding)
          return null

        onboarding.id = onboardingRef.id

        if (onboarding.onboardingflow) {
          const flowSnap = await getDoc(onboarding.onboardingflow)
          onboarding.onboardingflow = flowSnap.data() as OnboardingFlow
          onboarding.onboardingflow.id = flowSnap.id

          if (!onboarding.onboardingflow.steps && onboarding.onboardingflow.id) {
            const flow = await fetchFlow(onboarding.onboardingflow.id)
            debugger
            if (flow)
              onboarding.onboardingflow = flow
          }
        }

        userOnboardings.value[jurniId] = onboarding as Onboarding
        return userOnboardings.value[jurniId]
      }

      return null
    }

    return userOnboardings.value[jurniId]
  }

  function goToStep(index: number) {
    currentStepIndex.value = index
    isValid.value = false
    currentResponse.value = null
    if (currentStepSavedResponse.value)
      currentResponse.value = currentStepSavedResponse.value
  }

  async function updateOnboarding() {
    const { currentUser } = storeToRefs(useCoreStore())

    try {
      if (currentUser.value?.userRef &&
        currentUserOnboarding.value &&
        currentUserOnboardingId.value &&
        currentUserOnboarding.value.onboardingflow.id) {
        const onboardingRef = doc(collection(db, currentUser.value.userRef.path, 'onboarding'), currentUserOnboardingId.value)
        const onboardingflowRef = doc(db, 'onboardingflows', currentUserOnboarding.value?.onboardingflow.id)

        const onboardingClone = cloneDeep(currentUserOnboarding.value) as Record<any, any>
        const onboardingDoc = {
          ...onboardingClone,
          onboardingflow: onboardingflowRef
        } as OnboardingDoc

        return updateDoc(onboardingRef, onboardingDoc).catch((e) => {
          throw new Error(e)
        })
      }
      return null
    } catch (e: any) {
      throw new Error(e)
    }
  }

  async function updateOnboardingFlow(flowId: string, flowData: Record<string, any>) {
    console.log('saving flow', flowId, flowData)
    await setDoc(
      doc(db, 'onboardingflows', flowId),
      flowData,
      { merge: true }
    )
  }

  async function addOnboardingStep(stepType, onboardingId) {
    const newStepConfig = { ...stepType.config }
    if (!['video', 'image'].includes(newStepConfig.type)) {
      const uniqueId = v4()
      newStepConfig.settings.uniqueId = uniqueId
    }

    const newStep : Record<string, any> = { header: '', ...newStepConfig }
    await setDoc(doc(db, 'onboardingflows', onboardingId), { steps: arrayUnion(newStep) }, { merge: true })
  }

  async function removeOnboardingStep(onboardingId, stepIndex) {
    const flow = await fetchFlow(onboardingId) ?? { steps: [] as OnboardingFlowStep[] }
    await setDoc(doc(db, 'onboardingflows', onboardingId), { steps: arrayRemove(flow.steps[stepIndex]) }, { merge: true })
  }

  function validateInput() {
    if (currentStep.value?.settings?.required) {
      const alertStore = useAlertStore()
      if (currentResponse.value &&
        !(Array.isArray(currentResponse.value) && currentResponse.value.length === 0)) {
        isValid.value = true
        alertStore.clearAllAlerts()
      } else {
        let description = ''
        if (currentStep.value?.subtype === 'radio' || currentStep.value?.subtype === 'checkbox')
          description = 'Please select one of the options'

        else if (currentStep.value?.settings?.agreementInputType === 'signature')
          description = 'Please add a signature'
        else if (currentStep.value?.subtype === 'text' || currentStep.value?.subtype === 'textarea')
          description = 'Please provide an answer'
        else
          description = 'Input required'
        alertStore.add({ description, color: 'danger' })
      }
    }
  }

  async function saveResponse() {
    if (currentUserOnboarding.value) {
      if (!currentUserOnboarding.value.responses)
        currentUserOnboarding.value.responses = []

      const prevResponseIdx = currentUserOnboarding.value.responses.findIndex(
        (res) => res.stepIndex === currentStepIndex.value
      )
      if (prevResponseIdx !== -1) {
        currentUserOnboarding.value.responses.splice(prevResponseIdx, 1, {
          stepIndex: currentStepIndex.value,
          response: currentResponse.value,
          stepUniqueId: currentStep.value?.settings?.uniqueId ? currentStep.value?.settings?.uniqueId : ''
        })
      } else {
        currentUserOnboarding.value.responses.push(
          {
            stepIndex: currentStepIndex.value,
            response: currentResponse.value,
            stepUniqueId: currentStep.value?.settings?.uniqueId ? currentStep.value?.settings?.uniqueId : ''
          }
        )
      }
      updateOnboarding()
    }
  }

  async function completeOnboarding() {
    if (currentUserOnboarding.value) {
      currentUserOnboarding.value.completed = true
      await updateOnboarding()
    }
  }

  function next() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (
      (currentStep.value?.type === 'user-input' || currentStep.value?.settings?.agreementInputType) &&
      currentStep.value?.settings?.required
    ) {
      validateInput()
      if (!isValid.value)
        return
    }

    saveResponse().then(async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (isLastStep.value)
        await completeOnboarding()
      else
        goToStep(currentStepIndex.value + 1)
    })
  }

  function previous() {
    if (!isFirstStep.value)
      goToStep(currentStepIndex.value - 1)
  }

  async function loadInvitation(inviteHash) {
    if (!invitation.value) {
      const docRef = doc(db, 'invitations', inviteHash)
      const docSnap = await getDoc(docRef)
      const oldInvitationData = docSnap.data() as Invitation

      // rearchitectured onboarding with coach onboarding in users invitations subcollection
      // NOTE: create single field index for user subcollection invitations (collectionGroup)
      // id field, ascending
      const newInvitationsRef = collectionGroup(db, 'invitations')
      const newInvitationsQuery = query(
        newInvitationsRef,
        where('id', '==', inviteHash)
      )
      const newInvitationSnap = await getDocs(newInvitationsQuery)

      let newInvitationData
      newInvitationSnap.forEach((doc) => {
        if (doc.id === inviteHash)
          newInvitationData = doc.data() as Invitation
      })

      if (oldInvitationData || newInvitationData) {
        invitation.value = newInvitationData ?? oldInvitationData
        return invitation
      }
      const alertStore = useAlertStore()
      alertStore.add({ description: 'Invalid invitation', color: 'danger' }, null)
      return null
    }
    return invitation.value
  }

  async function sendInvite({ studentEmail, coachEmail = 'bucky@toolboxos.com', studentFirst, studentLast, jurniId }) {
    const functions = getFunctions()
    const sendInviteFunc = httpsCallable(functions, 'invitation/send')
    return sendInviteFunc({ studentEmail, coachEmail, studentFirst, studentLast, jurniId })
  }

  async function acceptInvite(hash) {
    const functions = getFunctions()
    const acceptInvite = httpsCallable(functions, 'invitation/accept')
    const response: ResponseData = await acceptInvite({ inviteHash: hash })
    if (response) {
      const token = response.data.token
      // const isNewUser = response.data.isNewUser

      // if (isNewUser)
      //   this.needsPassword = true

      if (token) {
        const { tokenAuth } = useFirebaseAuth()
        const userCredential = await tokenAuth(token)

        if (invitation.value && invitation.value.jurniId)
          await loadUserOnboarding(invitation.value.jurniId)

        if (invitation.value && invitation.value.communityId) {
          const groupStore = useGroupStore()
          groupStore.checkAutoAddToGroup(invitation.value.communityId, invitation.value.studentId)
        }

        return { response, userCredential }
      }
      return { response }
    }
    return null
  }

  async function load(jurniId) {
    if (!userOnboardings.value.jurniId) {
      await loadUserOnboarding(jurniId)
      // if (userOnboardings.value[jurniId].onboardingflow)
      goToStep(currentStepIndex.value)
      // return true
    }
  }

  async function loadOnboardingHeaders(jurniOnboarding) {
    const onboardingflowRef = jurniOnboarding.onboardingflow
    const onboardingFlowSnap = await getDoc(onboardingflowRef)
    const onboardingFlow = onboardingFlowSnap.data() as OnboardingFlow

    const hdrs = onboardingFlow.steps.map((step) => {
      if (!['video', 'image', 'text'].includes(step.type)) {
        return {
          label: step.header,
          key: step.settings ? step.settings.uniqueId || '' : '',
          verticalAlign: 'middle'
        }
      }
      return null
    }).filter((hdr) => hdr !== null)
    hdrs.unshift({ label: 'User Email', key: 'email', verticalAlign: 'middle' })
    onboardingHeaders.value = hdrs as Array<TableHeader>
  }

  async function loadOnboardingResponses(jurniId) {
    const { loadJurni } = useJurniStore()
    const jurniOnboarding = await loadJurni(jurniId)
    if (!jurniOnboarding)
      return

    await loadOnboardingHeaders(jurniOnboarding)
    const onboardingsArray: Record<string, any>[] = []

    try {
      const onboardingsQuery = query(
        collectionGroup(db, 'onboarding'),
        where('onboardingflow', '==', jurniOnboarding.onboardingflow)
      )
      const onboardingsQuerySnapshot = await getDocs(onboardingsQuery)
      onboardingsQuerySnapshot.forEach((doc) => {
        // since onboardingflow can be assigned to another jurni
        if (doc.id === jurniId) {
          const onboarding = doc.data() as OnboardingResponse
          const responses: Record<string, any> = { email: onboarding.userEmail }

          onboarding.responses.forEach((item: any) => {
            if (item.stepUniqueId && item.response)
              responses[item.stepUniqueId] = item.response
          })

          onboardingsArray.push(responses)
        }
      })
    } catch (e) {
      console.log('onboarding response err', e)
    }

    if (onboardingsArray)
      onboardings.value = onboardingsArray
  }

  async function loadAllUserOnboardings() {
    const { currentCommunity } = storeToRefs(useCoreStore())
    const { load: loadJurni } = useJurniStore()
    const { getJurnisByCommunityId, jurnis } = storeToRefs(useJurniStore())
    await loadJurni(ref(currentCommunity.value?.id ?? ''))

    Object.keys(jurnis.value).map(async (jurniId) => {
      await loadUserOnboarding(jurniId)
    })
  }

  async function deleteOnboardingResponse(userEmail, jurniId) {
    const { currentUser } = storeToRefs(useCoreStore())
    const { loadJurni } = useJurniStore()
    const jurniOnboarding = await loadJurni(jurniId)

    if (jurniOnboarding) {
      onboardings.value = onboardings.value.filter((item) => item.email !== userEmail)

      try {
        const onboardingsQuery = query(
          collectionGroup(db, 'onboarding'),
          where('userEmail', '==', userEmail),
          where('onboardingflow', '==', jurniOnboarding.onboardingflow)
        )

        const querySnapshot = await getDocs(onboardingsQuery)
        await Promise.all(querySnapshot.docs.map(async (doc) => {
          await deleteDoc(doc.ref)
        }))
      } catch (e) {
        console.error('Delete onboarding error', e)
      }
    }
  }

  const getOnboardingHeaders = computed(() => onboardingHeaders.value)

  const onboardingResponses = computed(() => onboardings.value)

  return {
    currentUserOnboardingId,
    currentStepIndex,
    invitation,
    currentResponse,
    isValid,
    completed,
    currentStep,
    currentStepSavedResponse,
    isFirstStep,
    isLastStep,
    progress,
    invitationAccepted,
    invitationExpired,
    steps,
    userOnboardings,
    getOnboardings,
    currentUserOnboarding,
    currentOnboardingFlow,
    onboardings,
    onboardingResponses,
    getOnboardingHeaders,
    getOnboardingGameboard,
    addOnboardingStep,
    removeOnboardingStep,
    load,
    selectUserOnboarding,
    // loadFlow,
    // loadFlowByRef,
    fetchFlow,
    createFlow,
    assignFlowToJurni,
    loadUserOnboarding,
    loadAllUserOnboardings,
    loadOnboardingResponses,
    deleteOnboardingResponse,
    next,
    goToStep,
    previous,
    validateInput,
    saveResponse,
    completeOnboarding,
    updateOnboarding,
    updateOnboardingFlow,
    loadInvitation,
    sendInvite,
    acceptInvite,
    iconTypeMap
  }
})
