import { User as AuthUser } from 'firebase/auth'
import {
  arrayRemove,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  getDoc,
  doc,
  DocumentReference,
  arrayUnion,
  limit
} from 'firebase/firestore'
import { deleteObject, ref as storageRef } from 'firebase/storage'
import { defineStore } from 'pinia'
import { computed, ref, Ref, watch, reactive } from 'vue'

import { useUser, UseUser } from '@/composables/useUser'
import { auth, db, storage } from '@/services/firebase'
import { registerGiroCustomer, addPaymentMethod, removePaymentMethod } from '@/services/giro'
import {
  Permission,
  Community,
  User,
  ImpersonateUserReqInterface,
  ImpersonateUserResInteface,
  UserBillingDetails,
  PaymentMethodForm,
  GiroSetupForm,
  Onboarding
} from '@/types'

import { storeToRefs } from 'pinia'
import { useThreadStore } from '@/stores/thread'
import { useContentStore } from '@/stores/content'

import { getFunctions, httpsCallable } from 'firebase/functions'
import { useFirebaseAuth } from '@/composables/useFirebaseAuth'

const functions = getFunctions()

export const useCoreStore = defineStore('core', () => {
  const currentUserAuth: Ref<AuthUser | null> = ref(null)
  const currentUserId: Ref<string | null> = ref(null)
  const users: Ref<Record<string, UseUser>> = ref({})
  const communities: Ref<Record<string, Community>> = ref({})
  const permissions: Ref<Permission[]> = ref([])
  const permissionsLoaded: Ref<boolean> = ref(false)
  const paymentMethodForm = ref<PaymentMethodForm>()
  const giroSetupForm = ref<GiroSetupForm>()
  const watchedCoachWelcomeVideo: Ref<boolean> = ref(false)
  const watchedCoachWelcomeVideoLoaded: Ref<boolean> = ref(false)
  const welcomeVideoData: Ref<Record<string, any>> = ref({})
  const profile = ref<User | null>(null)
  const currentCommunity = ref<Record<string, any> | null>(null)

  const currentUser = computed(() : UseUser | null => {
    if (!currentUserId.value)
      return null

    if (!Object.keys(users.value).includes(currentUserId.value))
      users.value[currentUserId.value] = useUser(currentUserId.value, currentUserAuth.value)
    return users.value[currentUserId.value]
  })

  const userCommunities = computed(() => Object.values(communities.value))

  const getCommunityMembers = computed<Array<UseUser>>(() => {
    const communityMemberIds = currentCommunity.value?.members.map((communityMember) => communityMember.id) ?? []
    const commMembers = Object.keys(users.value).map((memberId) => {
      if (!communityMemberIds.includes(memberId))
        return null
      return users.value[memberId]
    })

    return commMembers.filter((communityMember) => communityMember) as Array<UseUser>
  })

  const getCurrentCommunityMembers = async () => {
    if (!currentCommunity.value?.id)
      return []
    const UserSnapshot = await getDocs(
      query(
        collection(db, 'users'),
        where('communities', 'array-contains', doc(db, `communities/${currentCommunity.value?.id}`))
      )
    )

    const members : User[] = []
    UserSnapshot.docs.map((user) => {
      const data = user.data()
      members.push(data as User)
      return members
    })
    return members
  }

  const getCurrentUserAuth = async (): Promise<AuthUser | null> => new Promise((resolve, reject) => {
    if (auth.currentUser)
      resolve(auth.currentUser)

    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })

  const loadPermissions = async () => {
    if (!currentCommunity.value || !currentCommunity.value.roles)
      return

    const userId = ref(currentUser.value?.id)
    const communityRole = ref(currentUser.value?.role || currentCommunity.value?.roles[userId.value ?? ''] || 'student')
    permissions.value = []

    if (communityRole.value) {
      const permissionsCollection = collection(db, `roles/${communityRole.value}/permissions`)
      const permissionsQuerySnap = await getDocs(permissionsCollection)

      permissionsQuerySnap.forEach((permission) => {
        const actions = permission.data()

        Object.entries(actions).forEach(([key, value]) => {
          if (value) {
            const perm = { action: key, subject: permission.id }
            permissions.value.push(perm)
          }
        })
      })
    }
    permissionsLoaded.value = true
  }

  let currentCommunityResolve

  const currentCommunityPending = ref(true)

  const createCurrentCommunityPromise = () => new Promise<boolean>((resolve) => {
    currentCommunityResolve = resolve
  }).finally(() => { currentCommunityPending.value = false })

  const awaitCurrentCommunity = ref(createCurrentCommunityPromise())

  const loadCurrentUserCommunities = async () => {
    if (!currentUser.value)
      return

    communities.value = {}
    const communitiesQuerySnapshot = await getDocs(
      query(
        collection(db, 'communities'),
        where('members', 'array-contains', currentUser.value?.userRef)
      )
    )

    communitiesQuerySnapshot.docs.forEach(async (doc) => {
      const community = doc.data() as Community
      community.id = doc.id

      if (community.meta?.bannerItem) {
        const contentSnap = await getDoc(community.meta.bannerItem)
        const content = contentSnap.data()
        community.banner = content?.uploadUrl
      } else if (community.meta?.logo) {
        community.banner = community.meta.logo
      }

      communities.value[community.id] = community
    })

    if (currentCommunityPending.value)
      currentCommunityResolve()
  }

  const setMember = async (user: AuthUser | null) => {
    currentUserAuth.value = user
    currentUserId.value = (!user) ? null : user.uid

    if (!user)
      return

    const userRef = doc(db, 'users', user.uid) as DocumentReference<User>
    const userSnap = await getDoc(userRef)
    profile.value = userSnap.data() ?? null

    if (!profile.value)
      return

    if (!profile.value.activeCommunityId) {
      const communitiesQuery = await getDocs(query(
        collection(db, 'communities'),
        where('members', 'array-contains', userRef),
        limit(1)
      ))
      if (!communitiesQuery.empty) {
        const firstDoc = communitiesQuery.docs[0]
        await setDoc(userRef, { activeCommunityId: firstDoc.id }, { merge: true })
        profile.value.activeCommunityId = firstDoc.id
      }
      const userSnap = await getDoc(userRef)
      profile.value = userSnap.data() ?? null
    }

    if (!profile.value)
      return

    const communityRef = doc(db, 'communities', profile.value.activeCommunityId)
    const communitySnap = await getDoc(communityRef)
    currentCommunity.value = { ...communitySnap.data(), id: communitySnap.id }

    await loadCurrentUserCommunities()
    await loadPermissions()
  }

  const removeMember = async (memberId) => {
    if (!currentCommunity.value)
      return

    if (currentCommunity.value && currentCommunity.value.inactiveMembers) {
      currentCommunity.value.inactiveMembers = currentCommunity.value?.inactiveMembers.filter(
        (currCommMemberId) => currCommMemberId !== memberId
      )
    }

    await setDoc(
      doc(db, `communities/${currentCommunity.value?.id}`),
      { inactiveMembers: arrayRemove(doc(db, `users/${memberId}`)) },
      { merge: true }
    )
  }

  const signInAsUser = async (memberId: string) => {
    if (!currentCommunity.value)
      return

    const impersonateUserFunc = httpsCallable<ImpersonateUserReqInterface, ImpersonateUserResInteface>(functions, 'impersonateUser')

    try {
      const response = await impersonateUserFunc({ uid: memberId })

      if (response) {
        const token = response.data.token

        if (token) {
          const { tokenAuth } = useFirebaseAuth()
          await tokenAuth(token)

          return
        }
        return
      }
      return
    } catch (err: any) {
      throw new Error(`${err.message}`)
    }
  }

  const toggleMemberStatus = async (memberId) => {
    if (!currentCommunity.value)
      return

    const activeUser = currentCommunity.value.members.some((mbr) => mbr.id === memberId)

    await setDoc(
      doc(db, `communities/${currentCommunity.value?.id}`),
      {
        members: activeUser ? arrayRemove(doc(db, `users/${memberId}`)) : arrayUnion(doc(db, `users/${memberId}`)),
        inactiveMembers: activeUser ? arrayUnion(memberId) : arrayRemove(memberId)
      },
      { merge: true }
    )

    if (!currentCommunity.value.inactiveMembers)
      currentCommunity.value.inactiveMembers = []

    if (activeUser) {
      currentCommunity.value.members = currentCommunity.value?.members.filter(
        (currCommMember) => currCommMember.id !== memberId
      )

      currentCommunity.value.inactiveMembers = [...currentCommunity.value.inactiveMembers, memberId]
    } else {
      currentCommunity.value.members = [
        ...currentCommunity.value.members,
        doc(db, 'users', memberId) as DocumentReference<User>
      ]

      currentCommunity.value.inactiveMembers = currentCommunity.value.inactiveMembers.filter(
        (mbrId) => mbrId !== memberId
      )
    }
  }

  const loadMember = async (userId: string, userAuth: AuthUser | null = null, forceReload = false) => {
    if (!userId)
      return null
    if (!Object.keys(users.value).includes(userId) || forceReload)
      users.value[userId] = useUser(userId, userAuth)
    return users.value[userId] as UseUser
  }

  const getWelcomeVideoStep = async () => {
    const welcomeVideoRef = doc(db, 'onboardingflows', 'welcome-video')
    const welcomeVideoSnap = await getDoc(welcomeVideoRef)
    if (welcomeVideoSnap.exists())
      welcomeVideoData.value = welcomeVideoSnap.data()
  }

  const finishedCoachWelcomeVideo = async () => {
    if (!currentUser.value)
      return

    const userRef = currentUser.value.userRef
    const coachOnobardingRef = doc(userRef, 'onboarding', 'coach')
    await setDoc(coachOnobardingRef, { watchedWelcomeVideo: true }, { merge: true })
    watchedCoachWelcomeVideo.value = true
  }

  const hasCommunityCoachWatchedWelcomeVideo = async () => {
    await getWelcomeVideoStep()

    if (!currentCommunity.value || !currentCommunity.value.coach || !currentUser.value || !currentUser.value.userRef) {
      watchedCoachWelcomeVideo.value = true
      watchedCoachWelcomeVideoLoaded.value = true
      return
    }

    if (currentUser.value.userRef.id !== currentCommunity.value.coach.id) {
      watchedCoachWelcomeVideo.value = true
      watchedCoachWelcomeVideoLoaded.value = true
      return
    }

    const userRef = currentUser.value.userRef
    const coachOnobardingSnap = await getDoc(doc(userRef, 'onboarding', 'coach'))

    if (!coachOnobardingSnap.exists()) {
      watchedCoachWelcomeVideo.value = false
      watchedCoachWelcomeVideoLoaded.value = true
      return
    }

    const coachOnobarding = coachOnobardingSnap.data() as Onboarding
    watchedCoachWelcomeVideo.value = coachOnobarding.watchedWelcomeVideo ?? false
    watchedCoachWelcomeVideoLoaded.value = true
  }

  const setCommunity = async (communityId: string) => {
    if (!currentUser.value)
      return

    const userRef = currentUser.value?.userRef
    currentUser.value.activeCommunityId = ref(communityId)
    currentUser.value.billingStatus = ref('Inactive')

    const communityRef = doc(db, 'communities', communityId)
    const communitySnap = await getDoc(communityRef)
    currentCommunity.value = { ...communitySnap.data(), id: communitySnap.id }

    await setDoc(
      userRef,
      {
        activeCommunityId: communityId,
        meta: { activeThread: '' }
      },
      { merge: true }
    )

    const userSnap = await getDoc(userRef)
    const profileData = userSnap.data() as User

    currentUser.value.profile.value = profileData
    profile.value = { ...profileData }

    const commSettings = await getDoc(doc(userRef, 'communitySettings', communityId))
    const comSettingsData = commSettings.data()
    currentUser.value.billingStatus = comSettingsData?.billingStatus ? comSettingsData.billingStatus : ref('Inactive')
    currentUser.value.activePaymentMethodId = comSettingsData?.activePaymentMethodId ? comSettingsData?.activePaymentMethodId : ref('')
    currentUser.value.giroCustomerId = comSettingsData?.customerId ? comSettingsData?.customerId : ref('')

    if (comSettingsData) {
      if (comSettingsData.studentBillPayment) {
        Object.keys(comSettingsData.studentBillPayment).forEach((jurniId) => {
          if (currentUser.value)
            currentUser.value.studentBillPayment[jurniId] = comSettingsData.studentBillPayment[jurniId]
        })
      }
      currentUser.value.coachBillPayment = comSettingsData.coachBillPayment ?? ref()

      currentUser.value.paymentSettingChangedExcludeBillingFrom = comSettingsData
        .paymentSettingChangedExcludeBillingFrom ?? ref({})
    } else {
      currentUser.value.studentBillPayment.value = []
      currentUser.value.coachBillPayment.value = []
    }

    if (currentUser.value?.id) {
      // NOTE:x create composite index for customerBillingDetails collection,
      // uid + communityId ascending
      const userBillingDetailsSnap = await getDocs(
        query(
          collection(db, 'customerBillingDetails'),
          where('uid', '==', currentUser.value?.id),
          where('communityId', '==', communityId),
          where('status', '==', 'ACTIVE')
        )
      )
      const billingDetails: UserBillingDetails[] = []
      userBillingDetailsSnap.forEach(async (userBillingDetailsRef) => {
        const { payment_methods } = userBillingDetailsRef.data()

        if (currentUser.value) {
          Object.keys(payment_methods).forEach((payment_id) => {
            billingDetails.push({
              id: payment_id,
              meta: payment_methods[payment_id],
              isActive: comSettingsData?.activePaymentMethodId === payment_id
            })
          })
        }
      })
      currentUser.value.userBillingDetails = ref(billingDetails) ?? ref([])
    }

    const threadStore = useThreadStore()
    const { activeThreadId } = storeToRefs(threadStore)
    activeThreadId.value = null
  }

  const loadBillingStatus = async () => {
    if (!currentCommunity.value || !currentUser.value)
      return

    currentUser.value.billingStatus = ref('Inactive')
    const userRef = currentUser.value?.userRef

    const commSettings = await getDoc(doc(userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()
    currentUser.value.billingStatus = comSettingsData?.billingStatus ? comSettingsData.billingStatus : ref('Inactive')
  }

  const loadBillingExclusion = async () => {
    if (!currentCommunity.value || !currentUser.value)
      return

    currentUser.value.paymentSettingChangedExcludeBillingFrom = ref({})
    const userRef = currentUser.value?.userRef

    const commSettings = await getDoc(doc(userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()
    currentUser.value.paymentSettingChangedExcludeBillingFrom = comSettingsData
      ?.paymentSettingChangedExcludeBillingFrom ?? ref({})
  }

  const loadCurrentUserSharedContent = async () => {
    if (!currentCommunity.value || !currentUser.value)
      return

    currentUser.value.sharedContent = ref([])
    const userRef = currentUser.value?.userRef

    const commSettings = await getDoc(doc(userRef, 'communitySettings', currentCommunity.value?.id))
    const comSettingsData = commSettings.data()
    currentUser.value.sharedContent = comSettingsData
      ?.sharedContent ?? ref([])
  }

  const loadCommunityMembers = async () => {
    if (!currentCommunity.value || !currentUser.value)
      return

    // users.value = { [currentUserId.value ?? '']: currentUser.value }
    await Promise.all(currentCommunity.value.members.map(async (member) => {
      if (Object.keys(users.value).includes(member.id))
        return Promise.resolve(null)

      if (member.id !== currentUser.value?.id.value)
        return loadMember(member.id)

      return Promise.resolve(null)
    }))
  }

  const getMemberById = (id: string) => {
    if (!Object.keys(users.value).includes(id))
      loadMember(id)
    return users.value[id]
  }

  const getMemberIdByAgoraId = async (agoraId: string) => {
    const agoraIdInt = parseInt(agoraId, 10)
    const userSnaps = await getDocs(
      query(
        collection(db, 'users'),
        where('agoraId', '==', agoraIdInt)
      )
    )
    // since we have set agoraId as unique per user
    const userSnap = userSnaps.docs[0]
    return userSnap.id
  }

  const isCommunityActive = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.status)
      return false

    const activeStatuses = ['ACTIVE', 'TRIAL']

    return activeStatuses.includes(currentCommunity.value.status)
  })

  const validateRequiredUserDataForPayment = async () => {
    if (!currentUserId.value)
      throw new Error('User not found')

    const requiredFields = reactive({
      firstName: false,
      lastName: false,
      email: false,
      phone: false
    })
    const err: string[] = []

    Object.entries(requiredFields).forEach(([key, val]) => {
      if (currentUser.value?.profile[key])
        requiredFields[key] = true
      else
        err.push(`${key}`)
    })

    if (err.length > 0) {
      throw new Error(`User missing fields: ${err.join(', ')}.
        Please update in &nbsp;<a href="/settings/account"
        style="text-decoration: underline;">account settings</a>`)
    }
  }

  const storePaymentMethodForm = async (values: PaymentMethodForm) => {
    paymentMethodForm.value = values
  }

  const clearPaymentMethodForm = async () => {
    paymentMethodForm.value = undefined
  }

  const storeGiroSetupForm = async (values: GiroSetupForm) => {
    giroSetupForm.value = values
  }

  const clearGiroSetupForm = async () => {
    giroSetupForm.value = undefined
  }

  const registerCustomer = async (values) => {
    if (!currentUserId.value)
      return

    if (currentUserId.value) {
      await registerGiroCustomer(values)
      await loadMember(currentUserId.value, currentUserAuth.value, true)
      await loadCurrentUserCommunities()
    }
  }

  const addCustomerPaymentMethod = async (values) => {
    if (!currentUserId.value)
      return

    await addPaymentMethod(values)
    await loadMember(currentUserId.value, currentUserAuth.value, true)
    await loadCurrentUserCommunities()
  }

  const updateActivePaymentMethod = async (paymentMethodId: string) => {
    if (!currentUserId.value || !currentCommunity.value?.id)
      return

    const userSettingsRef = doc(db, 'users', currentUserId.value, 'communitySettings', currentCommunity.value.id)
    await setDoc(userSettingsRef, { activePaymentMethodId: paymentMethodId }, { merge: true })

    const userBillingDetailsSnap = await getDocs(
      query(
        collection(db, 'customerBillingDetails'),
        where('uid', '==', currentUser.value?.id),
        where('communityId', '==', currentCommunity.value?.id)
      )
    )

    const customerBillingDetailsRef = userBillingDetailsSnap.docs[0].ref
    await setDoc(customerBillingDetailsRef, { activePaymentMethodId: paymentMethodId }, { merge: true })

    await loadMember(currentUserId.value, currentUserAuth.value, true)
    await loadCurrentUserCommunities()
  }

  const removeCustomerPaymentMethod = async (paymentMethodId: string) => {
    if (!currentUserId.value || !currentCommunity.value?.id)
      return

    const data = {
      uid: currentUserId.value,
      communityId: currentCommunity.value.id,
      paymentMethodId
    }
    await removePaymentMethod(data)
    await loadMember(currentUserId.value, currentUserAuth.value, true)
    await loadCurrentUserCommunities()
  }

  const updateCommunity = async (data: Partial<Community>) : Promise<void> => {
    if (currentCommunity.value) {
      await setDoc(doc(db, `communities/${currentCommunity.value?.id}`), data, { merge: true })

      Object.entries(data).forEach(([key, value]) => {
        if (communities.value && currentCommunity.value?.id && communities.value[currentCommunity.value?.id]) {
          if (key === 'roles') {
            Object.entries(value).forEach(([k, v]) => {
              communities.value[currentCommunity.value!.id][key][k] = v
            })
          } else if (key === 'meta') {
            Object.entries(value).forEach(async ([k, v]) => {
              if (k === 'bannerItem') {
                const contentSnap = await getDoc(v as DocumentReference)
                const content = contentSnap.data()
                communities.value[currentCommunity.value!.id].banner = content?.uploadUrl
              }
            })
          } else {
            communities.value[currentCommunity.value.id][key] = value
          }
        }
      })
    }
  }

  const setCommunityRole = async (userId: string, role: string) => {
    await updateCommunity({ roles: { [userId]: role } })
  }

  const setCommunityLogo = async (file: File) : Promise<DocumentReference> => {
    // const name = v4()
    // const sr = storageRef(storage, `public/${name}`)
    // const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
    // const downloadURL = await getDownloadURL(fileSnap.ref)
    // await updateCommunity({ meta: { logo: downloadURL } })
    const { addContent } = useContentStore()
    const contentRef = await addContent('resource', false, file)

    return contentRef
  }

  const removeCommunityLogo = async () => {
    if (!currentCommunity.value)
      return

    const httpsReference = storageRef(storage, currentCommunity.value?.meta.logo)
    await deleteObject(httpsReference)
    await updateCommunity({ meta: { logo: '' } })
  }

  const updateUser = async (data: Partial<User>) : Promise<void> => {
    if (!currentUser.value)
      return

    Object.keys(data).forEach((key) => {
      if (currentUser.value)
        currentUser.value.profile[key] = data[key]
    })
  }

  const setupCoachGiro = async (stripeUrl: string, account_id: string) => {
    if (!currentUserId.value || !currentCommunity.value)
      throw new Error('[setupCoachGiro] - no user id or community id')

    await updateCommunity({ giro: { stripeUrl, account_id, isStripeSetupComplete: false } })
  }

  const refreshCommunity = async () => {
    if (!currentCommunity.value)
      return

    const communitySnap = await getDoc(doc(db, 'communities', currentCommunity.value?.id))
    const communityData = communitySnap.data() as Community
    const community = communities.value[currentCommunity.value?.id]

    if (!community)
      communities.value[currentCommunity.value?.id] = communityData

    community.name = communityData.name
    community.description = communityData.description
  }

  watch(() => currentCommunity.value, async () => {
    await loadPermissions()
    await hasCommunityCoachWatchedWelcomeVideo()
    await loadBillingStatus()
    await loadCurrentUserSharedContent()
  })

  return {
    getCurrentCommunityMembers,
    getCommunityMembers,
    loadCommunityMembers,
    getMemberById,
    getMemberIdByAgoraId,
    getCurrentUserAuth,
    loadPermissions,
    userCommunities,
    setMember,
    removeMember,
    signInAsUser,
    toggleMemberStatus,
    setCommunity,
    setCommunityRole,
    loadMember,
    loadCurrentUserCommunities,
    currentUser,
    currentUserId,
    currentCommunity,
    awaitCurrentCommunity,
    permissions,
    permissionsLoaded,
    isCommunityActive,
    watchedCoachWelcomeVideo,
    watchedCoachWelcomeVideoLoaded,
    welcomeVideoData,
    finishedCoachWelcomeVideo,
    validateRequiredUserDataForPayment,
    registerCustomer,
    addCustomerPaymentMethod,
    updateActivePaymentMethod,
    removeCustomerPaymentMethod,
    updateCommunity,
    setCommunityLogo,
    removeCommunityLogo,
    updateUser,
    paymentMethodForm,
    storePaymentMethodForm,
    clearPaymentMethodForm,
    users,
    setupCoachGiro,
    storeGiroSetupForm,
    clearGiroSetupForm,
    giroSetupForm,
    loadBillingStatus,
    loadBillingExclusion,
    refreshCommunity
  }
})
