import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from '@/App'
import router from '@/router'
import globals from '@/globals'
import { Vuemq, options } from '@/plugins/media-query'
import { Notification } from '@/plugins/notification'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import RadialProgressBar from 'vue3-radial-progress'
import { createIconsConfig, createVuesticEssential, VaDropdownPlugin } from 'vuestic-ui'
import VueViewer from 'v-viewer'

const pinia = createPinia()

createApp(App)
  .use(globals)
  .use(router)
  .use(pinia)
  .use(Vuemq, options)
  .use(Notification)
  .use(RadialProgressBar)
  .use(OneSignalVuePlugin, {
    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
    welcomeNotification: { disable: true }
  })
  .use(VueViewer)
  .use(createVuesticEssential({
    plugins: { VaDropdownPlugin },
    config: {
      icons: createIconsConfig({
        fonts: [
          {
            name: 'msi-{icon}',
            resolve: ({ icon }) : Record<string, string> => ({
              class: 'material-symbols-outlined',
              content: icon,
              tag: 'span'
            })
          }
        ]
      })
    }
  }))
  .mount('#app')
