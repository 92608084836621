import { useCoreStore } from '@/stores/core'
import { storeToRefs } from 'pinia'
import { usePermissions } from '@/composables/usePermissions'

const routeInactiveCommunity = async (to, from, next) => {
  const { currentCommunity, awaitCurrentCommunity } = storeToRefs(useCoreStore())
  const { can } = usePermissions()

  await awaitCurrentCommunity.value

  if (
    currentCommunity.value?.status === 'TRIAL_EXPIRED' &&
    to.name !== 'account.trial-expired' &&
    to.name !== 'account.unavailable'
  ) {
    if (can('manage', 'community')) {
      if (to.name === 'settings.account')
        next({ name: 'settings.payments' })
      else if (to.name === 'settings.payments')
        next()
      return next({ name: 'account.trial-expired' })
    }

    return next({ name: 'account.unavailable' })
  }

  if (
    currentCommunity.value?.status === 'INACTIVE' &&
    to.name !== 'account.unavailable' &&
    to.name !== 'settings.payments'
  ) {
    if (can('manage', 'community'))
      return next({ name: 'settings.payments' })

    return next({ name: 'account.unavailable' })
  }

  return next()
}

export {
  routeInactiveCommunity
}
