import { QueryConstraint, collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '@/services/firebase'
import { Role } from '@/types'

export const useRole = () => {
  const getRoleList = async () : Promise<Array<Role>> => {
    let roleSnaps
    const roleCollection = collection(db, 'roles')
    const roleConditions : QueryConstraint[] = []
    roleConditions.push(where('type', '!=', 'hide'))

    try {
      roleSnaps = await getDocs(query(roleCollection, ...roleConditions))
    } catch (err) {
      console.log('ROLE ERROR', err)
      return []
    }
    return roleSnaps.docs.map((roleSnap) => roleSnap.data() as Role)
  }

  return { getRoleList }
}
