import { RouterView } from 'vue-router'

import Meet from '@/views/meet/meeting.vue'

import { muteCallNotification } from '@/router/guards'

export default [
  {
    path: '/meet',
    name: 'meet',
    component: RouterView,
    beforeEnter: muteCallNotification,
    meta: {
      requiresAuth: true,
      layout: 'Default'
    },
    children: [
      {
        path: ':room',
        name: 'meet.room',
        props: true,
        component: Meet,
        beforeEnter: muteCallNotification,
        meta: { requiresAuth: true }
      }
    ]
  }
]
