import axios from 'axios'

import { getFunctions, httpsCallable } from 'firebase/functions'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { storeToRefs } from 'pinia'

import { db } from '@/services/firebase'

import { useCoreStore } from '@/stores/core'

import { Jurni, UserCommunitySettings } from '@/types'

import { useTimestamp } from '@/composables/utils/useTimestamp'

const functions = getFunctions()

interface manageSubscriptionReqInterface {
  subscriptionId: string,
  uid: string,
  jurniId: string,
  communityId: string,
  action: 'activate' | 'suspend' | 'cancel'
}

interface manageSubscriptionResInterface {
  status: string
}

const VUE_APP_PAYPAL_URL = process.env.VUE_APP_PAYPAL_URL
const VUE_APP_PAYPAL_CLIENT_ID = process.env.VUE_APP_PAYPAL_CLIENT_ID
const VUE_APP_PAYPAL_CLIENT_SECRET = process.env.VUE_APP_PAYPAL_CLIENT_SECRET

const authGenerator = async () => {
  if (!VUE_APP_PAYPAL_URL || !VUE_APP_PAYPAL_CLIENT_ID || !VUE_APP_PAYPAL_CLIENT_SECRET)
    throw new Error('missing configuration')

  try {
    const auth = await axios({
      url: `${VUE_APP_PAYPAL_URL}/v1/oauth2/token`,
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en_US',
        'content-type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: VUE_APP_PAYPAL_CLIENT_ID,
        password: VUE_APP_PAYPAL_CLIENT_SECRET
      },
      params: {
        grant_type: 'client_credentials'
      }
    })

    return auth
  } catch (error) {
    console.log(`Error in authGenerator: ${error}`)
    throw error
  }
}

const process_billing_cycles = (billing_cycles) => {
  let regular_period_price
  billing_cycles.forEach((cycle) => {
    if (cycle.tenure_type === 'REGULAR')
      regular_period_price = `${cycle.pricing_scheme.fixed_price.currency_code} ${cycle.pricing_scheme.fixed_price.value}`
  })

  return regular_period_price
}

export async function retrievePlanDetails(planId) {
  try {
    // 1. Call PayPal to get a token
    const auth = await authGenerator()

    // 2. Call PayPal to get the plan details
    const response = await axios({
      url: `${VUE_APP_PAYPAL_URL}/v1/billing/plans/${planId}`,
      method: 'get',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${auth.data.access_token}`
      }
    })

    if (response.status !== 200)
      throw new Error(response.data.message)

    if (response.data.status !== 'ACTIVE')
      throw new Error('selected plan is not active')

    const plan_details = {
      name: response.data.name,
      amount: process_billing_cycles(response.data.billing_cycles)
    }

    return plan_details
  } catch (err: any) {
    throw new Error(`Error retrieving plan details: ${err.message}`)
  }
}

export async function retrieveSubscriptionDetails(subscriptionId) {
  try {
    // 1. Call PayPal to get a token
    const auth = await authGenerator()

    // 2. Call PayPal to get the plan details
    const response = await axios({
      url: `${VUE_APP_PAYPAL_URL}/v1/billing/subscriptions/${subscriptionId}`,
      method: 'get',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${auth.data.access_token}`
      }
    })

    if (response.status !== 200)
      throw new Error(response.data.message)

    const lastPaymentTime = response.data.billing_info.last_payment?.time ?? ''
    let lastPaymentDate
    if (lastPaymentTime)
      lastPaymentDate = useTimestamp(Timestamp.fromDate(new Date(lastPaymentTime)))

    const nextBillingTime = response.data.billing_info.next_billing_time ?? ''
    let nextBillingDate
    if (nextBillingTime)
      nextBillingDate = useTimestamp(Timestamp.fromDate(new Date(nextBillingTime)))

    const subscriptionDetails = {
      lastPayment: lastPaymentDate ?? '-',
      nextBilling: nextBillingDate ?? '-'
    }

    return subscriptionDetails
  } catch (err: any) {
    throw new Error(`Error retrieving plan details: ${err.message}`)
  }
}

export const activateSubscription = async (subscriptionId: string, jurniId: string, uid: string) => {
  const { currentCommunity } = storeToRefs(useCoreStore())

  const activateSubscriptionFunc = httpsCallable<manageSubscriptionReqInterface, manageSubscriptionResInterface>(functions, 'managePaypalSubscription')

  const data = {
    subscriptionId,
    uid,
    jurniId,
    communityId: currentCommunity.value?.id ?? '',
    action: 'activate'
  } as manageSubscriptionReqInterface

  try {
    const response = await activateSubscriptionFunc(data)
    return response.data
  } catch (err: any) {
    throw new Error(`error activating subscription: ${err.message}`)
  }
}

export const suspendSubscription = async (subscriptionId: string, jurniId: string, uid: string) => {
  const { currentCommunity } = storeToRefs(useCoreStore())

  if (!currentCommunity.value?.id)
    throw new Error('missing community id')

  const suspendSubscriptionFunc = httpsCallable<manageSubscriptionReqInterface, manageSubscriptionResInterface>(functions, 'managePaypalSubscription')

  const data = {
    subscriptionId,
    uid,
    jurniId,
    communityId: currentCommunity.value?.id,
    action: 'suspend'
  } as manageSubscriptionReqInterface

  try {
    const response = await suspendSubscriptionFunc(data)
    return response.data
  } catch (err: any) {
    throw new Error(`error activating subscription: ${err.message}`)
  }
}

export const cancelSubscription = async (subscriptionId: string, jurniId: string, uid: string) => {
  const { currentCommunity } = storeToRefs(useCoreStore())
  const cancelSubscriptionFunc = httpsCallable<manageSubscriptionReqInterface, manageSubscriptionResInterface>(functions, 'managePaypalSubscription')

  const data = {
    subscriptionId,
    uid,
    jurniId,
    communityId: currentCommunity.value?.id ?? '',
    action: 'cancel'
  } as manageSubscriptionReqInterface

  try {
    const response = await cancelSubscriptionFunc(data)
    return response.data
  } catch (err: any) {
    throw new Error(`error activating subscription: ${err.message}`)
  }
}

export const subscribeToPlan = async (actions: any, planId: string) => {
  const resp = actions.subscription.create({
    plan_id: planId
  })
  // TODO:x
  // account payment settings, activate/cancel/suspend subscription per jurni
  //

  console.log(resp)

  return resp
}

export const logSubscription = async (jurniId: string, subscriptionId: string, status: string) => {
  // save user.communitySettings[communityId].subscriptions[jurniId] = { subscriptionId, status }
  // so we can check subscription status
  const { currentCommunity, currentUserId } = storeToRefs(useCoreStore())

  if (!currentUserId.value || !currentCommunity.value?.id)
    return

  const subscriptions: Partial<UserCommunitySettings> = {
    [jurniId]: {
      subscriptionId,
      status
    }
  }

  const userSettingsRef = doc(db, 'users', currentUserId.value, 'communitySettings', currentCommunity.value.id)
  await setDoc(
    userSettingsRef,
    {
      subscriptions
    },
    {
      merge: true
    }
  )
}

export const updateCoursePaypalPlanSettings = async (jurniId: string, data: Partial<Jurni>) => {
  if (!jurniId)
    return

  const jurniRef = doc(db, 'jurnis', jurniId)
  await setDoc(
    jurniRef,
    { paypal: data },
    { merge: true }
  )
}

export default {
  activateSubscription,
  suspendSubscription,
  cancelSubscription,
  subscribeToPlan,
  logSubscription,
  updateCoursePaypalPlanSettings,
  retrievePlanDetails
}
