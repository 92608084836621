import { defineStore, storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useCoreStore } from '@/stores/core'
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs, limit,
  query,
  setDoc,
  Timestamp,
  where
} from 'firebase/firestore'
import { db } from '@/services/firebase'
import { Campaign, CampaignTrigger } from '@/types/campaign'

const durationToSeconds = (value : number, interval: string) => {
  const formulas = {
    minutes: 60, hours: 3600, days: 86400, weeks: 604800
  }
  return formulas[interval] * value
}

export const useCampaignStore = defineStore('campaign', () => {
  const { currentCommunity } = storeToRefs(useCoreStore())
  const campaigns = ref<Array<Campaign>>([])

  const triggerTypes = ref([{ label: 'Fixed Time', id: 'time' }, { label: 'Action', id: 'action' }])
  const messageTypes = ref([{ label: 'Email', id: 'email' }, { label: 'SMS', id: 'sms' }, { label: 'Chat', id: 'chat' }])
  const delayTypes = ref([{ label: 'Fixed Time', id: 'time' }, { label: 'Duration', id: 'duration' }])

  const groupingOptions = ref([{ id: 'individual', label: 'Individual' }, { id: 'group', label: 'Group' }])
  const durationOptions = ref([
    { label: 'Minutes', id: 'minutes' },
    { label: 'Hours', id: 'hours' },
    { label: 'Days', id: 'days' },
    { label: 'Weeks', id: 'weeks' }
  ])
  const timeframeOptions = ref([
    { label: 'Before', id: 'before' },
    { label: 'After', id: 'after' }
  ])

  const actionOptions = ref([
    { label: 'Event', id: 'events' },
    { label: 'Billing Date', id: 'billingdate' },
    { label: 'Joining Group', id: 'joininggroup' },
    { label: 'Starting Jurni', id: 'startingjurni' },
    { label: 'Finishing Jurni', id: 'finishingjurni' },
    { label: 'Removed From Group', id: 'removedgroup' },
    { label: 'Removed From Jurni', id: 'removedjurni' },
    { label: 'Birthday', id: 'birthday' },
    { label: 'Missed Payment', id: 'missedpayment' }
  ])

  const repeatOptions = ref([
    { label: 'Daily', id: 'daily' },
    { label: 'Weekly', id: 'weekly' },
    { label: 'Weekdays', id: 'weekday' }
  ])

  const dayOptions = ref([
    { label: 'Monday', id: 'monday' },
    { label: 'Tuesday', id: 'tuesday' },
    { label: 'Wednesday', id: 'wednesday' },
    { label: 'Thursday', id: 'thursday' },
    { label: 'Friday', id: 'friday' },
    { label: 'Saturday', id: 'saturday' },
    { label: 'Sunday', id: 'sunday' }
  ])

  const loadCampaigns = async () => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return

    const campaignsSnaps = await getDocs(query(
      collection(db, 'campaigns'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id))
    ))

    campaigns.value = await Promise.all(campaignsSnaps.docs.map(
      async (campaignSnap) => {
        const tmpCampaign = campaignSnap.data() as Campaign

        const triggerSnaps = await getDocs(
          query(collection(db, campaignSnap.ref.path, 'triggers'), limit(200))
        )

        triggerSnaps.forEach((triggerSnap) => {
          if (!tmpCampaign.triggers)
            tmpCampaign.triggers = []
          tmpCampaign.triggers.push({ ...triggerSnap.data(), id: triggerSnap.id } as CampaignTrigger)
        })

        if (tmpCampaign.imageContentRef) {
          const imageSnap = await getDoc(tmpCampaign.imageContentRef)
          const imageData = imageSnap.data()
          if (imageData)
            tmpCampaign.imageUrl = imageData.uploadUrl ?? ''
        }

        return tmpCampaign
      }
    ))
  }

  const saveTrigger = async (campaignRef, card, index) => {
    const tmpStep = ref<Record<string, any>>({ type: card.type, subtype: card.subtype })
    tmpStep.value.triggerStep = index

    if (card.subtype === 'action') {
      tmpStep.value = {
        ...tmpStep.value,
        durationValue: card.durationValue,
        durationInterval: card.durationInterval,
        duration: durationToSeconds(+card.durationValue, card.durationInterval),
        actionTime: card.timeframe,
        event: card.event,
        eventRef: card.eventRef
      }
    } else if (card.subtype === 'time') {
      tmpStep.value.completed = tmpStep.value.completed ?? false
      tmpStep.value = {
        ...tmpStep.value,
        date: Timestamp.fromDate(new Date(card.date)),
        audience: card.audience ?? []
      }

      if (card.recurring) {
        tmpStep.value = {
          ...tmpStep.value,
          recurring: card.recurring,
          recurringInterval: card.recurringInterval,
          recurringDay: card.recurringInterval === 'weekly' ? card.recurringDay : ''
        }
      }
    }

    if (!card.id)
      await setDoc(doc(collection(db, campaignRef.value!.path, 'triggers')), tmpStep.value)
    else
      await setDoc(doc(collection(db, campaignRef.value!.path, 'triggers'), card.id), tmpStep.value)
  }

  const convertStep = (card) => {
    const tmpStep = ref<Record<string, any>>({ type: card.type, subtype: card.subtype })
    tmpStep.value.methods = card.type === 'message' ? card.subtype : []
    tmpStep.value.content = card.message ?? ''
    tmpStep.value.grouping = card.grouping ?? 'individual'
    tmpStep.value.delay = {}

    if (card.subtype === 'duration') {
      tmpStep.value.delay = {
        interval: card.durationInterval,
        value: card.durationValue,
        duration: durationToSeconds(+card.durationValue, card.durationInterval)
      }
    } else if (card.subtype === 'time') {
      tmpStep.value.delay = {
        date: Timestamp.fromDate(new Date(card.date))
      }
    }

    return tmpStep.value
  }

  const saveCampaign = async (campaignId: string, campaign, campaignCards: Array<Record<string, any>>) => {
    if (!currentCommunity.value)
      return

    const campaignRef = ref<DocumentReference | null>(null)
    if (!campaignId)
      campaignRef.value = doc(collection(db, 'campaigns'))
    else
      campaignRef.value = doc(db, 'campaigns', campaignId)

    const campaignData : Record<string, any> = {
      id: campaignRef.value.id,
      community: doc(db, 'communities', currentCommunity.value?.id),
      name: campaign.name ?? '',
      imageContentRef: campaign.imageContentRef ?? null,
      baseTemplate: campaign.baseTemplate ?? '',
      steps: []
    }

    await Promise.all(campaignCards.map(async (card, index) => {
      if (Array.isArray(card)) {
        await Promise.all(card.map(async (stackCard, index) => {
          if (stackCard.type === 'trigger') {
            await saveTrigger(campaignRef, stackCard, index)
          } else {
            const newStep = convertStep(stackCard)
            campaignData.steps.push(newStep)
          }
        }))
      } else if (card.type === 'trigger') {
        await saveTrigger(campaignRef, card, index)
      } else {
        const newStep = convertStep(card)
        campaignData.steps.push(newStep)
      }

      return card
    }))

    await setDoc(campaignRef.value, campaignData)
  }

  const getCampaignById = async (id: string) => {
    if (!campaigns.value || campaigns.value.length < 1)
      await loadCampaigns()
    return campaigns.value.find((campaign) => campaign.id === id)
  }

  watch(() => currentCommunity.value, async () => {
    campaigns.value = []
    await loadCampaigns()
  })

  return {
    campaigns,
    getCampaignById,
    loadCampaigns,
    saveCampaign,
    triggerTypes,
    messageTypes,
    delayTypes,
    groupingOptions,
    durationOptions,
    timeframeOptions,
    actionOptions,
    repeatOptions,
    dayOptions
  }
})
