import { Timestamp } from 'firebase/firestore'
import { storeToRefs } from 'pinia'
import { useJurniStore } from '@/stores/jurni'
import { useCoreStore } from '@/stores/core'
import { computed, ref } from 'vue'

import { useTimestamp } from '@/composables/utils/useTimestamp'

import { retrievePlanDetails, retrieveSubscriptionDetails } from '@/services/paypal'

export const useBilling = () => {
  const isStudent = (student) => {
    const { currentCommunity } = storeToRefs(useCoreStore())

    return currentCommunity.value?.coach.id !== student.id &&
      !['admin', 'coach', 'coachadmin', 'coachmoderator', 'superadmin'].includes(currentCommunity.value?.roles[student.id])
  }
  const isBillable = (student) => isStudent(student) && student.isActiveLast30Days

  const calculateCoachCost = () => {
    const { getCommunityMembers: communityStudents, currentCommunity } = storeToRefs(useCoreStore())
    const billableStudents = ref(new Set())
    const nonBillableStudents = ref(new Set())
    const date = new Date()

    communityStudents.value.map((student) => {
      if (!isStudent(student))
        return null

      if (!billableStudents.value.has(student) && isBillable(student))
        billableStudents.value.add(student)
      else if (!nonBillableStudents.value.has(student) && !isBillable(student))
        nonBillableStudents.value.add(student)
      return null
    })

    return {
      name: currentCommunity.value?.name ?? '',
      students: Array.from(billableStudents.value).length,
      freeStudents: Array.from(nonBillableStudents.value).length,
      cost: Array.from(billableStudents.value).length * 5,
      billingDate: useTimestamp(Timestamp.fromDate(new Date(date.getFullYear(), date.getMonth() + 1, 1)))
    }
  }

  const coachLatestPayment = () => {
    const { currentCommunity, currentUser } = storeToRefs(useCoreStore())

    const coachBillsPayment: Array<Record<string, any>> = []
    const currentCoachBillPayment = currentUser.value?.coachBillPayment ?? []
    let latestCoachBillingDate = 'Subscribed '
    let latestCoachBillingCost = 0

    if (currentCoachBillPayment.length > 0) {
      // eslint-disable-next-line max-len
      const latestCoachBilling = currentCoachBillPayment.reduce((a, b) => (useTimestamp(a.billingDate) > useTimestamp(b.billingDate) ? a : b))
      latestCoachBillingCost = latestCoachBilling.cost
      latestCoachBillingDate = useTimestamp(latestCoachBilling.billingDate)

      coachBillsPayment.push({
        name: currentCommunity.value?.name ?? '',
        coachPaymentCost: latestCoachBillingCost ?? 0,
        transactionDate: latestCoachBillingDate ?? '-'
      })
    }

    return coachBillsPayment
  }

  const coachPayments = () => {
    const { currentCommunity, currentUser } = storeToRefs(useCoreStore())

    const payments: Array<Record<string, any>> = []
    const currentCoachBillPayment = currentUser.value?.coachBillPayment ?? []
    const coachCommunityCost = ref(calculateCoachCost())

    if (currentCoachBillPayment.length === 0)
      return payments

    currentCoachBillPayment.forEach((item) => {
      payments.push(
        {
          name: currentCommunity.value?.name ?? '',
          charged: item.cost / 5,
          cost: item.cost,
          date: useTimestamp(item.billingDate)
        }
      )
    })

    return payments
  }

  const studentCommunityJurnis = () => {
    const { getCurrentUserJurnis } = storeToRefs(useJurniStore())
    const { currentCommunity } = storeToRefs(useCoreStore())

    return getCurrentUserJurnis.value.filter((jurni) => jurni.community.id === currentCommunity.value?.id)
  }

  const studentLatestJurnisPayments = () => {
    const { currentUser } = storeToRefs(useCoreStore())
    const myJurnis: Array<Record<string, any>> = []

    // if student
    if (isStudent(currentUser.value)) {
      studentCommunityJurnis().map((jurni) => {
        const currentJurniStudentBillables = currentUser.value?.studentBillPayment[jurni.id ?? ''] ?? []
        const latestJurniBillingDate = ref('-')
        const latestJurniBillingCost = ref('-')
        const jurniCost = ref('-')
        const recurStatus = ref('')
        const date = new Date()

        if (!jurni.meta?.billingProvider || jurni.meta?.billingProvider === 'Free') {
          jurniCost.value = '0'
          recurStatus.value = 'Free'
        } else if (jurni.meta?.billingProvider === 'Through External Service') {
          jurniCost.value = '-'
          recurStatus.value = 'Offline Subscription'
        } else if (jurni.meta?.billingType === 'Upfront') {
          jurniCost.value = `${jurni.meta?.cost}`
          recurStatus.value = 'One-Time'
        } else if (jurni.meta?.billingType === 'Recurring') {
          jurniCost.value = `${jurni.meta?.cost}`
          recurStatus.value = 'Monthly Recurring'
        } else {
          jurniCost.value = '-'
          recurStatus.value = 'Free'
        }

        if (currentJurniStudentBillables.length > 0) {
          // eslint-disable-next-line max-len
          const latestJurniBilling = currentJurniStudentBillables.reduce(
            (a, b) => (useTimestamp(a.billingDate) > useTimestamp(b.billingDate) ? a : b)
          )
          if (jurni.meta?.billingType === 'Recurring')
            latestJurniBillingDate.value = 'Subscribed '
          else if (jurni.meta?.billingType === 'Upfront')
            latestJurniBillingDate.value = 'Paid '

          latestJurniBillingDate.value += useTimestamp(latestJurniBilling.billingDate)
          latestJurniBillingCost.value = latestJurniBilling.cost
        }

        const studentPaymentCost = computed(() => {
          if (jurni.meta?.billingProvider === 'Through Jurni' && recurStatus.value !== 'Free')
            return latestJurniBillingCost.value ?? 5
          return jurniCost.value
        })

        const transactionDate = computed(() => {
          if (jurni.meta?.billingProvider === 'Through Jurni' && latestJurniBillingDate.value)
            return latestJurniBillingDate.value
          return useTimestamp(Timestamp.fromDate(new Date(date.getFullYear(), date.getMonth(), 1)))
        })

        myJurnis.push({
          id: jurni.id,
          name: jurni.name,
          recurringStatus: recurStatus.value,
          studentPaymentCost: studentPaymentCost.value,
          transactionDate: transactionDate.value
        })
        return null
      })
    }
    return myJurnis
  }

  const calculateStudentMonlthlyCost = () => {
    const billables: Array<Record<string, any>> = []
    const totalCost = ref(0)
    const date = new Date()
    const studentPayments = studentLatestJurnisPayments()

    studentCommunityJurnis().map((jurni) => {
      const studentJurniPayment = studentPayments.find((item) => item.id === jurni.id)
      if (!jurni.meta?.cost)
        return null

      if (
        jurni.meta?.billingType === 'Recurring' ||
        (jurni.meta?.billingType === 'Upfront' && studentJurniPayment?.transactionDate === '-')
      ) {
        billables.push({
          name: jurni.name,
          cost: jurni.meta.cost,
          billingDate: jurni.meta?.billingType === 'Recurring' ? useTimestamp(Timestamp.fromDate(new Date(date.getFullYear(), date.getMonth() + 1, 1))) : 'Upfront'
        })
        totalCost.value += parseFloat(jurni.meta.cost.toString())
      }

      return null
    })

    if (billables.length > 0)
      billables.push({ name: 'Total', cost: totalCost.value })
    return billables
  }

  const studentSubscriptionPlans = async () => {
    const { currentUser } = storeToRefs(useCoreStore())
    const { getUserJurniSubscriptionId, getUserJurniSubscriptionStatus } = useJurniStore()
    const myCoursePlans: Array<Record<string, any>> = []

    if (isStudent(currentUser.value)) {
      await Promise.all(studentCommunityJurnis().map(async (jurni) => {
        const jurniCost = ref('-')
        const recurStatus = ref('')
        const planName = ref('-')
        const lastPaymentDate = ref('-')
        const subscriptionStatus = ref('Subscription not set up')

        if (!jurni.paypal?.planId && jurni.paypal?.isFree) {
          jurniCost.value = '0'
          recurStatus.value = 'Free'
        } else {
          const planDetails = await retrievePlanDetails(jurni.paypal?.planId)
          jurniCost.value = planDetails.amount
          planName.value = planDetails.name
          recurStatus.value = 'Recurring'

          if (jurni.id && currentUser.value?.id) {
            const userSubscriptionId = getUserJurniSubscriptionId(jurni.id, currentUser.value?.id)
            if (userSubscriptionId) {
              lastPaymentDate.value = (await retrieveSubscriptionDetails(userSubscriptionId)).lastPayment
              subscriptionStatus.value = getUserJurniSubscriptionStatus(jurni.id, currentUser.value?.id)
            }
          }
        }

        myCoursePlans.push({
          id: jurni.id,
          name: jurni.name,
          planName: planName.value,
          recurringStatus: recurStatus.value,
          studentPaymentCost: jurniCost.value,
          lastPaymentDate: lastPaymentDate.value,
          subscriptionStatus: subscriptionStatus.value
        })
      }))
    }

    return myCoursePlans
  }

  const studentUpcomingPayments = async () => {
    const { currentUser } = storeToRefs(useCoreStore())
    const { getUserJurniSubscriptionId } = useJurniStore()

    const billables: Array<Record<string, any>> = []
    const totalCost = ref(0)

    await Promise.all(studentCommunityJurnis().map(async (jurni) => {
      const planName = ref('-')
      const nextBilling = ref('')

      if (jurni.paypal?.planId) {
        if (jurni.id && currentUser.value?.id) {
          const userSubscriptionId = getUserJurniSubscriptionId(jurni.id, currentUser.value?.id)
          if (userSubscriptionId)
            nextBilling.value = (await retrieveSubscriptionDetails(userSubscriptionId)).nextBilling
        }

        // no next billing date if subscription is canceled
        if (nextBilling.value) {
          const planDetails = await retrievePlanDetails(jurni.paypal?.planId)
          totalCost.value += parseFloat(planDetails.amount.split(' ')[1])
          planName.value = planDetails.name
          billables.push({
            name: jurni.name,
            planName: planName.value,
            cost: planDetails.amount,
            nextBilling: nextBilling.value
          })
        }
      }
    }))

    if (billables.length > 0)
      billables.push({ name: 'Total', cost: totalCost.value })
    return billables
  }

  return {
    calculateCoachCost,
    calculateStudentMonlthlyCost,
    studentLatestJurnisPayments,
    coachLatestPayment,
    coachPayments,
    studentSubscriptionPlans,
    studentUpcomingPayments
  }
}
