import { defineStore, storeToRefs } from 'pinia'
import { ref, watch, computed } from 'vue'
import { Community, ContentLibraryItem } from '@/types/global'
import { ContentLibraryAttachment } from '@/types'
import { useCoreStore } from '@/stores/core'
import { useJurniStore } from '@/stores/jurni'
import {
  collection,
  doc,
  DocumentReference,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
  deleteDoc
} from 'firebase/firestore'
import { db, storage } from '@/services/firebase'
import { getDownloadURL, ref as storageRef, uploadBytes, getMetadata, deleteObject } from 'firebase/storage'
import { v4 } from 'uuid'

export const useContentStore = defineStore('content', () => {
  const { currentCommunity, currentUser } = storeToRefs(useCoreStore())
  const { getArchivedJurnis, getActiveJurnis, userJurniProgress } = storeToRefs(useJurniStore())
  const { loadUserJurniProgress } = useJurniStore()
  const contentItems = ref<Array<ContentLibraryItem>>([])

  const modalUploadType = ref<'eduvideo' | 'resource' | 'chat' | 'homework' | 'recording'|'other'>('resource')
  const modalMode = ref<'single'|'multiple'>('single')
  const modalOpen = ref(false)
  const modalIsFeatured = ref(false)
  const modalUploadOptions = ref({})
  const modalFileUploaded = ref<(contentRef: DocumentReference) => string>(() => '')
  const showContentModal = async (
    uploadType: 'eduvideo' | 'resource' | 'chat' | 'homework' | 'recording' | 'other',
    fileUploaded: (contentRef: DocumentReference) => any,
    mode: 'single'|'multiple',
    isFeatured: boolean,
    uploadOptions?: Record<string, string>
  ) : Promise<void> => {
    modalFileUploaded.value = fileUploaded
    modalUploadType.value = uploadType
    modalOpen.value = true
    modalMode.value = mode
    modalIsFeatured.value = isFeatured
    modalUploadOptions.value = uploadOptions ?? {}
  }

  const getResources = (resourceList?: Array<DocumentReference>) : Array<ContentLibraryItem> => {
    if (!contentItems.value || !contentItems.value.length)
      return [] as Array<ContentLibraryItem>

    const res = ref<Array<ContentLibraryItem>>([])
    const itemsToFind = ref<Array<string>>([])
    if (resourceList && resourceList.length)
      resourceList.forEach((resourceRef) => itemsToFind.value.push(resourceRef.id))

    contentItems.value.forEach((contentItem) => {
      if (Array.isArray(resourceList)) {
        if (itemsToFind.value.includes(contentItem.id))
          res.value.push(contentItem)
      } else if (contentItem.type === 'resource') { res.value.push(contentItem) }
    })
    return res.value
  }

  const getContentType = async (item: ContentLibraryItem) => {
    const contentItemStorageRef = storageRef(storage, item.uploadPath)

    let contentItemMetaData
    try {
      contentItemMetaData = await getMetadata(contentItemStorageRef)
    } catch (err) {
      // group remove banner deleted files for related content items
      // breaking loading of content items
      console.log('[content library] file does not exist')
      return null
    }

    if (contentItemMetaData?.contentType?.includes('image'))
      return 'image'

    // video recordings from agora is saved as binary/octet-stream in storage
    if (contentItemMetaData?.contentType?.includes('video') || contentItemMetaData?.contentType?.includes('binary/octet-stream'))
      return 'video'

    return 'file'
  }

  const getContentMime = async (item: ContentLibraryItem) => {
    const contentItemStorageRef = storageRef(storage, item.uploadPath)

    let contentItemMetaData
    try {
      contentItemMetaData = await getMetadata(contentItemStorageRef)
    } catch (err) {
      console.log('[content library] file does not exist')
      return null
    }

    return contentItemMetaData?.contentType
  }

  const getThumbnail = (contentItem: ContentLibraryItem) => {
    if (contentItem?.thumbnailUrl)
      return contentItem.thumbnailUrl

    if (contentItem?.thumbnailPath) {
      const thumbStorageRef = storageRef(storage, contentItem.thumbnailPath)
      return getDownloadURL(thumbStorageRef)
    }

    if (contentItem?.contentType) {
      if (contentItem.contentType.includes('image') || contentItem.contentType.includes('video'))
        return contentItem.uploadUrl
    }
    return null
  }

  const getLockedStatus = async (contentItem: ContentLibraryItem) => {
    if (contentItem.type === 'recording')
      return false

    if (!contentItem.attachedTo)
      return false

    const attachedTo = contentItem.attachedTo as ContentLibraryAttachment
    const attachedToJurniIds = Object.keys(attachedTo)

    if (!attachedToJurniIds.length)
      return false

    const userActiveJurnies = getActiveJurnis

    const activeJurniIds = computed(() => {
      if (!userActiveJurnies.value)
        return []

      return userActiveJurnies.value.map((jurni) => jurni.id)
    })

    const isLocked = ref(true)
    await Promise.all(Object.entries(attachedTo).map(async ([jurniId, stepRefs]) => {
      if (!isLocked.value)
        return null

      if (!activeJurniIds.value.includes(jurniId)) {
        isLocked.value = false
        return null
      }

      if (userActiveJurnies.value) {
        const jurni = userActiveJurnies.value.find((jurni) => jurni.id === jurniId)

        if (!jurni)
          return null

        if (!jurni.meta?.isLockable) {
          isLocked.value = false
          return null
        }
      }

      if (stepRefs.length === 0) {
        isLocked.value = false
        return null
      }

      if (!userJurniProgress.value || !userJurniProgress.value[jurniId])
        await loadUserJurniProgress(jurniId)

      if (!userJurniProgress.value || !userJurniProgress.value[jurniId])
        return null

      stepRefs.map((stepRef) : null => {
        const stepProgress = userJurniProgress.value[jurniId][stepRef.id]
        if (!stepProgress)
          return null

        if (stepProgress.completed > 0)
          isLocked.value = false

        return null
      })

      return null
    }))
    return isLocked.value
  }

  const getFeaturedContent = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return []

    return contentItems.value.filter((content) => content.isFeatured)
  })

  const getEducationalVideoContent = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return []

    return contentItems.value.filter((content) => content.type === 'eduvideo')
  })

  const getResourceContent = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return []

    return contentItems.value.filter((content) => content.type === 'resource')
  })

  const getVideoRecordingContent = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.id || !currentUser.value || !currentUser.value.id)
      return []

    // show only to user who recorded video and users shared with
    return contentItems.value.filter((content) => content && content.type === 'recording' &&
      (content!.author!.id === currentUser!.value!.id ||
      currentUser!.value!.sharedContent!.map((contentRef) => contentRef.id).includes(content.id)))
  })

  const getHomeworkContent = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return []

    return contentItems.value.filter((content) => content.type === 'homework')
  })

  const loadContent = async () => {
    if (!currentCommunity.value || !currentCommunity.value.id)
      return

    const contentSnaps = await getDocs(query(
      collection(db, 'content'),
      where('community', '==', doc(db, 'communities', currentCommunity.value.id))
    ))

    const _contentItems: ContentLibraryItem[] = []

    await Promise.all(contentSnaps.docs.map(async (contentSnap) => {
      const contentItem = contentSnap.data() as ContentLibraryItem
      const _contentType = await getContentType(contentItem)

      if (!_contentType)
        return null

      contentItem.contentType = _contentType
      contentItem.mimeType = await getContentMime(contentItem)
      contentItem.isLocked = await getLockedStatus(contentItem)

      _contentItems.push(contentItem)
      return null
    }))
    // debugger
    contentItems.value = _contentItems
  }

  const getUnsharedVideoRecordingContent = computed(() => {
    if (!currentCommunity.value || !currentCommunity.value.id || !currentUser.value || !currentUser.value.id)
      return []

    return contentItems.value.filter((content) => content && content.type === 'recording' &&
      content!.author!.id === currentUser!.value!.id && !content.sharedWith)
  })

  const addContent = async (
    contentType: 'eduvideo' | 'resource' | 'chat' | 'homework' | 'recording' | 'other',
    isFeatured: boolean,
    uploadData: File
  ) : Promise<DocumentReference<ContentLibraryItem>> => {
    if (!currentCommunity.value || !currentUser.value || !uploadData)
      throw Error('Invalid upload request')

    const name = v4()
    const uploadRef = storageRef(storage, `content/${currentCommunity.value.id}/${name}`)
    const fileSnap = await uploadBytes(
      uploadRef,
      uploadData,
      { contentType: uploadData.type, customMetadata: { originalFileName: uploadData.name } }
    )
    const downloadURL = await getDownloadURL(fileSnap.ref)
    const newContentDoc = doc(collection(db, 'content')) as DocumentReference<ContentLibraryItem>
    const contentItem: ContentLibraryItem = {
      community: doc(db, 'communities', currentCommunity.value.id) as DocumentReference<Community>,
      id: newContentDoc.id,
      name: uploadData.name,
      author: currentUser.value.userRef,
      uploadDate: Timestamp.now(),
      uploadPath: uploadRef.fullPath,
      uploadUrl: downloadURL,
      mimeType: uploadData.type || 'application/octet-stream',
      type: contentType,
      isFeatured: isFeatured || false,
      attachedTo: {}
    }
    await setDoc(newContentDoc, contentItem)
    contentItems.value.push(contentItem)
    return newContentDoc
  }

  const addToFeatured = async (contentRefs: Array<DocumentReference<ContentLibraryItem>>) => {
    await Promise.all(contentRefs.map(async (contentRef) => {
      await setDoc(contentRef, { isFeatured: true }, { merge: true })
    }))
  }

  const removeFromFeatured = async (contentId: string) => {
    const contentRef = doc(db, 'content', contentId)
    await setDoc(contentRef, { isFeatured: false }, { merge: true })
  }

  const updateContentItem = async (item: ContentLibraryItem, data: Partial<ContentLibraryItem>) : Promise<void> => {
    await setDoc(doc(db, `content/${item.id}`), data, { merge: true })
  }

  const deleteMedia = async (path: string) => {
    const pathDecoded = path.replaceAll('%2F', '/')
    const mediaRef = storageRef(storage, pathDecoded)
    await deleteObject(mediaRef)
  }

  const deleteContentAndThumbnail = async (content: ContentLibraryItem) => {
    if (content.uploadPath)
      await deleteMedia(content.uploadPath)

    if (content.thumbnailPath)
      await deleteMedia(content.thumbnailPath)
  }

  const removeContentItem = async (content: ContentLibraryItem) => {
    await deleteContentAndThumbnail(content)
    await deleteDoc(doc(db, 'content', content.id))
    contentItems.value = contentItems.value.filter((item) => item.id !== content.id)
  }

  const getAssigned = async (attachedTo: ContentLibraryAttachment, currentUserJurniIds: Array<string | undefined>) => {
    if (!currentUserJurniIds)
      return 'Unassigned'

    const contentAttachments = ref(attachedTo)
    let attachedToJurniIds = Object.keys(contentAttachments.value)
    attachedToJurniIds.map((jurniId) => {
      if (!currentUserJurniIds.includes(jurniId))
        contentAttachments.value[jurniId] = []

      if (contentAttachments.value[jurniId].length === 0)
        delete contentAttachments.value[jurniId]

      return null
    })

    attachedToJurniIds = Object.keys(contentAttachments.value)
    getArchivedJurnis.value.map((jurni) => {
      if (!jurni || !jurni.id)
        return null

      if (attachedToJurniIds.includes(jurni.id))
        delete contentAttachments.value[jurni.id]

      return null
    })

    const jurniCount = Object.keys(contentAttachments.value).length

    if (jurniCount === 0)
      return 'Unassigned'

    let stepCount = 0
    Object.values(contentAttachments.value).map((items: any) => {
      stepCount += items.length
      return null
    })

    if (jurniCount > 1)
      return `in ${stepCount} steps (${jurniCount} ȷurnıes)`

    if (stepCount > 1)
      return `in ${stepCount} steps`

    return `in ${stepCount} step`
  }

  watch(() => currentCommunity.value, async () => {
    contentItems.value = []
    await loadContent()
  })

  return {
    contentItems,
    getThumbnail,
    getResources,
    getAssigned,
    showContentModal,
    loadContent,
    addContent,
    modalFileUploaded,
    modalUploadType,
    modalOpen,
    modalMode,
    modalIsFeatured,
    modalUploadOptions,
    getFeaturedContent,
    getEducationalVideoContent,
    getResourceContent,
    getVideoRecordingContent,
    getUnsharedVideoRecordingContent,
    getHomeworkContent,
    addToFeatured,
    removeFromFeatured,
    updateContentItem,
    removeContentItem
  }
})
