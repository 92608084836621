import { defineStore, storeToRefs } from 'pinia'
import { Ref, ref, computed, watch, reactive } from 'vue'
import { v4 } from 'uuid'

import { db, functions, storage } from '@/services/firebase'
import {
  collection,
  getDocs,
  getDoc,
  doc,
  DocumentReference,
  query,
  where,
  setDoc,
  deleteDoc,
  limit,
  arrayRemove,
  orderBy,
  arrayUnion,
  addDoc,
  deleteField,
  DocumentSnapshot,
  DocumentData
} from 'firebase/firestore'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { getDownloadURL, uploadBytes, deleteObject, ref as storageRef } from 'firebase/storage'

import {
  JurniStep,
  Jurni,
  GameBoardCard,
  JurniStepChapter,
  Invitation,
  JurniUserProgress,
  JurnisUserProgress,
  MeetingEvent,
  JurniSubscriber
} from '@/types'
import { usePermissions } from '@/composables/usePermissions'
import { useCoreStore } from '@/stores/core'
import { useAlertStore } from '@/stores/alert'
import { useGroupStore } from '@/stores/group'

export interface InvitationPayload {
  studentEmail: string
  studentFirst?: string
  studentLast?: string
  groupId?: string
  groupRole?: string
  jurniId: string
  inviteTemplate?: string
  'g-recaptcha-response'?: string
}

export const useJurniStore = defineStore('jurni', () => {
  const { currentCommunity, currentUser } = storeToRefs(useCoreStore())
  const invitationsByHash = ref<Record<string, unknown>>({})
  const jurnis = ref<Record<string, Jurni>>({})
  const featuredJurnis = ref<Record<string, Jurni>>({})
  const currentStepId = ref<string | null>(null)
  const currentChapterId = ref<string | null>(null)
  const gameboardCards = ref<Record<string, Array<GameBoardCard>>>({})
  const userJurniProgress = ref<JurnisUserProgress>({})
  const percent = ref<string>('0')
  const userWelcomeVideoProgress: Ref<boolean> = ref(true)
  const userWelcomeVideoProgressLoaded: Ref<boolean> = ref(false)

  const getJurniById = (id: string) : Jurni => jurnis.value[id]

  const getJurnis = computed(() => Object.values(jurnis.value))

  const freeJurnis = computed(() => Object.values(jurnis.value).filter((jurni) => !jurni.meta?.billingProvider || jurni.meta?.billingProvider === 'Free').map((jurni) => jurni.id))

  const paidJurnis = computed(() => Object.values(jurnis.value).filter((jurni) => jurni.meta?.billingProvider && jurni.meta?.billingProvider !== 'Free').map((jurni) => jurni.id))

  const trialActivatedJurnis = computed(() => Object.values(jurnis.value).filter((jurni) => jurni.meta?.billingProvider && jurni.meta?.billingProvider !== 'Free' && !!jurni.meta?.keepFirst30DaysFree).map((jurni) => jurni.id))

  const jurniTotalMembers = (jurni: Jurni) => {
    let totalMembers = jurni.members.length
    if (jurni.inactiveMembers?.length)
      totalMembers += jurni.inactiveMembers.length

    return totalMembers
  }

  const isJurniAdminMode = (id: string) : boolean => !!jurnis.value[id]?.meta?.jurniAdminMode

  const getJurnisByCommunityId = computed(() => {
    const { currentCommunity } = storeToRefs(useCoreStore())
    if (!currentCommunity.value)
      return []

    return Object.values(jurnis.value).filter((jurni) => {
      const isCommunity = jurni.community.id === currentCommunity.value?.id
      const isTemplate = jurni.meta?.isTemplate
      return isCommunity && !isTemplate
    })
  })

  const getTemplateJurnis = computed(() => Object.values(jurnis.value).filter((jurni) => jurni.meta?.isTemplate))

  const userCommunityJurnis = computed(() => {
    const { can } = usePermissions()
    const currentUserRef = currentUser.value?.userRef as DocumentReference
    if (!currentCommunity.value || !currentUserRef)
      return []

    return Object.values(jurnis.value).filter((jurni) => {
      const isCommunity = jurni.community.id === currentCommunity.value?.id
      const isMember = jurni.members.some((member: DocumentReference) => member.id === currentUserRef.id)
      return isCommunity && (isMember || can('manage', 'community'))
    })
  })

  // eslint-disable-next-line max-len
  const getActiveJurnis = computed(() => userCommunityJurnis.value.filter((jurni) => !jurni.meta?.isArchived && !jurni.meta?.isTemplate))

  const getArchivedJurnis = computed(() => userCommunityJurnis.value.filter((jurni) => jurni.meta?.isArchived))

  const getFeaturedJurnis = computed(() => {
    const featuredJurnies = Object.values(featuredJurnis.value).filter((jurni: Jurni) => {
      const currentUserRef = currentUser.value?.userRef as DocumentReference
      if (!currentCommunity.value || !currentUserRef)
        return false
      const isCommunity = jurni.community.id === currentCommunity.value?.id
      const isFeatured = jurni.meta?.isFeatured
      const isArchived = jurni.meta?.isArchived

      return isCommunity && isFeatured && !isArchived
    })
    return featuredJurnies
  })

  let jurnisResolve
  const jurnisPending = ref(true)
  const createJurnisPromise = () : Promise<boolean> => new Promise<boolean>((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    jurnisResolve = resolve
  }).finally(() => { jurnisPending.value = false })
  const awaitJurnisLoaded = ref(createJurnisPromise())
  const invitations = computed(() => Object.values(invitationsByHash.value))

  const getGameboardCards = (jurniId: string) : Array<Record<string, any>> => {
    const inputCards : Record<string, any> = gameboardCards.value[jurniId]
    if (!inputCards || !Array.isArray(inputCards))
      return []

    const tmpCards : Array<Record<string, any>> = []
    inputCards.forEach((card, index) : void => {
      if (card.type === 'phase') {
        if (!card.title)
          card.title = `Phase ${index + 1}`

        tmpCards.push({ ...card })
        card.steps.forEach((stepCard) => { tmpCards.push({ ...stepCard }) })
      } else if (card.type === 'special') {
        card.heading = card.title || 'Congrats!'
        card.showSteps = false
        card.id = 10000
        tmpCards.push({ ...card })
      } else if (['step', 'homework'].includes(card.type) && !card.phase) {
        tmpCards.push({ ...card })
      }
    })
    return tmpCards.filter((card) => card)
  }

  const isValidProgress = (progressData: JurniUserProgress, jurniData: Jurni) : boolean | null => {
    const isValid = ref(true)
    if (!Object.keys(progressData).length)
      isValid.value = false

    if (Object.keys(progressData).length - 1 !== Object.keys(jurniData.steps ?? {}).length)
      isValid.value = false

    if (!Object.keys(progressData).includes('justStarted'))
      isValid.value = false

    Object.entries(progressData).map(([stepKey, stepData]) => {
      if (stepKey === 'justStarted')
        return null

      if (!stepData.percent && stepData.percent !== 0)
        isValid.value = false
      return null
    })

    return isValid.value
  }

  async function loadJurniSteps(jurniId : string) : Promise<void> {
    const steps : Array<JurniStep> = []
    const stepsRef = collection(db, 'jurnis', jurniId, 'steps')

    const stepsSnap = await getDocs(query(stepsRef))
    await Promise.all(stepsSnap.docs.map(async (stepSnap) => {
      const step = stepSnap.data() as JurniStep
      const chapters = {}

      const chaptersSnap = await getDocs(query(
        collection(stepSnap.ref, 'chapters'),
        orderBy('order')
      ))
      chaptersSnap.forEach((chapterSnap) => {
        chapters[chapterSnap.id] = chapterSnap.data() as JurniStepChapter
      })
      step.id = stepSnap.id
      step.chapters = chapters
      steps.push(step)
    }))

    jurnis.value[jurniId].steps = steps
  }

  async function addJurni(jurniSnap: DocumentSnapshot) : Promise<void> {
    const jurni = jurniSnap.data() as Jurni

    if (!jurni)
      return

    jurni.id = jurniSnap.id
    jurni.steps = []

    if (jurni.bannerItem) {
      const contentSnap = await getDoc(jurni.bannerItem)
      const content = contentSnap.data()
      jurni.banner = content?.uploadUrl
    } else if (jurni.meta?.banner) {
      jurni.banner = jurni.meta.banner
    }

    const { getAssociatedGroups } = useGroupStore()
    const associatedgroup = await getAssociatedGroups(jurni.id)
    jurni.associatedgroup = associatedgroup.length

    jurnis.value[jurni.id] = jurni
    await loadJurniSteps(jurni.id)
  }

  async function loadJurni(id, forceReload = false) : Promise<Jurni> {
    const jurniRef = doc(db, 'jurnis', id)
    const jurniSnapshot = await getDoc(jurniRef)
    if (forceReload)
      await addJurni(jurniSnapshot)
    return jurniSnapshot.data() as Jurni
  }

  async function loadUserJurnies() : Promise<null> {
    if (!currentUser.value || !currentUser.value.userRef)
      return null

    const jurniQuerySnapshot = await getDocs(
      query(
        collection(db, 'jurnis'),
        where('members', 'array-contains', currentUser.value.userRef)
      )
    )

    await Promise.all(jurniQuerySnapshot.docs.map(async (doc) => {
      await addJurni(doc)
    }))

    return null
  }

  async function loadJurnies(communityIdRef) : Promise<null> {
    if (!currentUser.value || !currentUser.value.userRef || !communityIdRef.value)
      return null

    const { can } = usePermissions()
    let jurniQuery = query(
      collection(db, 'jurnis'),
      where('community', '==', doc(db, `communities/${communityIdRef.value}`))
    )

    if (!can('manage', 'community'))
      jurniQuery = query(jurniQuery, where('members', 'array-contains', currentUser.value?.userRef))

    const jurniQuerySnapshot = await getDocs(jurniQuery)

    await Promise.all(jurniQuerySnapshot.docs.map(async (doc) => {
      await addJurni(doc)
    }))

    if (jurnisPending.value)
      jurnisResolve()

    return null
  }

  async function loadFeaturedJurnies(communityIdRef) : Promise<null> {
    if (!communityIdRef.value)
      return null

    const jurniQuerySnapshot = await getDocs(
      query(
        collection(db, 'jurnis'),
        where('community', '==', doc(db, `communities/${communityIdRef.value}`)),
        where('meta.isFeatured', '==', true)
      )
    )

    await Promise.all(jurniQuerySnapshot.docs.map(async (doc) => {
      const jurni = doc.data() as Jurni
      jurni.id = doc.id
      featuredJurnis.value[jurni.id] = jurni
    }))

    return null
  }

  async function loadTemplateJurnies() : Promise<null> {
    const jurniQuerySnapshot = await getDocs(
      query(
        collection(db, 'jurnis'),
        where('meta.isTemplate', '==', true)
      )
    )

    await Promise.all(jurniQuerySnapshot.docs.map(async (doc) => {
      await addJurni(doc)
    }))

    return null
  }

  async function loadUserJurniProgress(jurniId: string) : Promise<void> {
    const { currentUser } = storeToRefs(useCoreStore())
    const justStarted = ref(false)

    if (!currentUser.value || userJurniProgress.value[jurniId])
      return

    const jurniProgressRef = doc(collection(currentUser.value.userRef, 'jurniProgress'), jurniId)
    const jurniProgressSnap = await getDoc(jurniProgressRef)
    const jurniProgressData = jurniProgressSnap.data() as JurniUserProgress

    if (!jurnis.value[jurniId] || !Object.keys(jurnis.value[jurniId].steps ?? {}).length)
      await loadJurni(jurniId)

    if (!jurniProgressSnap.exists())
      justStarted.value = true

    if (!jurnis.value[jurniId] || !Object.keys(jurnis.value[jurniId].steps ?? {}).length)
      return

    if (!jurniProgressSnap.exists() || !isValidProgress(jurniProgressData, jurnis.value[jurniId])) {
      const jurniProgress: JurniUserProgress = {}

      // loop jurni steps
      jurnis.value[jurniId].steps?.map((step) => {
        if (!step.id)
          return null

        const stepOldData: Record<string, any> | null = jurniProgressData ? jurniProgressData[step.id] : null
        if (step.type === 'homework') {
          jurniProgress[step.id] = {
            assignments: {},
            completed: 0,
            total: 1,
            percent: 0
          }
        } else {
          const stepChapters: Record<string, boolean> = {}
          Object.entries(step.chapters ?? {}).forEach(
            ([chapterId, chapter]) => {
              if (Object.keys(chapter).includes('order')) {
                if (stepOldData && Object.keys(stepOldData.chapters).includes(chapterId))
                  stepChapters[chapterId] = stepOldData.chapters[chapterId]
                else
                  stepChapters[chapterId] = false
              }
            }
          )

          const totalChapters = Object.keys(stepChapters).length ?? 1
          const completedChapters = Object.values(stepChapters)
            .filter((chapter) => chapter).length
          const percent = (completedChapters / totalChapters) * 100

          if (totalChapters > 0) {
            jurniProgress[step.id] = {
              chapters: stepChapters,
              completed: completedChapters,
              total: totalChapters,
              percent
            }
          }
        }
        return null
      })

      if (jurniProgressData)
        jurniProgress.justStarted = jurniProgressData.justStarted ?? justStarted.value
      else
        jurniProgress.justStarted = justStarted.value
      await setDoc(jurniProgressRef, jurniProgress, { merge: false })
      userJurniProgress.value[jurniId] = jurniProgress
    } else {
      const jurniProgress: JurniUserProgress = jurniProgressSnap.data() as JurniUserProgress
      userJurniProgress.value[jurniId] = jurniProgress
    }
  }

  async function finishedWelcomeVideo() : Promise<void> {
    if (!currentUser.value || !currentCommunity.value)
      return

    const userRef = currentUser.value.userRef
    const communitySettingsRef = doc(userRef, 'communitySettings', currentCommunity.value.id)
    await setDoc(communitySettingsRef, { watchedWelcomeVideo: true }, { merge: true })
    userWelcomeVideoProgress.value = true
  }

  async function loadUserWelcomeVideoProgress() : Promise<void> {
    userWelcomeVideoProgress.value = true
    userWelcomeVideoProgressLoaded.value = true

    // const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
    // userWelcomeVideoProgressLoaded.value = false

    // if (!currentUser.value || !currentCommunity.value) {
    //   userWelcomeVideoProgress.value = true
    //   userWelcomeVideoProgressLoaded.value = true
    //   return
    // }

    // if (currentUser.value.userRef.id === currentCommunity.value.coach.id) {
    //   userWelcomeVideoProgress.value = true
    //   userWelcomeVideoProgressLoaded.value = true
    //   return
    // }

    // const userRef = currentUser.value.userRef
    // const communitySettingsSnap = await getDoc(doc(userRef, 'communitySettings', currentCommunity.value.id))

    // if (!communitySettingsSnap.exists()) {
    //   userWelcomeVideoProgress.value = false
    //   userWelcomeVideoProgressLoaded.value = false
    //   return
    // }

    // const communitySettingsOnobarding = communitySettingsSnap.data() as Onboarding
    // userWelcomeVideoProgress.value = communitySettingsOnobarding.watchedWelcomeVideo ?? false
    // userWelcomeVideoProgressLoaded.value = true
  }

  async function loadJurniGameboardByMemberID(jurniId, userId) : Promise<Record<string, any>> {
    const jurniRef = doc(db, 'jurnis', jurniId)
    const uRef = doc(db, 'users', userId)
    const urefquery = query(collection(uRef, 'userjurniprogress'), where('id', '==', jurniRef))
    const userProcessSnap = await getDocs(urefquery)
    const userProcessRefList: any = []
    userProcessSnap.forEach((_doc) => {
      userProcessRefList.push(_doc.ref)
    })
    const userProgress: any = []
    await Promise.all(userProcessRefList.map(async (ref) => {
      const docRef = collection(ref, 'phase')
      const phaseSnap = await getDocs(docRef)
      phaseSnap.forEach((doc) => {
        userProgress.push(doc.data())
      })
    }))

    let jurniStep:any = {}
    jurniStep = jurnis.value[jurniId].steps

    jurniStep?.forEach((item) => {
      userProgress.forEach((card) => {
        if (item.chapters) {
          const chapter = item.chapters
          Object.keys(chapter).forEach((key) => {
            item.step = true
            const step = card.steps
            step.forEach((element) => {
              if (element?.chapters?.length) {
                element.chapters?.forEach((cha) => {
                  if (key === cha.chapterId)
                    chapter[key].completed = cha.isCompleted
                })
              }
            })
          })
        }
      })
    })
    return jurniStep
  }

  async function loadJurniGameboard(jurniId: string) : Promise<void> {
    const gameboard: Array<GameBoardCard> = []
    const currentStep = ref(1)
    const gameCards = await getDocs(
      query(
        collection(db, 'jurnis', jurniId, 'gameboardCards'),
        orderBy('id')
      )
    )

    await Promise.all(gameCards.docs.map(async (cardSnap) => {
      const cardData = cardSnap.data() as GameBoardCard
      cardData.ref = cardSnap.ref
      cardData.title = `${cardData.title}`

      if (['step', 'homework'].includes(cardData.type)) {
        if (cardData.content) {
          try {
            const stepSnap = await getDoc(cardData.content)
            const stepData = stepSnap.data() as JurniStep
            cardData.description = stepData.description
            cardData.stepId = cardData.content.id
          } catch (err) {
            console.error('getStep Error', err)
          }
        }
        cardData.ref = cardSnap.ref
        cardData.stepIndex = currentStep.value
        currentStep.value += 1
      }

      if (cardData.type === 'phase') {
        cardData.steps = [] as Array<GameBoardCard>
        const phaseStepCards = await getDocs(
          query(
            collection(db, 'jurnis', jurniId, 'gameboardCards'),
            where('type', 'in', ['step', 'homework']),
            where('phase', '==', cardSnap.ref),
            orderBy('id')
          )
        )

        await Promise.all(phaseStepCards.docs.map(async (stepCardSnap) => {
          const phaseStepData = stepCardSnap.data() as GameBoardCard
          let stepData : JurniStep | null = null
          if (phaseStepData.content) {
            try {
              const stepSnap = await getDoc(phaseStepData.content)
              stepData = stepSnap.data() as JurniStep
            } catch (err) {
              console.error('getStep Error', err)
            }
          }
          phaseStepData.stepId = phaseStepData.content?.id
          phaseStepData.ref = stepCardSnap.ref
          phaseStepData.color = cardData.color
          if (phaseStepData.type === 'step') {
            phaseStepData.title = `${phaseStepData.title}`
            if (stepData)
              phaseStepData.description = stepData.description
            phaseStepData.stepIndex = currentStep.value
            currentStep.value += 1
          }
          cardData.steps.push(phaseStepData)
          return phaseStepData
        }))
      }

      gameboard.push(cardData)
    }))

    gameboardCards.value[jurniId] = gameboard.sort((cardA, cardB) => {
      if (cardA.id > cardB.id)
        return 1
      if (cardA.id < cardB.id)
        return -1
      return 0
    })
  }

  async function load(communityId: Ref<string>, forceReload = false) : Promise<void> {
    if (!Object.keys(jurnis.value).length || forceReload) {
      await loadJurnies(communityId)
      await loadFeaturedJurnies(communityId)
      await loadTemplateJurnies()
    }
  }

  async function saveJurni(jurniId: string, jurniUpdates: Record<string, any>) : Promise<void> {
    await setDoc(
      doc(db, 'jurnis', jurniId),
      jurniUpdates,
      { merge: true }
    )
    await loadJurni(jurniId, true)
  }

  async function addChapter(jurniId: string, stepId: string, order = 1000) : Promise<void> {
    const newChapter = await addDoc(
      collection(db, 'jurnis', jurniId, 'steps', stepId, 'chapters'),
      { order, title: 'New Chapter' }
    )

    await loadJurni(jurniId, true)

    if (!userJurniProgress.value[jurniId])
      userJurniProgress.value[jurniId] = {} as JurniUserProgress

    const stepProgress = userJurniProgress.value[jurniId][stepId] ?? {}
    if (!stepProgress.chapters)
      stepProgress.chapters = {}

    stepProgress.chapters[newChapter.id] = false
    stepProgress.total = Object.keys(stepProgress.chapters).length
    stepProgress.percent = (stepProgress.completed ?? 0 / stepProgress.total) * 100
    userJurniProgress.value[jurniId][stepId] = stepProgress
  }

  function createCard(
    jurniId: string,
    { title, type } : Record<string, any>,
    order?: number,
    phase?: DocumentReference
  ) : Record<string, any> {
    const jurniCards = gameboardCards.value[jurniId] ?? []
    const stepPhase = ref<Record<string, any> | null>(null)
    if (phase)
      stepPhase.value = jurniCards.find((phaseCard) => phaseCard.ref?.id === phase.id) ?? null

    const currentOrder = computed(() => {
      const highestMainOrder = ref(1)
      const steps = stepPhase.value ? stepPhase.value.steps : jurniCards
      steps.map((card) => {
        if (card.type !== 'special' && card.id > highestMainOrder.value)
          highestMainOrder.value = card.id
        return null
      })
      return highestMainOrder.value + 1
    })

    const tmpCard: Record<string, any> = { title, type }
    tmpCard.id = order || currentOrder.value

    if (type === 'phase') {
      tmpCard.step = []
      if (!title)
        tmpCard.title = 'New Phase'
    } else if (['step', 'homework'].includes(type)) {
      const newStepRef = doc(collection(db, 'jurnis', jurniId, 'steps'))
      if (stepPhase.value)
        tmpCard.phase = stepPhase.value

      if (phase)
        tmpCard.phase = phase

      tmpCard.title = type === 'step' ? 'New Step' : 'New Survey/Test'

      setDoc(newStepRef,
        { title: tmpCard.title, description: '', type })
      if (type === 'step')
        addChapter(jurniId, newStepRef.id)

      tmpCard.content = newStepRef
    }

    const newCardRef = doc(collection(db, `jurnis/${jurniId}/gameboardCards`))
    setDoc(newCardRef, tmpCard)

    tmpCard.ref = newCardRef
    return tmpCard
  }

  async function updateGameboardList(
    jurniId: string,
    list: Array<Record<string, any>>
  ) : Promise<Array<Record<string, any>>> {
    let phase : DocumentReference | null = null
    let phaseId = 1
    let showSteps = false

    await Promise.all(list.map(async (card, index) => {
      const currentPhase = phase ? doc(db, phase.path) : null
      if (card.new)
        card = createCard(jurniId, { title: card.title, type: card.id }, index, currentPhase ?? undefined)

      if (currentPhase && card.type !== 'phase' && card.type !== 'special') {
        await setDoc(currentPhase, { step: arrayUnion(card.ref) }, { merge: true })
        if (card.type !== 'special') {
          await setDoc(card.ref, { phase: currentPhase }, { merge: true })
          card.phase = currentPhase
          card.showSteps = showSteps
        }
      }

      if (!card.ref)
        return

      if (card.type === 'phase') {
        phase = card.ref
        showSteps = card.showSteps ?? true
        if (phase)
          await setDoc(phase, { step: [] }, { merge: true })
        card.heading = `Phase ${phaseId}`
        phaseId += 1
      }

      if (card.types !== 'special')
        await setDoc(card.ref, { id: index + 1 }, { merge: true })
      else
        await setDoc(card.ref, { id: 10000 }, { merge: true })
      list.splice(index, 1, card)
    }))
    await loadJurniGameboard(jurniId)
    return list
  }

  async function updateChapterList(jurniId: string, stepId: string, list: Array<JurniStepChapter>) : Promise<void> {
    let order = 0

    await Promise.all(list.map(async (chapter) => {
      order += 1
      await setDoc(
        doc(collection(db, `/jurnis/${jurniId}/steps/${stepId}/chapters`), chapter.id),
        { order },
        { merge: true }
      )
    }))
    await loadJurni(jurniId, true)
  }

  async function saveStep(jurniId: string, stepId:string, stepUpdates: Record<string, string>) : Promise<void> {
    await setDoc(
      doc(db, 'jurnis', jurniId, 'steps', stepId),
      stepUpdates,
      { merge: true }
    )

    await loadJurni(jurniId, true)
  }

  async function saveChapter(
    jurniId: string,
    stepId: string,
    chapterId: string,
    chapterUpdates: Record<string, string>
  ) : Promise<void> {
    await setDoc(
      doc(db, 'jurnis', jurniId, 'steps', stepId, 'chapters', chapterId),
      chapterUpdates,
      { merge: true }
    )
    await loadJurni(jurniId, true)
  }

  async function removeChapter(jurniId: string, stepId: string, chapterId: string) : Promise<void> {
    await deleteDoc(doc(db, 'jurnis', jurniId, 'steps', stepId, 'chapters', chapterId))
    await loadJurni(jurniId, true)

    const stepProgress = userJurniProgress.value[jurniId][stepId]
    if (!stepProgress.chapters)
      stepProgress.chapters = {}

    if (Object.keys(stepProgress.chapters).includes(chapterId))
      delete stepProgress.chapters[chapterId]

    stepProgress.total = Object.keys(stepProgress.chapters).length
    stepProgress.percent = (stepProgress.completed / stepProgress.total) * 100
    userJurniProgress.value[jurniId][stepId] = stepProgress
  }

  const uploadChapterPhoto = async (file: File) : Promise<string> => {
    const name = v4()
    const sr = storageRef(storage, `jurni/chapters/upload/${name}`)
    const fileSnap = await uploadBytes(sr, file, { contentType: file.type })
    const downloadURL = await getDownloadURL(fileSnap.ref)

    return downloadURL
  }

  const removeChapterPhoto = async (url: string) : Promise<void> => {
    const urlToDelete = new URL(url)
    const splitURL = urlToDelete.pathname.split('/o/')
    const path = splitURL[1]
    const pathDecoded = path.replaceAll('%2F', '/')
    const mediaRef = storageRef(storage, pathDecoded)
    await deleteObject(mediaRef)
  }

  async function addCard(jurniId: string, type: string, phase?: DocumentReference) : Promise<Record<string, any>> {
    return createCard(jurniId, { title: '', type }, undefined, phase)
  }

  async function saveCard(
    jurniId: string,
    cardRef: DocumentReference,
    cardUpdates: Record<string, string>
  ) : Promise<void> {
    await setDoc(
      cardRef,
      cardUpdates,
      { merge: true }
    )
    await loadJurniGameboard(jurniId)
  }

  async function removeCard(jurniId: string, card: GameBoardCard) : Promise<void> {
    if (card.content)
      await deleteDoc(card.content)

    if (card.steps && card.steps.length > 0) {
      await Promise.all(card.steps.map(async (stepCard) => {
        await removeCard(jurniId, stepCard)
      }))
    }

    if (card.type === 'step' && card.phase)
      await setDoc(card.phase, { step: arrayRemove(card.ref) }, { merge: true })

    if (card.ref)
      await deleteDoc(card.ref)
    await loadJurniGameboard(jurniId)
  }

  async function savePhaseCard(jurniId: string, phaseId: string, changes: Record<string, any>) : Promise<void> {
    await setDoc(
      doc(db, 'jurnis', jurniId, 'gameboardCards', phaseId),
      changes,
      { merge: true }
    )
    await loadJurniGameboard(jurniId)
  }

  async function saveStepTitle(jurniId: string, stepId:string, newTitle: string) : Promise<void> {
    await saveStep(jurniId, stepId, { title: newTitle })

    const stepCards = await getDocs(
      query(
        collection(db, 'jurnis', jurniId, 'gameboardCards'),
        where('content', '==', doc(db, 'jurnis', jurniId, 'steps', stepId))
      )
    )

    if (!stepCards.empty) {
      await Promise.all(stepCards.docs.map(async (stepCard) => {
        await saveCard(jurniId, stepCard.ref, { title: newTitle })
        return null
      }))
    }
  }

  async function selectJurni(jurniId : string) : Promise<void> {
    await loadUserJurniProgress(jurniId)
    await loadJurniGameboard(jurniId)
  }

  const markComplete = async (jurniId: string, stepId: string, chapterId?: string) : Promise<boolean> => {
    if (!jurniId || !stepId || !chapterId || !userJurniProgress.value[jurniId])
      return false

    const stepProgress = userJurniProgress.value[jurniId][stepId] ?? {}
    if (!stepProgress.chapters)
      stepProgress.chapters = { [chapterId]: false }

    stepProgress.chapters[chapterId] = true
    stepProgress.completed = Object.values(stepProgress.chapters).filter((chapter) => chapter).length
    const percent = (stepProgress.completed / stepProgress.total) * 100
    stepProgress.percent = percent ?? 0.00

    const { currentUser } = storeToRefs(useCoreStore())
    if (!currentUser.value)
      return false

    const userJurniProgressRef = doc(
      collection(
        db,
        currentUser.value.userRef.path,
        'jurniProgress'
      ),
      jurniId
    )
    await setDoc(userJurniProgressRef, { [stepId]: stepProgress }, { merge: true })
    userJurniProgress.value[jurniId][stepId] = stepProgress
    return true
  }

  const markJurniStarted = async (jurniId: string) : Promise<boolean> => {
    if (!jurniId || !userJurniProgress.value[jurniId])
      return false

    const { currentUser } = storeToRefs(useCoreStore())
    if (!currentUser.value)
      return false

    const jurniProgressRef = doc(
      collection(
        db,
        currentUser.value.userRef.path,
        'jurniProgress'
      ),
      jurniId
    )
    await setDoc(jurniProgressRef, { justStarted: false }, { merge: true })
    userJurniProgress.value[jurniId].justStarted = false
    return true
  }

  const updatejurniPaymentSetup = async (jurniId: string, data: Partial<Jurni>) : Promise<void> => {
    if (jurniId) {
      const jurniRef = doc(db, 'jurnis', jurniId)
      await setDoc(
        jurniRef,
        { meta: data },
        { merge: true }
      )
      await loadJurni(jurniId, true)
    }
  }

  const removeJurniBanner = async (
    jurniRef: DocumentReference,
    { jurniBanner, contentItem } : { jurniBanner?: string, contentItem?: DocumentReference }
  ): Promise<void> => {
    // console.log('removeJurniBanner', jurniBanner, contentItem)
    if (!jurniBanner && !contentItem)
      return

    if (jurniBanner) {
      const httpsReference = storageRef(storage, jurniBanner)
      await deleteObject(httpsReference)
      await setDoc(jurniRef, { meta: { banner: deleteField() } }, { merge: true })
    }

    if (contentItem) {
      // console.log('before banner save')
      await saveJurni(jurniRef.id, { bannerItem: deleteField() })
      // console.log('after banner save')
    }
  }

  const updateArchivedStatus = async (jurniId: string): Promise<void> => {
    const jurniRef = doc(db, 'jurnis', jurniId)
    const jurniSnap = await getDoc(jurniRef)
    const jurniData = jurniSnap.data()

    let isArchived = {}
    if (!jurniData?.meta.isTemplate) {
      isArchived = { isArchived: !jurniData?.meta?.isArchived ?? true }
    } else {
      const { currentCommunity } = storeToRefs(useCoreStore())

      if (!currentCommunity.value?.id)
        return

      const archivedStatus = jurniData?.meta?.isArchivedFor &&
        jurniData?.meta?.isArchivedFor[currentCommunity.value?.id]
        ? !jurniData?.meta?.isArchivedFor[currentCommunity.value?.id] : true

      isArchived = {
        isArchivedFor: {
          [currentCommunity.value?.id]: archivedStatus
        }
      }
    }

    await setDoc(
      jurniRef,
      { meta: isArchived },
      { merge: true }
    )
    await loadJurni(jurniId, true)
  }

  const toggleUserJurniStatus = async (userId: string, jurniId: string) : Promise<void> => {
    const inactiveMember = jurnis.value[jurniId].inactiveMembers?.includes(userId)

    // remove from unsubscribeRequested
    if (!inactiveMember) {
      await setDoc(
        doc(db, `jurnis/${jurniId}`),
        {
          unsubscribeRequested: arrayRemove(doc(db, 'users', userId))
        },
        { merge: true }
      )
    }

    await setDoc(
      doc(db, `jurnis/${jurniId}`),
      {
        members: inactiveMember ? arrayUnion(doc(db, 'users', userId)) : arrayRemove(doc(db, 'users', userId)),
        inactiveMembers: inactiveMember ? arrayRemove(userId) : arrayUnion(userId)
      },
      { merge: true }
    )
    await loadJurni(jurniId, true)
  }

  const removeCurrentUserFromJurni = async (jurni) : Promise<void> => {
    const { currentUser } = storeToRefs(useCoreStore())
    await setDoc(
      doc(db, `jurnis/${jurni.id}`),
      {
        members: arrayRemove(currentUser.value?.userRef),
        inactiveMembers: arrayRemove(currentUser.value?.id)
      },
      { merge: true }
    )
    if (jurnis.value[jurni.id])
      delete jurnis.value[jurni.id]
  }

  const requestUnsubscribeFromJurni = async (jurni) : Promise<void> => {
    // this will make sure student can trigger request to unsubscribe multiple times
    await setDoc(
      doc(db, `jurnis/${jurni.id}`),
      {
        unsubscribeRequested: arrayRemove(currentUser.value?.userRef)
      },
      { merge: true }
    )
    await setDoc(
      doc(db, `jurnis/${jurni.id}`),
      {
        unsubscribeRequested: arrayUnion(currentUser.value?.userRef)
      },
      { merge: true }
    )
  }

  const switchJurniAdminMode = async (jurniId: string, val: boolean) : Promise<void> => {
    if (!jurniId)
      return

    await setDoc(
      doc(db, 'jurnis', jurniId),
      { meta: { jurniAdminMode: val } },
      { merge: true }
    )

    jurnis.value[jurniId]!.meta!.jurniAdminMode = val
  }

  const createJurni = async (jurniData: Ref) : Promise<string> => {
    const { currentUser } = storeToRefs(useCoreStore())
    const { currentCommunity } = storeToRefs(useCoreStore())
    const { can } = usePermissions()

    if (currentUser.value && currentCommunity.value && can('manage', 'community')) {
      const communityRef = doc(db, 'communities', currentCommunity.value.id)
      const newJurniRef = doc(collection(db, 'jurnis'))
      const newJurni: Partial<Jurni> = { ...jurniData.value }
      newJurni.coach = currentUser.value?.userRef
      newJurni.community = communityRef
      newJurni.members = [currentUser.value?.userRef]
      newJurni.meta = { isArchived: false }

      // create the jurni
      await setDoc(newJurniRef, newJurni)
      const newJurniSnapshot = await getDoc(newJurniRef)

      // create special phase card
      const specialCard: Record<string, any> = {
        id: 100000,
        title: 'Congrats!',
        description: 'Congrats!',
        type: 'special'
      }
      const newCardRef = doc(collection(db, `jurnis/${newJurniSnapshot.id}/gameboardCards`))
      await setDoc(newCardRef, specialCard)

      await addJurni(newJurniSnapshot)
      await switchJurniAdminMode(newJurniSnapshot.id, true)
      return newJurniSnapshot.id
    }
    return ''
  }

  const joinJurni = async (jurniId: string) : Promise<Record<string, unknown>> => {
    if (!jurniId)
      return { sucess: false, message: 'Invalid ȷurnı' }

    if (!currentUser.value?.userRef)
      return { sucess: false, message: 'Invalid user' }

    const joinJurniFunc = httpsCallable(functions, 'joinJurni')
    const response: HttpsCallableResult<any> = await joinJurniFunc({ jurniId, userId: currentUser.value?.id })

    return response.data
  }

  const duplicateJurni = async (jurniId: string): Promise<DocumentReference | null> => {
    const { can } = usePermissions()

    // create the jurni
    // this way we could somehow block duplication from users without permission
    // since we only check if isLoggedIn() in create
    if (!can('manage', 'community'))
      return null

    const { currentUser, currentCommunity } = storeToRefs(useCoreStore())

    const jurni = JSON.parse(JSON.stringify(jurnis.value[jurniId]))
    const newJurniRef = doc(collection(db, 'jurnis'))
    const addClone = !jurni.meta?.isTemplate

    // remove unnecessary items
    delete jurni.id
    delete jurni.steps
    delete jurni.group
    delete jurni.onboardingflow
    delete jurni.meta?.isTemplate
    delete jurni.banner
    // add coach to jurni members
    jurni.community = doc(db, 'communities', currentCommunity.value!.id)
    jurni.coach = doc(db, `users/${currentUser.value!.id}`)
    jurni.members = [doc(db, `users/${currentUser.value!.id}`)]
    jurni.name += addClone ? ' (Clone)' : ''
    jurni.meta = { ...jurni.meta, billingProvider: 'Free' }
    if (jurni.bannerItem)
      jurni.bannerItem = jurnis.value[jurniId].bannerItem

    const newJurni: Partial<Jurni> = {
      ...jurni
    }

    await setDoc(newJurniRef, newJurni)

    const newJurniSnapshot = await getDoc(newJurniRef)
    const newJurniId = newJurniSnapshot.id
    // dupe steps
    const steps = await getDocs(
      query(
        collection(db, 'jurnis', jurniId, 'steps')
      )
    )
    await Promise.all(steps.docs.map(async (stepSnap) => {
      const newStepRef = doc(db, 'jurnis', newJurniId, 'steps', stepSnap.id)

      const newStep = { ...stepSnap.data() }
      await setDoc(newStepRef, newStep)

      // dupe chapters
      const chapters = await getDocs(
        query(
          collection(db, 'jurnis', jurniId, 'steps', newStepRef.id, 'chapters')
        )
      )

      await Promise.all(chapters.docs.map(async (chapterSnap) => {
        const newChapterRef = doc(db, 'jurnis', newJurniId, 'steps', newStepRef.id, 'chapters', chapterSnap.id)
        const newChapter = { ...chapterSnap.data() }
        await setDoc(newChapterRef, newChapter)
      }))
    }))

    // dupe cards
    const gameboardCards = await getDocs(
      query(
        collection(db, 'jurnis', jurniId, 'gameboardCards')
      )
    )
    await Promise.all(gameboardCards.docs.map(async (gameboardCardSnap) => {
      const newGameboardCardRef = doc(db, 'jurnis', newJurniId, 'gameboardCards', gameboardCardSnap.id)
      const newGameboardCard = { ...gameboardCardSnap.data() }

      // update gameboardCards references
      if (newGameboardCard.type === 'phase') {
        newGameboardCard.step = newGameboardCard.step.map((step) => doc(db, 'jurnis', newJurniId, 'gameboardCards', step.id))
      } else if (['step', 'homework'].includes(newGameboardCard.type)) {
        newGameboardCard.content = doc(db, 'jurnis', newJurniId, 'steps', newGameboardCard.content.id)
        newGameboardCard.phase = doc(db, 'jurnis', newJurniId, 'gameboardCards', newGameboardCard.phase.id)
      }

      await setDoc(newGameboardCardRef, newGameboardCard)
    }))

    // add new jurni to list
    await addJurni(newJurniSnapshot)

    return newJurniRef
  }

  watch(() => currentCommunity.value, async () => {
    if (currentCommunity.value && currentCommunity.value.id)
      await load(ref(currentCommunity.value.id), true)
  })

  // DEPRECATED CODE SECTION
  // TODO: WILL REMOVE WHEN UNUSED
  function addInvitation(invitation) : void {
    invitationsByHash.value[invitation.inviteHash] = invitation
  }

  async function loadInvitations(jurniId) : Promise<void> {
    const invitationsCollectionRef = collection(db, 'invitations')
    const q = query(invitationsCollectionRef,
      where('jurniId', '==', jurniId),
      limit(50))

    const invitationQuerySnapshot = await getDocs(q)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    invitationQuerySnapshot.forEach((doc) => {
      const invitation = doc.data()
      addInvitation(invitation)
    })
  }

  async function sendInvite(invitePayload: InvitationPayload) : Promise<Invitation | null> {
    const alertStore = useAlertStore()

    const inviteData: InvitationPayload = { ...invitePayload }
    const jurniSnap = await getDoc(doc(db, `jurnis/${inviteData.jurniId}`))

    const userQuerySnapshot = await getDocs(
      query(
        collection(db, 'users'),
        where('email', '==', inviteData.studentEmail)
      )
    )
    const userRefList: any = []
    userQuerySnapshot.forEach((userSnap) => {
      userRefList.push(userSnap.ref)
    })

    if (userRefList.length) {
      // deactivated users may not have a user reference yet
      const invitedUser = userRefList[0]
      const jurniData = jurniSnap.data()
      if (jurniData!.members.some((member: DocumentReference) => member.id === invitedUser.id)) {
        alertStore.add({ description: `User is already a member of jurni: ${jurniData!.name}`, color: 'danger' })
        return null
      }
    }

    if (jurniSnap.exists()) {
      const jurni = jurniSnap.data() as Jurni
      if (jurni.meta?.inviteTemplate)
        inviteData.inviteTemplate = jurni.meta?.inviteTemplate

      const sendInviteFunc = httpsCallable(functions, 'invitationSend')
      const response: HttpsCallableResult<any> = await sendInviteFunc(inviteData)
      const invitation = response.data as Invitation

      if (invitation) {
        addInvitation(invitation)
        return invitation
      }
    }
    return null
  }

  const removeMember = async (userId: string, jurniId: string) : Promise<void> => {
    await setDoc(
      doc(db, `jurnis/${jurniId}`),
      {
        members: arrayRemove(doc(db, `users/${userId}`)),
        inactiveMembers: arrayRemove(userId)
      },
      { merge: true }
    )
    await loadJurni(jurniId, true)
  }

  const createJurniEvent = async (values) : Promise<void> => {
    const { currentCommunity } = storeToRefs(useCoreStore())

    if (!currentCommunity.value)
      return

    const communityRef = doc(db, 'communities', currentCommunity.value?.id)
    const newJurniEvent: Partial<MeetingEvent> = { ...values }
    const newJurniEventRef = doc(collection(db, 'meetingEvents'))
    newJurniEvent.community = communityRef

    await setDoc(newJurniEventRef, newJurniEvent)
  }

  const saveWelcomeEmail = async (payload: Record<string, any>, jurniId?: string) : Promise<null> => {
    const { currentCommunity } = storeToRefs(useCoreStore())
    const newTemplate : DocumentData = {
      template: 'jurni-student-invite-custom',
      type: 'invite',
      variables: {
        title: 'jurni invitation'
      }
    }
    let ref : DocumentReference | null = null

    if (jurniId) {
      const jurniTemplatesSnap = await getDocs(query(
        collection(db, 'templates'),
        where('jurniId', '==', jurniId)
      ))

      if (!jurniTemplatesSnap.empty) {
        ref = jurniTemplatesSnap.docs[0].ref
        const data = jurniTemplatesSnap.docs[0].data()
        Object.entries(data).map(([key, value]) => {
          if (key !== 'variables') { newTemplate[key] = value } else {
            Object.entries(value).map(([subkey, subvalue]) => {
              newTemplate.variables[subkey] = subvalue
              return null
            })
          }
          return null
        })
      }

      newTemplate.jurniId = jurniId
    } else {
      const communityTemplatesSnap = await getDocs(query(
        collection(db, 'templates'),
        where('communityId', '==', currentCommunity.value?.id)
      ))

      if (!communityTemplatesSnap.empty) {
        ref = communityTemplatesSnap.docs[0].ref
        const data = communityTemplatesSnap.docs[0].data()
        Object.entries(data).map(([key, value]) => {
          if (key !== 'variables') { newTemplate[key] = value } else {
            Object.entries(value).map(([subkey, subvalue]) => {
              newTemplate.variables[subkey] = subvalue
              return null
            })
          }
          return null
        })
      }

      newTemplate.communityId = currentCommunity.value?.id
    }

    if (Object.keys(payload).includes('copy'))
      newTemplate.variables.copy = payload.copy

    if (!ref)
      ref = doc(collection(db, 'templates'))

    await setDoc(ref, newTemplate, { merge: false })
    return null
  }

  const acceptJurniPaymentChange = async (jurni) : Promise<void> => {
    const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
    const alertStore = useAlertStore()

    if (!currentUser.value || !currentCommunity.value)
      return

    const jurniRef = doc(db, 'jurnis', jurni.id)

    // accept in notification
    const notifsSnap = await getDocs(query(
      collection(db, 'notifications'),
      where('meta.item.ref', '==', jurniRef),
      where('meta.user', '==', currentUser.value.userRef),
      where('notificationType', '==', 'GHUS Payment Setting Change')
    ))

    await Promise.all(notifsSnap.docs.map(async (notif) => {
      await setDoc(notif.ref, { status: 'accepted' }, { merge: true })

      alertStore.removeNotificationById(notif.ref.id)
    }))
  }

  const declineJurniPaymentChange = async (jurniId) : Promise<void> => {
    const { currentUser, currentCommunity } = storeToRefs(useCoreStore())
    const { loadBillingExclusion } = useCoreStore()
    const alertStore = useAlertStore()

    if (!currentUser.value || !currentCommunity.value)
      return

    const jurni = await loadJurni(jurniId)

    // so accept button will be gone in payments tab
    // if jurni was changed to upfront and student declined
    // they will be removed from jurni in the backend (cloud function)
    if (jurni.meta && jurni.meta.billingType === 'Upfront') {
      await setDoc(
        doc(currentUser.value.userRef, 'communitySettings', currentCommunity.value.id),
        {
          paymentSettingChangedExcludeBillingFrom: {
            [jurniId]: false
          }
        }, { merge: true }
      )
      await loadBillingExclusion()
      alertStore.add({ description: `Payment change declined. You will be removed from ${jurni.name} course shortly.` })
    } else {
      alertStore.add({ description: `Payment change declined. You will be removed from ${jurni.name} course on the next billing cycle.` })
    }
  }

  const canCoachInviteStudents = async (jurniId: string) : Promise<boolean> => {
    if (!currentUser.value || !currentCommunity.value)
      return false

    const jurniSnap = await getDoc(doc(db, `jurnis/${jurniId}`))
    const jurniData = jurniSnap.data() as Jurni

    if (!jurniData)
      return false

    // if course payment plan is not set up yet
    if (!jurniData.paypal?.planId && !jurniData.paypal?.isFree)
      return false

    return true
  }

  const validateStudentInviteForm = async (inviteForm: Record<string, any>) : Promise<void> => {
    const requiredFields = reactive({
      studentEmail: false,
      studentFirst: false,
      studentLast: false,
      jurniId: false
    })
    const fieldsMap = {
      studentEmail: 'Email',
      studentFirst: 'First Name',
      studentLast: 'Last Name',
      jurniId: 'Jurni'
    }
    const err: string[] = []

    Object.keys(requiredFields).forEach((key) => {
      if (inviteForm[key])
        requiredFields[key] = true
      else
        err.push(`${fieldsMap[key]}`)
    })

    if (err.length > 0)
      throw new Error(`Student invite form missing fields: ${err.join(', ')}`)
  }

  const addExtenalJurniMember = async (jurni: any, userId: string, status: string) : Promise<void> => {
    const jurniRef = doc(db, 'jurnis', jurni.id)
    const userRef = doc(db, 'users', userId)
    const updates : Record<string, unknown> = { inactiveMembers: arrayRemove(userId) }

    if (status === 'accept')
      updates.members = arrayUnion(userRef)

    await setDoc(jurniRef, updates, { merge: true })
  }

  const getUserJurniSubscriptionId = (jurniId: string, uid: string) => {
    if (!currentUser.value)
      return ''

    const jurni = getJurniById(jurniId)
    if (!jurni.subscribers)
      return ''

    const userSubscription = jurni.subscribers?.[uid]
    return userSubscription?.subscriptionId ?? ''
  }

  const getUserJurniSubscriptionStatus = (jurniId: string, uid: string) => {
    if (!currentUser.value)
      return '-'

    const jurni = getJurniById(jurniId)
    if (!jurni.subscribers)
      return 'Subscription not set up'

    const userSubscription = jurni.subscribers?.[uid]
    return userSubscription?.status ?? 'Subscription not set up'
  }

  const isUserAnActiveJurniSubscriber = (jurniId: string) => {
    if (!currentUser.value)
      return false

    const jurni = getJurniById(jurniId)
    if (!jurni)
      return false

    if (!Object.keys(jurni).includes('subscribers') || !jurni.subscribers)
      return false

    const activeSubscriberIds = Object.entries(jurni.subscribers as JurniSubscriber)
      .filter(([uid, subscriptionDetails]) => subscriptionDetails.status === 'ACTIVE')
      .map(([uid]) => uid)

    return activeSubscriberIds.includes(currentUser.value.userRef.id)
  }

  return {
    userJurniProgress,
    userWelcomeVideoProgress,
    userWelcomeVideoProgressLoaded,
    finishedWelcomeVideo,
    percent,
    gameboardCards,
    invitations,
    getJurnis,
    joinJurni,
    jurniTotalMembers,
    loadUserJurnies,
    loadJurni,
    loadUserWelcomeVideoProgress,
    loadFeaturedJurnies,
    getJurniById,
    getTemplateJurnis,
    getFeaturedJurnis,
    getGameboardCards,
    load,
    loadJurnies,
    jurnis,
    freeJurnis,
    paidJurnis,
    trialActivatedJurnis,
    // jurniAdminMode,
    isJurniAdminMode,
    switchJurniAdminMode,
    updateGameboardList,
    addCard,
    createCard,
    saveJurni,
    saveCard,
    removeCard,
    saveStep,
    addChapter,
    saveChapter,
    removeChapter,
    savePhaseCard,
    saveStepTitle,
    updateChapterList,
    selectJurni,
    markComplete,
    markJurniStarted,
    currentStepId,
    loadInvitations,
    loadJurniGameboard,
    sendInvite,
    currentChapterId,
    awaitJurnisLoaded,
    getCurrentUserJurnis: userCommunityJurnis,
    getActiveJurnis,
    getArchivedJurnis,
    getJurnisByCommunityId,
    updatejurniPaymentSetup,
    removeCurrentUserFromJurni,
    requestUnsubscribeFromJurni,
    toggleUserJurniStatus,
    loadJurniGameboardByMemberID,
    loadUserJurniProgress,
    removeJurniBanner,
    createJurni,
    duplicateJurni,
    updateArchivedStatus,
    removeMember,
    createJurniEvent,
    saveWelcomeEmail,
    acceptJurniPaymentChange,
    declineJurniPaymentChange,
    uploadChapterPhoto,
    removeChapterPhoto,
    canCoachInviteStudents,
    validateStudentInviteForm,
    addExtenalJurniMember,
    isUserAnActiveJurniSubscriber,
    getUserJurniSubscriptionStatus,
    getUserJurniSubscriptionId
  }
})
